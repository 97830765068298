import React from 'react';
import { StatusAborted, StatusOK } from '@backstage/core-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Tooltip from '@mui/material/Tooltip';
import { Schedule } from '../../types';
import { ResponderTitleFormatter } from './OnCallList';
import { OnCallForScheduleList } from './OnCallScheduleList';

export const OnCallForScheduleCard = ({
  schedule,
  responderFormatter,
  date,
}: {
  schedule: Schedule;
  responderFormatter?: ResponderTitleFormatter;
  date?: string;
}) => {
  const title = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={schedule.enabled ? 'Enabled' : 'Disabled'}>
        <div>{schedule.enabled ? <StatusOK /> : <StatusAborted />}</div>
      </Tooltip>
      {schedule.ownerTeam?.name}
    </div>
  );

  return (
    <Card>
      <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <OnCallForScheduleList
          schedule={schedule}
          responderFormatter={responderFormatter}
          date={date}
        />
      </CardContent>
    </Card>
  );
};
