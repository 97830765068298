import type { ExportedData, ExportedValue } from '../types';

export function downloadJson(data: ExportedData) {
  const json = JSON.stringify(data, null, 2);
  const blob = new Blob([json], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.json');
  a.click();
  URL.revokeObjectURL(url);
}

function convertToDelimited(data: ExportedData, format: 'csv' | 'tsv'): string {
  if (data.length === 0) return '';
  const delimiter = format === 'tsv' ? '\t' : ',';
  const headers = Array.from(new Set(data.flatMap(obj => Object.keys(obj))));
  const formatValue = (value: ExportedValue): string => {
    if (value === null || value === undefined) return '';
    if (Array.isArray(value)) {
      const joinedArray = value
        .map(v => String(v))
        .join(format === 'tsv' ? ' | ' : ', ');
      return format === 'csv' ? `"${joinedArray}"` : joinedArray;
    }
    let stringValue = String(value);
    if (
      format === 'csv' &&
      (stringValue.includes(',') ||
        stringValue.includes('"') ||
        stringValue.includes('\n'))
    ) {
      stringValue = `"${stringValue.replace(/"/g, '""')}"`;
    }
    if (format === 'tsv') {
      stringValue = stringValue.replace(/\t/g, ' ').replace(/\n/g, ' ');
    }
    return stringValue;
  };
  const rows = data.map(row =>
    headers.map(header => formatValue(row[header] ?? '')).join(delimiter),
  );
  return [headers.join(delimiter), ...rows].join('\n');
}

export function downloadTsv(data: ExportedData) {
  const tsv = convertToDelimited(data, 'tsv');
  const blob = new Blob([tsv], { type: 'text/tab-separated-values' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.tsv');
  a.click();
  URL.revokeObjectURL(url);
}

export function downloadCsv(data: ExportedData) {
  const csv = convertToDelimited(data, 'csv');
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.csv');
  a.click();
  URL.revokeObjectURL(url);
}
