import { getTeamNameFromFullName } from 'plugin-core';

export function getTeamOptionName(team: IEntityGroup): string {
  const { id, id_name, fullName } = team.spec;

  if (fullName) return `${getTeamNameFromFullName(fullName)} (${id})`;
  else if (id_name) return `${id_name} (${id})`;
  return id;
}

const ESTIMATED_TOTAL_INCIDENTS = 1500;

export function getProgress(current: number): number {
  const API_LIMIT = 100;

  const portion = current + API_LIMIT;
  if (portion > ESTIMATED_TOTAL_INCIDENTS) return current;
  return portion;
}

export function getProgressPercentage(portion: number): number {
  return Math.round((portion / ESTIMATED_TOTAL_INCIDENTS) * 100);
}
