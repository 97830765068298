import {
  DiscoveryApi,
  IdentityApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { RepositoryApi } from './RepositoryApi';
import {
  ApplicationTemplate,
  CreateRepositoryRequest,
  CustomizedRepositoryStatus,
  Organization,
  Repository,
  RepositoryStatus,
  TemplateType,
  ApplicationTemplateForm,
} from './types';

const repoAlreadyExistRegexp = /^(repository).*(already exists)$/i;
const notMemberOfRegexp = /^(user).*(is not a member of).*$/i;

const customizeErrorResponse = async (
  res: RepositoryStatus,
): Promise<CustomizedRepositoryStatus> => {
  const { description: originalMessage, status } = res;
  if (status === 'error') {
    if (originalMessage.toLowerCase().match(repoAlreadyExistRegexp)) {
      return {
        ...res,
        description: 'This repository already exists.',
        field: 'name',
      };
    }

    if (originalMessage.toLowerCase().match(notMemberOfRegexp)) {
      return {
        ...res,
        description: 'You are not a member of this organization.',
        field: 'org_name',
      };
    }
  }

  return res;
};

const getZSEUsername = async (identityApi: IdentityApi) => {
  try {
    const profile = await identityApi.getProfileInfo();
    const email = profile.email;
    const nameParts = email?.split('@')[0].split('.');
    const zseUsername = `${nameParts?.join('-')}_zse`;
    return zseUsername;
  } catch (error) {
    return '';
  }
};

export class RepositoryClient implements RepositoryApi {
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
    identityApi: any;
  }) {
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async createRepository(repository: Repository): Promise<RepositoryStatus> {
    let template_type = '';
    let template_name: string | undefined = '';

    const zse_username = await getZSEUsername(this.identityApi);

    if (repository.gitignore_template !== undefined) {
      template_type = TemplateType.gitignore;
      template_name = repository.gitignore_template;
    }
    if (repository.application_template !== undefined) {
      template_type = TemplateType.application;
      template_name = repository.application_template;
    }
    const createReq = {
      org_name: repository.org_name,
      team_id: repository.team_id,
      name: repository.name,
      description: repository.description,
      type: repository.type,
      template_type: template_type,
      template_name: template_name,
      application_template_form: repository.application_template_form,
      zse_username: zse_username,
    } as CreateRepositoryRequest;
    return await this.post(`/create_repository`, createReq).then(resp =>
      resp.json(),
    );
  }

  async getStatus(
    status: RepositoryStatus,
  ): Promise<CustomizedRepositoryStatus> {
    const res = (await this.get(
      `/${status.org_name}/${status.repo_name}/status`,
    )) as RepositoryStatus;
    return customizeErrorResponse(res);
  }

  async getOrganizations(): Promise<Organization[]> {
    return this.get('/organizations');
  }

  async getApplicationTemplates(): Promise<ApplicationTemplate[]> {
    try {
      return await this.get('/application_templates');
    } catch (error) {
      throw new Error('Failed to fetch application templates');
    }
  }

  async getApplicationTemplateForm(
    name: string,
  ): Promise<ApplicationTemplateForm> {
    return this.get(`/application_templates/${name}/form`);
  }

  private async get<T>(path: string): Promise<T> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url: string = `${proxyUrl}/repo-manager${path}`;
    const token: string = await this.oauth2Api.getAccessToken();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    });

    if (!response.ok) {
      const payload = await response.text();
      const message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      throw new Error(message);
    }

    return await response.json();
  }

  private async post(path: string, body: any = {}): Promise<Response> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url: string = `${proxyUrl}/repo-manager${path}`;
    const token: string = await this.oauth2Api.getAccessToken();

    return fetch(url, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`,
        accept: 'application/json',
      }),
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }
}
