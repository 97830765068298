import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useStyles } from './styles';
import type { IOption } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface Props {
  label: string;
  options: IOption[];
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const SelectFilter = React.memo(
  ({ label, value, onChange, options, loading = false, disabled }: Props) => {
    const { inputRoot } = useStyles();
    const id = `${label.split(' ').join('-').toLowerCase()}-filter`;

    return (
      <FormControl
        variant="outlined"
        margin="dense"
        size="small"
        disabled={loading || disabled}
      >
        <InputLabel id={id} children={label} />
        <Select
          variant="outlined"
          size="small"
          value={value}
          onChange={ev => onChange(ev.target.value)}
          labelId={id}
          label={label}
          classes={{ root: inputRoot }}
          disabled={loading || disabled}
          endAdornment={
            loading && (
              <Box display="flex" alignItems="center" pr={3}>
                <CircularProgress size={16} />
              </Box>
            )
          }
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  },
);
