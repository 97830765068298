import {
  AISystem,
  CreateAISystem,
  ListAISystem,
  ListAISystemResponse,
  UpdateAISystem,
} from '../interfaces/ai_system';
import { Promise } from 'ts-toolbelt/out/Any/Promise';
import { HttpClient } from '../httpClient';
import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import aiSystemsQuery from '../graphql/ai_system';
import { InputError } from '@backstage/errors';

export interface AISystemServiceApi {
  getAISystems(
    getAISystemParameters: ListAISystem,
  ): Promise<ListAISystemResponse>;
  getAISystemBySlug(aiSystemSlug: string): Promise<AISystem>;
  getAISystemById(aiSystemId: string): Promise<AISystem>;
  createAISystem(aiSystem: CreateAISystem): Promise<number>;
  updateAISystem(aiSystemId: number, aiSystem: UpdateAISystem): Promise<number>;
  getTicketStatus(aiSystemId: number): Promise<string>;
}

export class AISystemService implements AISystemServiceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getAISystems(
    getAISystemParameters: ListAISystem,
  ): Promise<ListAISystemResponse> {
    const variables = this.httpClient.cleanUpParameters(getAISystemParameters);
    return this.httpClient
      .post('/graphql', {
        operationName: 'AISystems',
        query: aiSystemsQuery,
        variables,
      })
      .then(response => {
        if (response.data.errors && !response.data.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the AI Systems',
          );
        }
        return response.data;
      });
  }

  async getAISystemBySlug(aiSystemSlug: string): Promise<AISystem> {
    return this.httpClient.get(`/ai_systems/slug/${aiSystemSlug}`);
  }

  async getAISystemById(aiSystemId: string): Promise<AISystem> {
    return this.httpClient.get(`/ai_systems/${aiSystemId}`);
  }

  async createAISystem(aiSystem: CreateAISystem): Promise<number> {
    return this.httpClient.post('/ai_systems/', aiSystem).then(response => {
      return response.resource_id;
    });
  }

  async updateAISystem(
    aiSystemId: number,
    aiSystem: UpdateAISystem,
  ): Promise<number> {
    const parameters = this.httpClient.cleanUpParameters(aiSystem);
    return this.httpClient.patch(`/ai_systems/${aiSystemId}`, parameters);
  }

  async getTicketStatus(aiSystemId: number): Promise<string> {
    return this.httpClient.get(`/ai_systems/${aiSystemId}/jira`).then(
      response => {
        return response.status;
      },
      () => {
        return 'Could not fetch Jira status';
      },
    );
  }
}

export const aiSystemApiRef = createApiRef<AISystemServiceApi>({
  id: 'ml-platform-ai-system-api',
});
