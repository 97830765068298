import React, { type ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { isPrimitive } from './utils/general.utils';
import { TableToolbar } from './Toolbar/TableToolbar';
import type { Table } from '@tanstack/react-table';
import type { TableToolbarItem } from './types/toolbar';
import type { TableProps } from './types';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

interface Props<T> {
  table: Table<T>;
  hidden?: boolean;
  showSearch?: boolean;
  toolbar?: TableToolbarItem[];
  header?: ReactNode;
  headerTooltip?: ReactNode;
  loading?: boolean;
  onExport?: TableProps<T>['onExport'];
}

export function TableHeader<T>({
  table,
  hidden,
  showSearch,
  toolbar = [],
  header,
  headerTooltip,
  loading,
  onExport,
}: Props<T>) {
  if (hidden) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
        bgcolor: 'background.paper',
        p: 3,
        zIndex: 2,
        top: 0,
        position: 'sticky',
      }}
    >
      {isPrimitive(header) ? (
        <Typography variant="h3" m={0}>
          {header}
        </Typography>
      ) : (
        header
      )}
      {headerTooltip && (
        <Tooltip title={headerTooltip} placement="right">
          <InfoIcon fontSize="small" />
        </Tooltip>
      )}
      <Box flex={1} />
      <TableToolbar
        table={table}
        showSearch={showSearch}
        items={toolbar}
        loading={loading}
        onExport={onExport}
      />
    </Box>
  );
}
