import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { HttpClient } from '../httpClient';
import {
  ListPipeline,
  ListPipelinesResponse,
  Pipeline,
} from '../interfaces/pipeline';
import pipelinesQuery from '../graphql/pipelines';
import { InputError } from '@backstage/errors';
import { ListRunParams, ListRunsResponse, Run } from '../interfaces/run';

export interface PipelineServiceApi {
  getPipelineBySlug(pipelineSlug: string): Promise<Pipeline>;
  getPipelines(
    listPipelineParameters: ListPipeline,
  ): Promise<ListPipelinesResponse>;
  getRuns(queryParameters: ListRunParams): Promise<ListRunsResponse>;
  archivePipeline(pipeline_id: number): Promise<number>;
  getRunBySlug(runSlug: string): Promise<Run>;
}

export class PipelineService implements PipelineServiceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getPipelineBySlug(pipelineSlug: string): Promise<Pipeline> {
    return this.httpClient.get(`/pipelines/slug/${pipelineSlug}`);
  }

  async getPipelines(
    listPipelineParameters: ListPipeline,
  ): Promise<ListPipelinesResponse> {
    const variables = this.httpClient.cleanUpParameters(listPipelineParameters);

    variables.archived = variables?.archived === 'yes';
    variables.with_runs = variables.with_runs === 'yes';

    if (variables.with_runs) {
      variables.include = ['last_run', 'steps'];
    }

    return this.httpClient
      .post('/graphql', {
        operationName: 'Pipelines',
        query: pipelinesQuery,
        variables,
      })
      .then(response => {
        if (response.data.errors && !response.data.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the pipelines data',
          );
        }
        return response.data;
      });
  }

  async getRuns(queryParameters: ListRunParams): Promise<ListRunsResponse> {
    const variables = this.httpClient.cleanUpParameters(queryParameters);
    variables.include = ['steps'];

    return this.httpClient.get('/runs/', variables).then(response => {
      return {
        runs: response.items as Run[],
        total: response.total,
      } as ListRunsResponse;
    });
  }

  async archivePipeline(pipeline_id: number): Promise<number> {
    return this.httpClient.patch(`/pipelines/archive/${pipeline_id}`, {
      archive: true,
    });
  }

  async getRunBySlug(runSlug: string): Promise<Run> {
    return this.httpClient.get(`/runs/slug/${runSlug}`);
  }
}

export const pipelinesApiRef = createApiRef<PipelineServiceApi>({
  id: 'ml-platform-pipelines-api',
});
