import React from 'react';
import { SearchConfigForGCS } from '../models';
import { AccentColors } from '../constants/AccentColors';
import { HighlightedGCSTexts } from '../components/SearchResultHighlighter/HighlightedGCSTexts';
import { CollibraIcon } from 'plugin-ui-components';
import { formatIndexLabelToID } from '../utils';

const label = 'Collibra';
const id = formatIndexLabelToID(label);
export const collibraIndex = new SearchConfigForGCS({
  id,
  label,
  datasource: 'collibra',
  icon: CollibraIcon,
  accentColor: AccentColors.Default,
  dynamicFilters: {
    creator: {
      excluded: true,
    },
  },
  getUrl: hit => hit.url,
  getTitle: hit => hit.title,
  render: hit => <HighlightedGCSTexts snippet={hit.snippet} />,
});
