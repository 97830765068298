import React, { type JSX, useMemo } from 'react';

type SVGProps = JSX.IntrinsicElements['svg'];
interface Props extends SVGProps {
  extension: string;
}

export function FileIcon({ extension }: Props) {
  const maskId = useMemo(
    () =>
      [...Array(9)]
        .map(() => Math.floor(Math.random() * 36).toString(36))
        .join(''),
    [],
  );

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <mask id={maskId}>
          <text
            x="35%"
            y="50%"
            writingMode="tb"
            fontSize="6"
            fontWeight="200"
            fontFamily="Roboto, sans-serif"
            textAnchor="middle"
            dominantBaseline="middle"
            letterSpacing="1"
            fill="black"
          >
            {extension.toUpperCase()}
          </text>
        </mask>
      </defs>
      <path
        strokeWidth="1"
        d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
      />
      <polyline strokeWidth="1" points="14 2 14 8 20 8" />
      <rect
        x="4"
        y="0"
        width="16"
        height="100%"
        fill="currentColor"
        mask={`url(#${maskId})`}
        strokeWidth="1"
      />
    </svg>
  );
}
