import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { lighten } from '@mui/material/styles';

const useStyles = makeStyles(theme => {
  const infoText =
    theme.palette.mode === 'dark'
      ? lighten(theme.palette.infoText, 0.5)
      : theme.palette.infoText;

  return {
    icon: {
      color: infoText,
    },
    panel: {
      border: '1px solid',
      borderColor: theme.palette.infoBackground,
      color: theme.palette.infoText,
      verticalAlign: 'middle',
      marginBottom: '1rem',
    },
    summaryText: {
      color: infoText,
      fontWeight: 'bold',
    },
    message: {
      width: '100%',
      display: 'block',
      color: infoText,
      '& > a': {
        textDecoration: 'underline',
      },
    },
    details: {
      width: '100%',
      display: 'block',
      color: theme.palette.textContrast,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.palette.border}`,
      padding: theme.spacing(2.0),
      fontFamily: 'sans-serif',
    },
  };
});

type Props = {
  title?: string;
  message?: React.ReactNode;
  children?: React.ReactNode;
};

/**
 * InfoPanel. Show a user friendly message to a user.
 *
 * @param {string} [title] A title for the info panel.
 * @param {Object} [message] Optional more detailed user-friendly message elaborating on the cause of the error.
 * @param {Object} [children] Objects to provide context, such as a stack trace or detailed error reporting.
 *  Will be available inside an unfolded accordion.
 */
export const InfoPanel = (props: Props) => {
  const classes = useStyles(props);
  const { title, message, children } = props;

  return (
    <Accordion className={classes.panel} variant="outlined">
      <AccordionSummary expandIcon={<ExpandMoreIcon color="info" />}>
        <Box display="flex" gap={0.5} alignItems="center">
          <ErrorOutline fontSize="small" className={classes.icon} />
          <Typography className={classes.summaryText} variant="subtitle1">
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      {(message || children) && (
        <AccordionDetails sx={{ p: 0 }}>
          <Grid container>
            {message && (
              <Grid item xs={12}>
                <Typography className={classes.message} variant="body1">
                  {message}
                </Typography>
              </Grid>
            )}
            {children && (
              <Grid item xs={12} className={classes.details}>
                {children}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
};
