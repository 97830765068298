import React from 'react';
import {
  PieChartProps,
  PieChart as XChartsPie,
  useDrawingArea,
} from '@mui/x-charts';
import useTheme from '@mui/styles/useTheme';

function PieCenterLabel({
  children,
  fill = 'black',
  ...labelProps
}: React.PropsWithChildren<
  { fill?: string } & React.SVGProps<SVGTextElement>
>) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <text
      fill={fill}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={20}
      transform={`translate(${left + width / 2}, ${top + height / 2})`}
      {...labelProps}
    >
      {children}
    </text>
  );
}

interface IPieChartProps extends PieChartProps {
  displayValue?: React.ReactNode | string | number;
  labelProps?: React.SVGProps<SVGTextElement>;
}

export const PieChart = ({
  displayValue,
  labelProps,
  ...props
}: IPieChartProps & React.RefAttributes<unknown>) => {
  const theme = useTheme();
  return (
    <XChartsPie {...props}>
      <PieCenterLabel fill={theme.palette.text.primary} {...labelProps}>
        {displayValue}
      </PieCenterLabel>
    </XChartsPie>
  );
};

export const PIE_CHART_SIZE_MAP = {
  small: {
    width: 70,
    height: 70,
    innerRadius: 25,
    outerRadius: 30,
    fontSize: 14,
    labelX: 31,
    labelY: 35,
  },
  medium: {
    width: 90,
    height: 90,
    innerRadius: 35,
    outerRadius: 40,
    fontSize: 16,
    labelX: 41,
    labelY: 45,
  },
  large: {
    width: 110,
    height: 110,
    innerRadius: 50,
    outerRadius: 60,
    fontSize: 20,
    labelX: 51,
    labelY: 55,
  },
};
