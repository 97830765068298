import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { useStyles } from './styles';
import type { IOption } from './types';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
  label: string;
  options: IOption[];
  value: string[];
  onChange: (value: string[]) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const MultiSelectFilter = React.memo(
  ({ label, options, value, onChange, loading = false, disabled }: Props) => {
    const id = `${label.split(' ').join('-').toLowerCase()}-filter`;
    const { inputRoot } = useStyles();

    return (
      <FormControl disabled={loading || disabled}>
        <Autocomplete
          id={id}
          multiple
          options={options}
          classes={{ inputRoot }}
          value={options.filter(i => value.includes(i.value))}
          onChange={(_: any, val) => onChange(val.map(v => v.value))}
          disabled={loading || disabled}
          renderInput={params => (
            <TextField
              label={label}
              {...params}
              size="small"
              variant="outlined"
              margin="dense"
              InputProps={
                loading
                  ? {
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          <CircularProgress size={16} />
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }
                  : params.InputProps
              }
            />
          )}
        />
      </FormControl>
    );
  },
);
