import { Box } from '@mui/system';
import React from 'react';
import { useServices } from '../../../../services';
import { useParams } from 'react-router';
import { DeploymentArtifacts } from '../Artifacts/DeploymentArtifacts';
import { getDomainFromAlias } from '../../../../utils/domains';
import { STEP_STATUS } from '../../../../constants';
import { getMessageByStatus } from './utils';
import Link from '@mui/material/Link';
import { Text } from '../../../../common';

export function DeploymentResources() {
  const {
    domain_alias = '',
    org_name = '',
    repo_name = '',
    runId = '',
  } = useParams();
  const { runService } = useServices();

  if (runService.step.associated_step.ordinal === -1) {
    return (
      <Box padding={2}>
        <span>This step doesn't use deployment artifacts.&nbsp;</span>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://cloud.docs.zalando.net/concepts/builds/#deployment-artifacts"
        >
          Read more
        </Link>
        <span>&nbsp;about how to use the feature.</span>
      </Box>
    );
  }

  const associatedStepDetails = runService.run.steps.find(
    step => step.ordinal === runService.step.associated_step.ordinal,
  );

  if (associatedStepDetails?.status !== STEP_STATUS.SUCCEEDED) {
    return (
      <Box padding={2}>
        {getMessageByStatus(associatedStepDetails?.status || '')}
      </Box>
    );
  }

  return (
    <Box padding={2}>
      <Text marginBottom="15px" display="block">
        <span>Deployment resources from&nbsp;</span>
        <strong>{runService.step.deployArtifact}</strong>
        <span>&nbsp;artifact, created by&nbsp;</span>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={`../${runService.step.associated_step.ordinal}`}
        >
          {runService.step.associated_step.id}
        </Link>
        <span>&nbsp;step.</span>
      </Text>

      <DeploymentArtifacts
        repository={{
          host: getDomainFromAlias(domain_alias),
          org: org_name,
          name: repo_name,
        }}
        pipelineID={runId}
        artifactName={runService.step.deployArtifact}
        hasTitle={false}
      />
    </Box>
  );
}
