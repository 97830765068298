import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';

interface IGroupFilter<T> {
  id: T;
  label: string;
  count: number;
  icon?: React.ReactNode;
  loading?: boolean | number;
}

interface IGroup<T> {
  label: string;
  filters: IGroupFilter<T>[];
  hidden?: boolean;
}

interface Props<T> {
  selected: T;
  groups: IGroup<T>[];
  onClick: (newValue: T) => void;
}

export function GroupFilters<T = string>({
  selected,
  groups,
  onClick,
}: Props<T>) {
  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.11)',
        gridArea: 'filters',
        height: 'fit-content',
        padding: 1,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {groups.map((group, i) => {
        if (group.hidden) return <></>;
        return (
          <Box mb={2}>
            <Typography
              variant="subtitle2"
              sx={{ fontSize: 12, fontWeight: 'bold', marginBottom: '0.5rem' }}
            >
              {group.label.toUpperCase()}
            </Typography>
            <List
              key={i}
              disablePadding
              sx={{
                gridArea: 'filters',
                borderRadius: 1.5,
                overflow: 'hidden',
                borderBottom: '2px solid rgba(100,100,100,0.5)',
              }}
            >
              {group.filters.map(filter => (
                <ListItemButton
                  key={filter.id as string}
                  disableRipple
                  onClick={() => onClick(filter.id)}
                  selected={filter.id === selected}
                  sx={theme => ({
                    backgroundColor: theme.palette.background.paper,
                    '&:hover': {
                      backgroundColor: 'var(--mui-selected-list-item)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'var(--mui-selected-list-item)',
                      '&:hover': {
                        backgroundColor: 'var(--mui-selected-list-item)',
                      },
                    },
                  })}
                >
                  <ListItemText
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      '& > span': {
                        fontWeight: 500,
                      },
                    }}
                    primary={filter.label}
                    secondary={filter.count}
                  />
                  {!!filter.loading && (
                    <LinearProgress
                      value={
                        typeof filter.loading === 'number'
                          ? filter.loading
                          : undefined
                      }
                      variant={
                        typeof filter.loading === 'number'
                          ? 'determinate'
                          : 'indeterminate'
                      }
                      sx={{ position: 'absolute', insetInline: 0, bottom: 0 }}
                    />
                  )}
                </ListItemButton>
              ))}
            </List>
          </Box>
        );
      })}
    </Box>
  );
}
