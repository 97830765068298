import type { GlobalSearchConfig } from './types';
import * as indexes from './indexes';

export const searchConfig: GlobalSearchConfig = {
  layout: {
    filterOrientation: 'vertical',
  },
  indexes: [
    indexes.zDocsIndex,
    indexes.peopleIndex,
    indexes.teamsIndex,
    indexes.applicationsIndex,
    indexes.apisIndex,
    indexes.resourceTypes,
    indexes.toolingIndex,
    indexes.githubIndex,
    indexes.zFlowIndex,
    indexes.collibraIndex,
    indexes.googleDriveIndex,
    indexes.otherIndex,
  ],
};
