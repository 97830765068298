import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  BaseLayout,
  ListEntities,
  StatusTableCell,
  ActionsTableCell,
  CopyActionsBar,
  StatusFilter,
} from '../../components';
import { useApi } from '@backstage/core-plugin-api';
import { RunStatusEnum, notebooksApiRef } from '../../api';
import { RunsPlaceholder } from './RunsPlaceholder';
import { constructLinkToDataLabNotebook } from '../../utils';
import { Content, SubvalueCell } from '@backstage/core-components';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';

export const Runs = () => {
  const { scheduledNotebookId = '' } = useParams();
  const notebookClient = useApi(notebooksApiRef);
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState<RunStatusEnum>();
  const [runsCount, setRunsCount] = useState(-1);
  const [archiveExecutionError, setArchiveExecutionError] = useState<Error>();
  const [notebookName, setNotebookName] = useState('');

  useEffect(() => {
    const getScheduledNotebook = async () => {
      const res = await notebookClient.getScheduledNotebookDetails(
        scheduledNotebookId,
      );
      setNotebookName(res.name);
    };
    getScheduledNotebook();
  }, [scheduledNotebookId, notebookClient]);

  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await notebookClient.deleteRun(id, scheduledNotebookId);
    } catch (err) {
      setArchiveExecutionError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const entityPromise = notebookClient.getRuns(
    scheduledNotebookId,
    page,
    statusFilter,
  );
  useEffect(() => {
    entityPromise.then(e => {
      setRunsCount(e.meta.total);
    });
  }, [entityPromise]);

  const COLUMNS = [
    {
      title: 'Name',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Status',
      field: 'row.status',
      filtering: true,
      filterComponent: () => (
        <StatusFilter value={statusFilter} onChange={setStatusFilter} />
      ),
      render: (row: any) => <StatusTableCell status={row?.status} />,
    },
    {
      title: 'Created At',
      field: 'created_at',
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="scheduled_notebooks"
          id={row.scheduled_notebook_id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="runs"
          childId={row.id}
          onDelete={onDelete}
        />
      ),
    },
  ];
  const table = (
    <ListEntities
      getEntitiesPromise={entityPromise}
      entityKey="runs"
      columns={COLUMNS}
      childEntityKey=""
      title="Runs"
      refreshList={refreshList}
      page={page}
      onPageChange={setPage}
      error={archiveExecutionError}
      setError={setArchiveExecutionError}
    />
  );

  return (
    <BaseLayout notebookName={notebookName}>
      <Content>
        <CopyActionsBar type="runs" id={scheduledNotebookId} showRunNow />
        {runsCount === 0 && statusFilter === undefined ? (
          <RunsPlaceholder scheduledNotebookId={scheduledNotebookId} />
        ) : (
          table
        )}
      </Content>
    </BaseLayout>
  );
};
