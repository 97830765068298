import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApplicationScorecardsContext } from '../ApplicationScorecardsContext';
import { useAsyncRetry } from 'react-use';
import { getApplicationScorecards } from '../utils';

interface ScorecardMetrics {
  passing: number;
  failing: number;
}

interface TeamScorecardMetrics extends ScorecardMetrics {
  score: number;
  notApplicable: number;
}

export interface UserTeamsScorecardsInfo {
  teamMetrics: Map<string, TeamScorecardMetrics>;
  appsByTeam: Map<string, IEntityApp[]>;
  scorecardAssessmentsByTeams: Map<string, ScorecardEntityWithAssessment[][]>;
}

// Hook to fetch all scorecards and assessments related to an application
export function useApplicationScorecards(
  application: Entity,
  scorecardsToShow?: IEntityScorecard[],
) {
  const contextData = useApplicationScorecardsContext();
  const catalogApi = useApi(catalogApiRef);
  return useAsyncRetry(async () => {
    return getApplicationScorecards(
      application,
      contextData,
      catalogApi,
      scorecardsToShow,
    );
  }, [application, scorecardsToShow, contextData]);
}
