import React from 'react';
import { Link, SidebarPinStateContext } from '@backstage/core-components';
import IconButton from '@mui/material/IconButton';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import AppsIcon from '@mui/icons-material/Apps';
import SupportIcon from '@mui/icons-material/ContactSupport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DomainIcon from '@mui/icons-material/Domain';
import MoneyIcon from '@mui/icons-material/Euro';
import LocalLibraryIcon from '@mui/icons-material/LocalLibraryOutlined';
import Lock from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import TextsmsIcon from '@mui/icons-material/Textsms';
import InsightsIcon from '@mui/icons-material/Insights';
import cn from 'classnames';
import {
  APIsIcon,
  CICDPipelinesIcon,
  CyberWeekIcon,
  FeatureFlagIcon,
  MLProjectsIcon,
  TechRadarIcon,
  TrackedLink,
  useFilteredNav,
} from 'plugin-ui-components';
import { LogoFull, LogoIcon } from './Logo';
import { Shortcuts } from './Shortcuts';
import { SearchModalChip } from './SearchModalChip';
import { sideBarClick } from './tracking';
import { Psychology } from '@mui/icons-material';
import RouteIcon from '@mui/icons-material/Route';
import { ZflowIcon } from 'plugin-ml';

const allNavs: Array<NavItem> = [
  {
    icon: SearchIcon,
    href: '/search',
    text: 'Search',
  },
  {
    icon: TextsmsIcon,
    href: '/ask',
    text: 'Ask Sunrise',
  },
  {},
  { icon: AddIcon, href: '/create', text: 'Create' },
  {},
  {
    icon: DomainIcon,
    href: '/domains',
    text: 'Domains',
    routes: ['/catalog/default/Domain'],
    featureFlag: 'domains',
    isAdminOnly: true,
  },
  {
    icon: AppsIcon,
    href: '/applications',
    text: 'Applications',
    routes: ['/catalog/default/Component'],
  },
  {
    icon: APIsIcon,
    href: '/apis',
    text: 'APIs',
    routes: ['/catalog/default/API'],
  },
  {
    icon: LocalLibraryIcon,
    href: '/libraries',
    featureFlag: 'dependencies-plugin',
    text: 'Libraries',
    routes: ['/catalog/library/Component'],
  },
  {
    icon: MenuBookIcon,
    href: '/docs',
    text: 'Documentation',
    routes: ['/docs'],
    featureFlag: 'documentation-index',
    persist: true,
  },
  {
    icon: PeopleIcon,
    href: '/teams',
    text: 'Teams',
    routes: ['/catalog/default/group'],
  },
  {
    icon: DashboardIcon,
    href: '/tooling',
    text: 'Tooling',
    routes: ['/catalog/default/Tooling'],
  },
  { icon: TechRadarIcon, href: '/tech-radar', text: 'Tech Radar' },
  { icon: CyberWeekIcon, href: '/cyberweek', text: 'CyberWeek' },
  {
    icon: ReportProblemIcon,
    href: '/reliability-center',
    text: 'Reliability',
  },
  {
    icon: Lock,
    href: '/compliance-and-security',
    text: 'Compliance & Security',
  },
  {
    icon: SpeedIcon,
    href: '/load-tests',
    text: 'Load Testing',
    featureFlag: 'load-testing',
  },
  {
    icon: RouteIcon,
    href: '/rkeep',
    text: 'rkeep - Route Manager',
  },
  {},
  { icon: CICDPipelinesIcon, href: '/cdp', text: 'CI/CD Pipelines' },
  {
    icon: AccountBalanceIcon,
    href: '/infrastructure',
    text: 'Infrastructure',
  },
  {
    icon: MoneyIcon,
    href: '/cloud-cost-insights',
    text: 'Cost Insights',
  },
  {
    icon: InsightsIcon,
    href: '/tech-insights',
    text: 'Tech Insights',
    routes: ['/tech-insights', '/catalog/default/scorecard'],
  },
  {},
  { icon: MLProjectsIcon, href: '/ml', text: 'ML Platform' },
  {
    icon: ZflowIcon,
    href: '/mlp/pipelines?with_runs=yes&archived=no',
    featureFlag: 'ml-platform',
    text: 'ML Pipelines',
  },
  {
    icon: MLProjectsIcon,
    href: '/mlp/shared-notebooks',
    featureFlag: 'ml-platform-notebooks',
    text: 'Notebooks',
  },
  {
    icon: Psychology,
    href: '/mlp/models?archived=no',
    featureFlag: 'ml-platform',
    text: 'ML Models',
  },
  {
    icon: Psychology,
    href: '/mlp/ai-systems?group=mine',
    featureFlag: 'ml-platform',
    text: 'AI Systems',
  },
  {},
  { icon: SupportIcon, href: '/support', text: 'Support' },
  {
    icon: AnnouncementIcon,
    href: '/announcements',
    text: 'Announcements',
  },
  { icon: SettingsIcon, href: '/settings', text: 'Settings' },
  {},
];

export function AppSideBar() {
  const { isMobile } = React.useContext(SidebarPinStateContext);
  const [expanded, setExpanded] = React.useState(false);
  const navs = useFilteredNav(allNavs);
  const { isPinned: pinned } = React.useContext(SidebarPinStateContext);

  function onMenuClick() {
    const value = !expanded;
    setExpanded(value);
    if (value) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }

  function onNavClick() {
    if (isMobile) {
      setExpanded(false);
    }
  }

  return (
    <>
      <aside className={cn({ pinned: isMobile || pinned, expanded })}>
        <div>
          <Link className="brand" to="/" onClick={onNavClick}>
            <LogoIcon />
            <LogoFull />
          </Link>
          <nav>
            {navs.map(
              ({ href, text, icon: Icon, routes = [], featureFlag }, i) => {
                if (href && text && Icon) {
                  const pathname = window.location.pathname.toLowerCase();
                  const active = [href, ...routes!].some(r =>
                    pathname.startsWith(r.toLowerCase()),
                  );
                  return (
                    <TrackedLink
                      to={href}
                      key={i}
                      className={cn({ active })}
                      onClick={onNavClick}
                      {...sideBarClick(text)}
                    >
                      <Icon className="side-bar-icon" />
                      <div className="side-bar-text">
                        <span>{text}</span>
                        {featureFlag && <FeatureFlagIcon name={featureFlag} />}
                        {href === '/search' && <SearchModalChip />}
                      </div>
                    </TrackedLink>
                  );
                }
                return <hr key={i} />;
              },
            )}
            <Shortcuts onNavClick={onNavClick} />
          </nav>
        </div>
      </aside>
      {/* only visible in mobile */}
      <div className="tab-bar">
        <IconButton onClick={onMenuClick}>
          <MenuIcon />
        </IconButton>
      </div>
    </>
  );
}
