import React, { FormEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Alert from '@mui/lab/Alert';
import { useAsync } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { Link, LinkButton } from '@backstage/core-components';
import { slugify } from 'plugin-core';
import { SunriseHttpError, techInsightsApiRef } from '../../../api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import type { FieldValidationError } from 'express-validator';

interface Props {
  scorecard?: TechInsights.Scorecard;
  retry?: (scorecard: TechInsights.Scorecard) => void;
}

const SCORECARD_CATEGORIES = ['Operational Excellence', 'APEC', 'Dependencies'];

export function ScorecardForm({ scorecard, retry }: Props) {
  const alertApi = useApi(alertApiRef);
  const techInsightsApi = useApi(techInsightsApiRef);
  const [name, setName] = useState(scorecard?.name ?? '');
  const [title, setTitle] = useState(scorecard?.title ?? '');
  const [description, setDescription] = useState(scorecard?.description ?? '');
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedChecks, setSelectedChecks] = useState<TechInsights.Check[]>(
    [],
  );
  const [error, setError] = useState<SunriseHttpError>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const mode = !!scorecard ? 'edit' : 'create';

  useEffect(
    () => setError(undefined),
    [name, description, selectedChecks, title],
  );

  useEffect(() => {
    if (scorecard) {
      setSelectedChecks(scorecard.checks);
      setSelectedCategories(scorecard.categories);
    }
  }, [scorecard]);

  const { value: checks, loading: checksLoading } = useAsync(
    techInsightsApi.getChecks.bind(techInsightsApi),
    [],
  );

  function handleSubmit(ev: FormEvent) {
    ev.preventDefault();

    if (mode === 'edit') {
      updateScorecard();
    } else {
      createScorecard();
    }
  }

  function createScorecard() {
    setLoading(true);
    techInsightsApi
      .createScorecard({
        name: name || (null as any),
        title: title || (null as any),
        description: description || (null as any),
        checks_ids: selectedChecks.map(check => check.id),
        categories: selectedCategories,
      })
      .then(newScorecard => {
        alertApi.post({
          message: 'Scorecard created successfully',
          severity: 'success',
          display: 'transient',
        });
        if (retry) retry(newScorecard);
        navigate(`/catalog/default/scorecard/${newScorecard.name}/edit`);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }

  function updateScorecard() {
    if (!scorecard) return;
    setLoading(true);
    techInsightsApi
      .updateScorecard(scorecard.id, {
        title: title || (null as any),
        description: description || (null as any),
        checks_ids: selectedChecks.map(check => check.id),
        categories: selectedCategories,
      })
      .then(updatedScorecard => {
        alertApi.post({
          message: 'Scorecard updated successfully',
          severity: 'success',
          display: 'transient',
        });
        if (retry) retry(updatedScorecard);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <form onSubmit={handleSubmit}>
        {loading && (
          <Box marginBlock="1rem">
            <LinearProgress />
          </Box>
        )}
        <Card>
          <CardHeader title={`${!!scorecard ? 'Edit' : 'Create'} scorecard`} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="title"
                    label="Title"
                    id="title"
                    value={title}
                    onChange={ev => {
                      setTitle(ev.target.value);
                      if (mode === 'create') {
                        setName(slugify(ev.target.value));
                      }
                    }}
                    error={!!error?.forField?.('title')}
                    helperText="The title of the scorecard that reflects its purpose"
                    size="small"
                    disabled={loading}
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    multiple
                    options={checks ?? []}
                    getOptionLabel={check => check.title}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Checks"
                        error={!!error?.forField?.('checks_ids')}
                        required={selectedChecks?.length === 0}
                      />
                    )}
                    value={selectedChecks}
                    onChange={(_, newValue) => setSelectedChecks(newValue)}
                    size="small"
                    loading={checksLoading}
                    disabled={loading}
                  />
                  <FormHelperText>
                    Select the checks that will be executed in the assessment of
                    this scorecard. If you cannot find the desired check, you
                    can{' '}
                    <Link to="/tech-insights/checks/create">
                      register a new check
                    </Link>{' '}
                    first.
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <TextField
                    name="description"
                    label="Description"
                    placeholder="Scorecard description"
                    value={description}
                    onChange={ev => setDescription(ev.target.value)}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!error?.forField?.('description')}
                    helperText="A description that explains the purpose of the scorecard"
                    disabled={loading}
                    size="small"
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    multiple
                    options={SCORECARD_CATEGORIES}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Categories"
                        required={selectedCategories?.length === 0}
                      />
                    )}
                    value={selectedCategories}
                    onChange={(_, newValue) => setSelectedCategories(newValue)}
                    size="small"
                    disabled={loading}
                  />
                  <FormHelperText>
                    The categories of the scorecard to help prioritize and
                    improve filtering.
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              mt={2}
            >
              <LinkButton
                to={
                  mode === 'edit'
                    ? `/catalog/default/scorecard/${name}`
                    : '/tech-insights'
                }
                color="primary"
                variant="outlined"
              >
                Cancel
              </LinkButton>
              <Button type="submit" variant="contained" disabled={loading}>
                Save
              </Button>
            </Grid>
            {error && (
              <Box marginBlock="1rem">
                <Alert severity="error" onClose={() => setError(undefined)}>
                  {error.message}
                  {Array.isArray(error.data) && (
                    <Box component="ul" paddingInlineStart="1rem" margin="0">
                      {error.data
                        .filter(e => e.type === 'field' && e.path !== 'name')
                        .map((e, i) => (
                          <li key={i}>
                            <Box
                              component="span"
                              textTransform="capitalize"
                              fontWeight="bold"
                            >
                              {(e as FieldValidationError).path}
                            </Box>
                            : {e.msg}
                          </li>
                        ))}
                    </Box>
                  )}
                </Alert>
              </Box>
            )}
          </CardContent>
        </Card>
      </form>
    </Box>
  );
}
