import { useMemo } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import type { TableColumn } from '../types';
import type {
  CustomFilterFns,
  FilterFn,
  TableFilterOption,
  TableGroupFilter,
} from '../types/filtering';
import {
  DEFAULT_BOOLEAN_FILTER_OPTIONS,
  DEFAULT_EXISTS_FILTER_OPTIONS,
} from '../constants';

function getDefaultFilterFn<T>(
  column: ColumnDef<T> & TableColumn<T>,
): CustomFilterFns | undefined {
  if (!column.filtering) return undefined;
  const { filterFn, filterType } = column.filtering;
  if (filterFn) return filterFn as any;
  if (
    (column.filtering.filterType === undefined ||
      column.filtering.filterType === 'select') &&
    column.filtering.multiSelect &&
    !column.filtering.filterFn
  ) {
    return 'arrEqualsSome';
  }

  // prettier-ignore
  switch (filterType) {
    case 'date': return 'dateEquals';
    case 'date-time': return 'dateTimeEquals';
    case 'month': return 'monthEquals';
    case 'time': return 'timeEquals';
    case 'date-range': return 'dateBetween';
    case 'date-time-range': return 'dateTimeBetween';
    case 'month-range': return 'monthBetween';
    case 'time-range': return 'timeBetween';
    default: return 'equals';
  }
}

export function useProcessedColumns<T>(
  columns: TableColumn<T>[],
  groupFilters?: TableGroupFilter<T>[],
) {
  return useMemo(() => {
    const columnFilters: TableColumn<T>[] = [];

    if (groupFilters?.length) {
      const groupOptions = groupFilters.map(
        (g): TableFilterOption => ({ label: g.label, value: g.id }),
      );
      const filterFn: FilterFn<T> = (row, _columnId, filterValue) => {
        const selectedGroup = groupFilters.find(
          g => g.id === filterValue[0].value,
        );

        if (!selectedGroup) return true;
        return selectedGroup.filterFn?.(row.original) ?? true;
      };
      const defaultFilter = groupFilters.find(g => g.default);
      const defaultOption =
        defaultFilter && groupOptions.find(o => o.value === defaultFilter?.id);
      const groupColumn: ColumnDef<T> & TableColumn<T> = {
        id: 'group',
        hidden: true,
        enableGlobalFilter: false,
        filterFn,
        filtering: {
          filterType: 'select',
          options: groupOptions,
          initialSelection: [defaultOption ?? groupOptions[0]],
          filterFn,
        },
      };
      columnFilters.push(groupColumn);
    }

    columns.forEach(c => {
      const newColumn: ColumnDef<T> & TableColumn<T> = { ...(c as any) };
      if (newColumn.filtering) {
        // Set default for select filter
        if (
          newColumn.filtering.filterType === undefined ||
          newColumn.filtering.filterType === 'select'
        ) {
          // Resolve filter options generators
          if (typeof newColumn.filtering.options === 'function') {
            newColumn.filtering = {
              ...newColumn.filtering,
              options: newColumn.filtering.options(),
            };
          }

          // Set default options
          if (!newColumn.filtering.options) {
            const { filterFn } = newColumn.filtering;
            if (filterFn === 'boolean') {
              newColumn.filtering.options = DEFAULT_BOOLEAN_FILTER_OPTIONS;
            } else if (filterFn === 'exists') {
              newColumn.filtering.options = DEFAULT_EXISTS_FILTER_OPTIONS;
            } else {
              newColumn.filtering.options = [];
            }
          }

          // Enable multi-select for array filters
          if (
            typeof newColumn.filtering.filterFn === 'string' &&
            newColumn.filtering.filterFn.startsWith('arr') &&
            newColumn.filtering.multiSelect === undefined
          ) {
            newColumn.filtering.multiSelect = true;
          }

          // Enable searchable for multi-select filters by default
          if (
            newColumn.filtering.multiSelect &&
            newColumn.filtering.searchable === undefined
          ) {
            newColumn.filtering.searchable = true;
          }
        }

        // Set default column filterFn
        newColumn.filterFn = getDefaultFilterFn(newColumn) as any;

        // Set default filter type
        if (!newColumn.filtering.filterType) {
          newColumn.filtering.filterType = 'select';
        }
      }

      columnFilters.push(newColumn);
    });

    return columnFilters;
  }, [columns, groupFilters]);
}
