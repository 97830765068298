import { useEffect, useRef, useState } from 'react';

const defaultCopyText = 'Copy shareable link';

export function useHoverAndCopy() {
  const mountedRef = useRef<boolean>(true);
  const [copyText, setCopyText] = useState(defaultCopyText);
  const [hover, setHover] = useState(false);

  const handleHover = () => setHover(true);
  const handleMouseOut = () => {
    setHover(false);
    setCopyText(defaultCopyText);
  };
  const copyToClipboard = (path: string) => {
    setCopyText('Copied!');
    setTimeout(() => {
      if (mountedRef.current) setCopyText(defaultCopyText);
    }, 4000);
    navigator.clipboard.writeText(path);
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return { copyText, hover, handleHover, handleMouseOut, copyToClipboard };
}
