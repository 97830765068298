import React from 'react';
import { useDeploymentArtifacts } from '../../../../../hooks/useDeploymentArtifacts';
import LinearProgress from '@mui/material/LinearProgress';
import { ErrorBox } from 'plugin-ui-components';
import {
  CopyButton,
  fontSize,
  List,
  ListItem,
  Text,
} from '../../../../../common';
import yaml from 'js-yaml';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useStyles } from './styles';
import { Box } from '@mui/system';

type Props = {
  pipelineID: string;
  artifactName: string;
  hasTitle: boolean;
  repository: {
    host: string;
    org: string;
    name: string;
  };
};

function YamlFormatter({ yamlString }: { yamlString: string }) {
  const formatYaml = (input: string) => {
    try {
      const parsedYaml = yaml.load(input);

      return yaml.dump(parsedYaml, {
        indent: 2,
        lineWidth: -1,
        noRefs: true,
        sortKeys: false,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse YAML:', error);
      return input;
    }
  };

  const formattedYaml = formatYaml(yamlString);

  return (
    <Box marginTop="20px">
      <CopyButton text={formattedYaml} description="copy file content" />

      <SyntaxHighlighter language="yaml" style={docco}>
        {formattedYaml}
      </SyntaxHighlighter>
    </Box>
  );
}

export default YamlFormatter;

export function DeploymentArtifacts({
  repository,
  pipelineID,
  artifactName,
  hasTitle = true,
}: Props) {
  const { loading, error, files } = useDeploymentArtifacts(
    repository.host,
    repository.org,
    repository.name,
    pipelineID,
    artifactName,
  );

  const classes = useStyles();

  if (loading) {
    return <LinearProgress />;
  }

  if (!!error) {
    return <ErrorBox error={error} severity="error" />;
  }

  if (!files.length) {
    return (
      <ErrorBox
        description={`No files found for ${artifactName} artifact`}
        severity="info"
      />
    );
  }

  return (
    <div className={classes.artifact}>
      {hasTitle && (
        <Text fontSize={fontSize.medium} marginBottom="10px" display="block">
          {artifactName}
        </Text>
      )}

      <List>
        {files.map((file, index) => (
          <ListItem key={index}>
            <div className={classes.resources}>
              <details className={classes.details}>
                <YamlFormatter yamlString={file.content} />

                <summary className={classes.summary}>
                  <span className={classes.title}>
                    📁&nbsp;&nbsp;{file.name}
                  </span>
                </summary>
              </details>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
