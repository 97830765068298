import {
  DateParam,
  DateTimeParam,
  type QueryParamConfig,
} from 'use-query-params';

/** Custom boolean param decoder since the one found in the library converts the boolean into binary */
export const BooleanParam: QueryParamConfig<boolean | undefined> = {
  encode: value => {
    return value !== undefined && value !== null ? String(value) : undefined;
  },
  decode: value => {
    if (typeof value === 'string') {
      return value === 'true';
    }
    return undefined;
  },
};

export const ArrayDateParam: QueryParamConfig<
  Array<Date | string> | undefined | null,
  Array<Date | string> | undefined | null
> = {
  encode: (value): Array<string | null> | null | undefined => {
    if (!Array.isArray(value)) return undefined;
    return value.map(v => DateParam.encode(new Date(v)) ?? null) as any;
  },
  decode: value => {
    if (!Array.isArray(value)) return undefined;
    return value.map(v => DateParam.decode(v)) as any;
  },
  equals: (valueA, valueB) => {
    // eslint-disable-next-line eqeqeq
    if (valueA == null || valueB == null) return valueA === valueB;
    if (valueA.length !== valueB.length) return false;
    for (let i = 0; i < valueA.length; i++) {
      const dateA = new Date(valueA[i]);
      const dateB = new Date(valueB[i]);
      if (
        dateA.getFullYear() !== dateB.getFullYear() ||
        dateA.getMonth() !== dateB.getMonth() ||
        dateA.getDate() !== dateB.getDate()
      ) {
        return false;
      }
    }
    return true;
  },
};

export const ArrayDateTimeParam: QueryParamConfig<
  Array<Date | string> | undefined | null,
  Array<Date | string> | undefined | null
> = {
  encode: (value): Array<string | null> | null | undefined => {
    if (!Array.isArray(value)) return undefined;
    return value.map(v => {
      if (typeof v === 'string') return v;
      return DateTimeParam.encode(v) as string;
    });
  },
  decode: value => {
    if (!Array.isArray(value)) return undefined;
    return value.map(v => DateTimeParam.decode(v)) as any;
  },
  equals: (valueA, valueB) => {
    // eslint-disable-next-line eqeqeq
    if (valueA == null || valueB == null) return valueA === valueB;
    if (valueA.length !== valueB.length) return false;
    for (let i = 0; i < valueA.length; i++) {
      const dateA = new Date(valueA[i]);
      const dateB = new Date(valueB[i]);
      if (dateA.getTime() !== dateB.getTime()) {
        return false;
      }
    }
    return true;
  },
};
