import React, { ChangeEvent, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import InfoIcon from '@mui/icons-material/Info';
import { useServices } from '../../../../services';
import { Dialog, Tooltip } from '../../../../common';
import { ErrorMessage } from './ErrorMessage';
import * as S from '../styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const UpsertFormDialog = observer(({ isOpen, handleClose }: Props) => {
  const { secretsService } = useServices();

  const [secretID, setSecretID] = useState('');
  const [secretValue, setSecretValue] = useState('');
  const [availableInPR, setAvailableInPR] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setSecretID('');
      setSecretValue('');
      setAvailableInPR(false);
    }
  }, [isOpen]);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    secretsService.upsert(secretID, secretValue, availableInPR);
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        title="Create or update secret"
        testID="upsert-form-dialog"
        children={
          <S.Form onSubmit={handleSubmit}>
            <S.DialogContent dividers>
              {secretsService.upsertState.isNotFound && (
                <ErrorMessage secretID={secretID}>
                  404 Error: This can happen if the Secret ID is empty or if no
                  pipelines exist in this repository yet.
                </ErrorMessage>
              )}
              {secretsService.upsertState.hasConflict && (
                <ErrorMessage secretID={secretID}>
                  Updating 'Available in Pull Request' is not allowed, As
                  explained in the{' '}
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://sunrise.zalando.net/docs/default/Documentation/cloud/howtos/use-secrets-in-builds/#update-a-secret"
                  >
                    documentation.
                  </Link>
                </ErrorMessage>
              )}
              {secretsService.upsertState.hasError && (
                <ErrorMessage secretID={secretID}>
                  {secretsService.upsertState.message}
                </ErrorMessage>
              )}
              <FormControl required fullWidth>
                <InputLabel htmlFor="secret-id">Secret ID</InputLabel>
                <Input
                  id="secret-id"
                  inputProps={{
                    'data-testid': 'secret-id',
                  }}
                  autoComplete="off"
                  value={secretID}
                  disabled={secretsService.upsertState.isLoading}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSecretID(event.target.value)
                  }
                />
              </FormControl>
              <FormControl required fullWidth>
                <InputLabel htmlFor="secret-value">Secret Value</InputLabel>
                <Input
                  id="secret-value"
                  inputProps={{
                    'data-testid': 'secret-value',
                  }}
                  autoComplete="off"
                  value={secretValue}
                  disabled={secretsService.upsertState.isLoading}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSecretValue(event.target.value)
                  }
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={availableInPR}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setAvailableInPR(event.target.checked)
                    }
                  />
                }
                label="Available in Pull Requests"
              />
              <Tooltip title="CANNOT be changed once created.">
                <InfoIcon color="disabled" />
              </Tooltip>
            </S.DialogContent>
            <DialogActions>
              <Button
                disabled={secretsService.upsertState.isLoading}
                type="submit"
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </S.Form>
        }
      />
    </>
  );
});
