import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { HStack } from 'plugin-ui-components';

export const ClassificationChip = ({
  classification,
}: {
  classification: string;
}) => {
  const classificationInfo: {
    color: 'warning' | 'error' | 'info';
    tooltip: string;
  } = useMemo(() => {
    switch (classification) {
      case 'MUST':
        return {
          color: 'error',
          tooltip: `MUST means non-optional and expected in all cases.`,
        };
      case 'SHOULD':
        return {
          color: 'warning',
          tooltip: `SHOULD means opt-out, not opt-in; opting out requires a clear justification.`,
        };
      default:
        return {
          color: 'info',
          tooltip: `COULD means opt-in, ideally something would be done, resources and time allowing.`,
        };
    }
  }, [classification]);

  return (
    <Tooltip title={classificationInfo.tooltip}>
      <Chip
        color={classificationInfo.color}
        size="small"
        sx={{ width: 'fit-content' }}
        variant="outlined"
        label={
          <HStack alignItems="center" spacing=".5rem">
            <Box
              sx={theme => ({
                backgroundColor: theme.palette[classificationInfo.color].main,
                borderRadius: '50%',
                width: '8px',
                height: '8px',
              })}
            >
              &nbsp;
            </Box>
            {classification}
          </HStack>
        }
      />
    </Tooltip>
  );
};
