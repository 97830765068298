import React from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';

import { useServices } from '../../../../services';
import {
  getStepConfig,
  getStepExecutionReason,
  getStepRunDetails,
} from './utils';
import { STEP_TYPE } from '../../../../constants';
import { Emoji, List, ListItem, InfoCard } from '../../../../common';

import {
  Error,
  Warnings,
  StatusLog,
  Grid,
  GridItem,
  DeploymentProblems,
  UserErrors,
  InternalErrors,
  ValidationProblems,
} from '../../../common';
import { StepRunHistory } from './StepRunHistory';
import { ZesDetails } from '../ZesDetails';
import { TrafficProgress } from './TrafficProgress';
import Alert from '@mui/material/Alert';
import { RunStepRunModel } from '../../../../models';

function StepErrors({
  type,
  runner,
  run,
}: {
  type: string;
  runner: string;
  run: RunStepRunModel;
}) {
  return (
    <>
      {!!run.error &&
        (runner === 'zflow' ? (
          <ZesDetails cdpDeploymentId={run.id} cdpError={run.error} />
        ) : (
          <Error error={run.error} />
        ))}

      {type === STEP_TYPE.SCRIPT && !!run.user_errors.length && (
        <UserErrors userErrors={run.user_errors} />
      )}

      {type === STEP_TYPE.SCRIPT && !!run.internal_errors.length && (
        <InternalErrors internalErrors={run.internal_errors} />
      )}
    </>
  );
}

export const Details = observer(() => {
  const {
    runService: { step },
  } = useServices();

  const config = getStepConfig(step);
  const runDetails = getStepRunDetails(step);

  return (
    <Box padding={2}>
      <Grid>
        <GridItem>
          <InfoCard
            title={
              <span>
                <Emoji name="info" emoji="🛠️" /> Step configuration
              </span>
            }
          >
            <List>
              {config.map(
                (item, index) =>
                  Boolean(item.value) &&
                  item.value !== 0 && (
                    <ListItem key={index} icon={item.icon}>
                      {item.text}
                    </ListItem>
                  ),
              )}
            </List>
          </InfoCard>

          <InfoCard
            title={
              <span>
                <Emoji name="info" emoji="🚀" /> Latest step run
              </span>
            }
          >
            {step.run.id ? (
              <List>
                {runDetails.map(
                  (item, index) =>
                    Boolean(item.value) && (
                      <ListItem key={index} icon={item.icon}>
                        {item.text}
                      </ListItem>
                    ),
                )}
              </List>
            ) : (
              <Alert severity="info">{`The step doesn't have executions yet. Reason: ${getStepExecutionReason(
                step.status,
              )}`}</Alert>
            )}
          </InfoCard>

          {!!step.run_history.length && (
            <GridItem>
              <StepRunHistory history={step.run_history} />
            </GridItem>
          )}
        </GridItem>

        <GridItem>
          <StepErrors run={step.run} type={step.type} runner={step.runner} />

          {step.type === STEP_TYPE.TRAFFIC && Boolean(step.is_automated) && (
            <TrafficProgress />
          )}

          {!!step.run.status_log.length && (
            <StatusLog statusLog={step.run.status_log} />
          )}

          {!!step.run.warnings.length && (
            <Warnings warnings={step.run.warnings} />
          )}

          {!!Object.keys(step.run.problems.deploymentValidationProblems)
            .length && (
            <DeploymentProblems
              deploymentValidationProblems={
                step.run.problems.deploymentValidationProblems
              }
            />
          )}

          {!!step.run.problems.scriptValidationProblems.length && (
            <ValidationProblems
              validationProblems={step.run.problems.scriptValidationProblems}
            />
          )}
        </GridItem>
      </Grid>
    </Box>
  );
});
