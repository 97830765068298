import { useMemo } from 'react';
import { buildTree } from '../utils/tree.utils';
import type { TableProps } from '../types';

export function useProcessedData<T>(
  data: T[],
  treeMapper?: TableProps<T>['treeMapper'],
) {
  return useMemo(() => {
    if (treeMapper) {
      return buildTree(data, treeMapper);
    }

    return data;
  }, [treeMapper, data]);
}
