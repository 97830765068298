import { stringifyEntityRef } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { applicationColumns } from 'plugin-ui-components';
import React from 'react';
import { getColor } from '../../utils';
import { ClassificationChip } from '../common/scorecards/ClassificationChip';

function makeAssessmentColumn(
  scorecard: IEntityScorecard,
  assessments: IEntityScorecardAssessment[],
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Assessment',
    render: ({ entity }) => {
      const exclusion = scorecard.spec.exclusions.find(
        e => e.entity_ref === stringifyEntityRef(entity),
      );
      if (exclusion) {
        return (
          <Tooltip title={`Reason: ${exclusion.reason}`}>
            <Chip
              size="small"
              variant="outlined"
              className="info-chip"
              label="Skipped"
              sx={{ cursor: 'help' }}
            />
          </Tooltip>
        );
      }
      const assessment = assessments.find(
        a => a.spec.assessment.entityRef === stringifyEntityRef(entity),
      );
      if (!assessment) {
        return (
          <Chip
            size="small"
            variant="outlined"
            label="N/A"
            className="info-chip"
          />
        );
      }

      const formatter = new Intl.NumberFormat('en', {
        style: 'percent',
        maximumFractionDigits: 0,
      });
      const completion = assessment.spec.assessment.completionPercentage;

      return (
        <Chip
          size="small"
          variant="outlined"
          label={formatter.format(completion / 100)}
          className={`info-chip ${getColor(completion)}`}
        />
      );
    },
    sorting: true,
    customSort: (a, b) => {
      const completionA =
        assessments.find(
          assessment =>
            assessment.spec.assessment.entityRef ===
            stringifyEntityRef(a.entity),
        )?.spec.assessment.completionPercentage || 0;
      const completionB =
        assessments.find(
          assessment =>
            assessment.spec.assessment.entityRef ===
            stringifyEntityRef(b.entity),
        )?.spec.assessment.completionPercentage || 0;

      return order === 'asc'
        ? completionA - completionB
        : completionB - completionA;
    },
  };
}

function makeClassificationColumn(
  assessments: IEntityScorecardAssessment[],
): TableColumn<ICatalog.EntityRow> {
  return {
    title: 'Classification',
    render: ({ entity }) => {
      const assessment = assessments.find(
        a => a.spec.assessment.entityRef === stringifyEntityRef(entity),
      );
      if (!assessment || !assessment.spec.assessment.classification) {
        return <Chip size="small" variant="outlined" label="N/A" />;
      }
      return (
        <ClassificationChip
          classification={assessment.spec.assessment.classification}
        />
      );
    },
  };
}

interface MakeColumnsProps {
  scorecard: IEntityScorecard;
  assessments: IEntityScorecardAssessment[];
  showClassification?: boolean;
}

export const makeColumns = ({ scorecard, assessments }: MakeColumnsProps) => [
  ...applicationColumns('asc', ['prr', 'star', 'scorecards']),
  makeAssessmentColumn(scorecard, assessments),
  makeClassificationColumn(assessments),
];
