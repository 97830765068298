import React, { type PropsWithChildren, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmationDialogProps {
  title?: string;
  open: boolean;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  confirmButtonText?: string;
}

export interface UseConfirmationReturnType {
  open: VoidFunction;
  close: VoidFunction;
  isOpen: boolean;
  ConfirmationDialog: React.FC<
    PropsWithChildren<Omit<ConfirmationDialogProps, 'open' | 'onClose'>>
  >;
}

function ConfirmationDialog({
  title = 'Confirmation',
  open,
  onClose,
  onConfirm,
  children,
  confirmButtonText = 'Save',
}: PropsWithChildren<ConfirmationDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle
        component="h5"
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>{children}</DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function useConfirmationDialog(): UseConfirmationReturnType {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const ConfirmationDialogComponent = useCallback(
    ({
      children,
      title,
      onConfirm,
      confirmButtonText,
    }: PropsWithChildren<
      Omit<ConfirmationDialogProps, 'open' | 'onClose'>
    >) => {
      return (
        <ConfirmationDialog
          title={title}
          open={isOpen}
          onClose={close}
          confirmButtonText={confirmButtonText}
          onConfirm={() => {
            onConfirm();
          }}
        >
          {children}
        </ConfirmationDialog>
      );
    },
    [isOpen, close],
  );

  return {
    open: open,
    close: close,
    isOpen: isOpen,
    ConfirmationDialog: ConfirmationDialogComponent,
  };
}
