import React, { useRef, useState } from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import DataObjectIcon from '@mui/icons-material/DataObject';
import ListItemIcon from '@mui/material/ListItemIcon';
import type { Table } from '@tanstack/react-table';
import type { ExportDataType, ExportedData, TableProps } from '../types';
import { downloadCsv, downloadJson, downloadTsv } from './utils';
import CircularProgress from '@mui/material/CircularProgress';
import { FileIcon } from './FileIcon';

interface Props<T> {
  table: Table<T>;
  onExport?: TableProps<T>['onExport'];
}

export function ExportButton<T>({ onExport, table }: Props<T>) {
  const exportRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<ExportDataType>();

  function exportData(type: ExportDataType) {
    if (!onExport) return;
    const rawData = table.getFilteredRowModel().rows.map(r => r.original);
    const response = onExport(rawData);

    function downloadExportedData(
      exportedData: ExportedData,
      exportType: ExportDataType,
    ) {
      switch (exportType) {
        case 'json':
          downloadJson(exportedData);
          break;
        case 'tsv':
          downloadTsv(exportedData);
          break;
        case 'csv':
          downloadCsv(exportedData);
          break;
        default:
          return;
      }
    }

    if (response instanceof Promise) {
      setLoading(type);
      response
        .then(data => downloadExportedData(data, type))
        .finally(() => setLoading(undefined));
    } else {
      downloadExportedData(response, type);
    }
  }

  const loadingContent = (
    <CircularProgress size="small" sx={{ width: 20, height: 20 }} />
  );

  return (
    <div>
      <Button
        size="small"
        ref={exportRef}
        onClick={() => setOpen(true)}
        startIcon={<GetAppIcon fontSize="small" />}
      >
        Export
      </Button>
      <Menu
        anchorEl={exportRef.current}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem dense onClick={() => exportData('json')} disabled={!!loading}>
          <ListItemIcon>
            {loading === 'json' ? (
              loadingContent
            ) : (
              <DataObjectIcon color="primary" fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>JSON</ListItemText>
        </MenuItem>

        <MenuItem dense onClick={() => exportData('tsv')} disabled={!!loading}>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            {loading === 'tsv' ? loadingContent : <FileIcon extension="TSV" />}
          </ListItemIcon>
          <ListItemText>TSV</ListItemText>
        </MenuItem>

        <MenuItem dense onClick={() => exportData('csv')} disabled={!!loading}>
          <ListItemIcon sx={{ color: 'primary.main' }}>
            {loading === 'csv' ? loadingContent : <FileIcon extension="CSV" />}
          </ListItemIcon>
          <ListItemText>CSV</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
