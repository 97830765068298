import React, { useMemo } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { alpha, useTheme } from '@mui/material/styles';
import type { Table } from '@tanstack/react-table';
import {
  ColumnFilter,
  TableFilterOption,
  TableGroupFilter,
} from '../types/filtering';

interface Props<T> {
  table: Table<T>;
  item: TableGroupFilter<T>;
  selectedGroup: ColumnFilter | undefined;
}

export function GroupFilterItem<T>({ table, item, selectedGroup }: Props<T>) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const borderColor = isDark ? alpha('#000', 0.5) : theme.palette.divider;
  const hoverColor = isDark ? 'rgb(97, 97, 97)' : 'rgb(238, 238, 238)';
  const selectedColor = isDark ? 'rgb(97, 97, 97)' : 'rgb(238, 238, 238)';
  const selectedHoverColver = isDark ? 'rgb(66, 66, 66)' : 'rgb(224, 224, 224)';

  const tableState = table.getState();
  const count = useMemo(() => {
    let filteredData = table.getCoreRowModel().flatRows;
    // Apply column filters
    for (const columnFilter of table.getState().columnFilters) {
      const column = table.getColumn(columnFilter.id);
      if (!column) continue;
      const filterFn = column.getFilterFn();

      filteredData = filteredData.filter(row => {
        if (columnFilter.id === 'group') {
          const filterValue: TableFilterOption[] = [
            { label: '', value: item.id },
          ];
          return filterFn?.(row, column.id, filterValue, () => {}) ?? true;
        }
        const filterValue = column.getFilterValue() as TableFilterOption[];
        return filterFn?.(row, column.id, filterValue, () => {}) ?? true;
      });
    }
    // Apply global filter
    const searchFn = table.getGlobalFilterFn();
    if (tableState.globalFilter && searchFn) {
      filteredData = filteredData.filter(row => {
        return table.getAllColumns().some(column => {
          if (!column.getCanGlobalFilter()) return false;
          return searchFn(row, column.id, tableState.globalFilter, () => {});
        });
      });
    }
    return filteredData.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  return (
    <ListItemButton
      key={item.id}
      disableRipple
      selected={selectedGroup?.value.some(v => v.value === item.id)}
      sx={{
        gap: 1,
        backgroundColor: 'background.paper',
        transition: 'background-color 200ms',
        ':hover': {
          backgroundColor: hoverColor,
        },
        ':active': {
          backgroundColor: selectedColor,
        },
        borderBottom: `1px solid ${borderColor}`,
        '&.Mui-selected': {
          backgroundColor: selectedColor,
          ':hover': {
            backgroundColor: selectedHoverColver,
          },
        },
      }}
      onClick={() => {
        table.setColumnFilters(prev => {
          return prev.map(f => {
            if (f.id === 'group') {
              return {
                id: 'group',
                value: [{ label: item.label, value: item.id }],
              };
            }
            return f;
          });
        });
      }}
    >
      {item.icon && (
        <ListItemIcon sx={{ minWidth: 'fit-content', color: 'inherit' }}>
          <item.icon fontSize="small" />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
          whiteSpace: 'nowrap',
          fontWeight: 500,
          '& > *': {
            fontWeight: 500,
          },
        }}
        primary={item.label}
        secondary={count}
      />
    </ListItemButton>
  );
}
