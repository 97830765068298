import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useUser } from './useUser';

export const useIsAdmin = () => {
  const config = useApi(configApiRef);
  const [isAdmin, setIsAdmin] = React.useState<boolean | undefined>(undefined);
  const user = useUser();

  const admins = config.getStringArray('features.admins');

  React.useEffect(() => {
    if (user.value) {
      const name = user.value.metadata.name;
      if (admins.includes(name)) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isAdmin;
};
