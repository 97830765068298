export default `
query ApplicationResources(
  $application_id: String, 
  $limit: Int, 
  $offset: Int, 
  $include: [String!]
) {
  models(
    application_id: $application_id,
    limit: $limit,
    offset: $offset,
    include: $include
  ) {
    items {
      ... on ModelSchema {
        name
        model_id
        description
        meta
        slug
        updated_at
        license_link
        license_type
        application_id
        created_at
        created_by {
          realm
          uid
        }
      }
      
    }

  }
  pipelines(
    application_id: $application_id,
    limit: $limit,
    offset: $offset,
    include: $include
  ) {
    items {
      ... on PipelineSchema {
        application_id
        archived
        created_at
        slug
        created_by {
          realm
          uid
        }
        deployment_environment {
          account_id
          deployment_environment_id
          name
          production_status
          type
        }
        full_name
        last_run {
          created_at
          created_by {
            realm
            uid
          }
          meta
          full_name
          name
          pipeline_id
          resource_id
          run_id
          status
          steps {
            created_at
            created_by {
              realm
              uid
            }
            details
            full_name
            meta
            metrics {
              confidence_interval
              is_average
              key
              meta
              model_version_id
              metric_id
              run_id
              slice
              step_id
              threshold
              value
            }
            name
            next_step_ids
            params {
              model_version_id
              key
              param_id
              run_id
              step_id
              value
            }
            resource_id
            run_id
            status
            step_id
            step_type
            updated_at
            updated_by {
              realm
              uid
            }
          }
          trigger_source
          updated_at
          updated_by {
            realm
            uid
          }
        }
        name
        pipeline_id
        pipeline_type
      }
    }
  }
  ai_systems(
    application_id: $application_id,
    limit: $limit,
    offset: $offset,
    include: $include
  ) {
    items {
      ... on AISystemSchema {
        name
        team_id
        description
        ai_system_id
        category
        input_data
        output_data
        purpose
        zalando_role
        impact
        documentation
        slug
        meta
        created_at
        created_by {
          uid
          realm
        }
        updated_at
        updated_by {
          uid
          realm
        }
        applications
        custom_values
      }
    }
  }
}`;
