import React from 'react';
import Tooltip from '@mui/material/Tooltip';

export const TeamReferenceError = ({
  error,
  team,
  fallback,
}: {
  error: Error | undefined;
  team: string;
  fallback?: string;
}) => {
  const displayTeam = team || 'Unknown';
  const tooltipTeam = fallback ? `${fallback} (${displayTeam})` : displayTeam;

  const title = error
    ? `An error occurred when fetching Team data: ${
        error?.message || String(error) || 'Unknown error'
      }`
    : `Team ${tooltipTeam} was not found in Sunrise`;
  return (
    <Tooltip title={title} placement="top" arrow>
      <span>{fallback || displayTeam}</span>
    </Tooltip>
  );
};
