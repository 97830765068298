import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { HttpClient } from '../httpClient';
import { ApplicationResources } from '../interfaces/meta';
import applicationResourceQuery from '../graphql/application_resources';
import { InputError } from '@backstage/errors';

export interface ApplicationResourceApi {
  getApplicationResources(applicationId: string): Promise<ApplicationResources>;
}

export class ApplicationResourceService implements ApplicationResourceApi {
  readonly httpClient: HttpClient;

  constructor(options: { oauth2Api: OAuthApi; discoveryApi: DiscoveryApi }) {
    this.httpClient = new HttpClient({
      pluginProxyEndpoint: 'ml',
      ...options,
    });
  }

  async getApplicationResources(
    applicationId: string,
  ): Promise<ApplicationResources> {
    const variables = { application_id: applicationId };
    return this.httpClient
      .post('/graphql', {
        operationName: 'ApplicationResources',
        query: applicationResourceQuery,
        variables,
      })
      .then(response => {
        if (response.data && !response.data) {
          throw new InputError(
            response.errors[0]?.message ||
              'There was a problem with fetching the application data information.',
          );
        }
        return {
          models: response.data.models.items,
          pipelines: response.data.pipelines.items,
          ai_systems: response.data.ai_systems.items,
        } as ApplicationResources;
      });
  }
}

export const applicationResourceApiRef = createApiRef<ApplicationResourceApi>({
  id: 'ml-platform-application-resource-api',
});
