import type { ExpandedState, Table } from '@tanstack/react-table';
import type { TableFilteringVariants } from '../types/filtering';

export function isPrimitive(value: any) {
  return ['string', 'number', 'boolean'].includes(typeof value);
}

export function getExpandedState<T>(table: Table<T>, depth = 0): ExpandedState {
  return (
    Object.keys(table.getExpandedRowModel().rowsById)
      // Filter by the number of separators '.' compared to depth starting with 0
      .filter(o => o.split('.').length < depth + 1)
      // Transform into an object with each option set to true
      .reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {} as Record<string, boolean>,
      )
  );
}

type FilterType = TableFilteringVariants['filterType'];
export function getFormat(type: FilterType) {
  switch (type) {
    case 'date':
    case 'date-range':
      return 'dd.MM.yyyy';
    case 'date-time':
    case 'date-time-range':
      return 'dd.MM.yyyy HH:mm';
    case 'month':
    case 'month-range':
      return 'MMM.yyyy';
    case 'time':
    case 'time-range':
      return 'HH:mm';
    default:
      return 'dd.MM.yyyy';
  }
}
