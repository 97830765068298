import type { UseTableStateProps } from '../hooks/useTableState';
import type { TableState } from '../types';
import type { SortingState } from '@tanstack/react-table';
import type { TableFilterOption } from '../types/filtering';
import { DateFilter } from '../Filtering/DateFilter';
import { DateTimeRangeFilter } from '../Filtering/DateTimeRangeFilter';
import { SelectFilter } from '../Filtering/SelectFilter';
import { DEFAULT_PAGE_SIZE } from '../constants';

export function getDefaultTableState<T>({
  columns,
  pagination,
  enableSearchParamsSync,
}: UseTableStateProps<T>): TableState {
  const searchParams = new URLSearchParams(window.location.search);
  const columnFiltersState: TableState['columnFilters'] = [];
  const sorting: SortingState = [];

  columns.forEach(column => {
    const columnId = column.id;

    if (!columnId) return;
    // Sorting
    if (column.defaultSort && !sorting.length) {
      sorting.push({ id: columnId, desc: column.defaultSort === 'desc' });
    }

    // Column filtering
    const filtering = column.filtering;
    if (!filtering) return;

    let selection: TableFilterOption[];
    switch (filtering.filterType) {
      case 'date':
      case 'date-time':
      case 'month':
      case 'time':
        selection = DateFilter.getInitialSelection(
          column,
          enableSearchParamsSync,
        );
        break;
      case 'date-range':
      case 'date-time-range':
      case 'month-range':
      case 'time-range':
        selection = DateTimeRangeFilter.getInitialSelection(
          column,
          enableSearchParamsSync,
        );
        break;
      default:
        selection = SelectFilter.getInitialSelection(
          column,
          enableSearchParamsSync,
        );
    }

    // Set the initial selection, if any
    if (selection.length) {
      columnFiltersState.push({ id: columnId, value: selection });
    }
  });

  return {
    globalFilter: enableSearchParamsSync
      ? searchParams.get('search') ?? ''
      : '',
    columnFilters: columnFiltersState,
    expanded: {},
    sorting,
    pagination: {
      pageIndex: 0,
      pageSize:
        (typeof pagination === 'object' && pagination.initialRowsPerPage) ||
        DEFAULT_PAGE_SIZE,
    },
  };
}
