import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mapFilterKeys } from '../context/utils';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function useFilterSetters(refs: ReturnType<typeof useFilterRefs>) {
  const [_, setSearchParams] = useSearchParams();
  const setters = {} as {
    [K in keyof typeof refs as `set${Capitalize<string & K>}`]: (
      value: any,
    ) => void;
  };

  Object.keys(refs).forEach(key => {
    const setterName = `set${capitalizeFirstLetter(
      key,
    )}` as keyof typeof setters;
    const filterKey = key.split('Filter')[0];
    setters[setterName] = (value: any) => {
      refs[key as keyof typeof refs].current = value;
      if (!value.length || !value) {
        setSearchParams(currentVal => {
          currentVal.delete(mapFilterKeys(filterKey));
          return currentVal;
        });
      } else {
        setSearchParams(currentVal => {
          currentVal.set(
            mapFilterKeys(filterKey),
            Array.isArray(value) ? value.join(',') : value,
          );
          return currentVal;
        });
      }
    };
  });

  return setters;
}

/** Create initial refs for all filters */
export function useFilterRefs(tab: string) {
  const tagsFilter = useRef<string[]>([]);
  const statusFilter = useRef<ICatalog.FilterYesOrNo>(
    tab === 'service' ? 'yes' : '',
  );
  const docsCategoryFilter = useRef<string[]>([]);
  const criticalityFilter = useRef<string>('');
  const cyberweekRelevantFilter = useRef<ICatalog.FilterYesOrNo>('');
  const support247Filter = useRef<ICatalog.FilterYesOrNo>('');
  const teamsFilter = useRef<string[]>([]);
  const lifecycleFilter = useRef<string>('');
  const typeFilter = useRef<string>('');
  const costCenterFilter = useRef<string[]>([]);
  const idsAndAliasesFilter = useRef<(string | number)[]>([]);
  const reviewFilter = useRef<IAppReview.ReviewStatus[]>([]);
  const dataClassificationFilter = useRef<IAppReview.DataClassification[]>([]);
  const apecFilter = useRef<ApecStatusType[]>([]);
  const cyberWeekPhaseFilter = useRef<string[]>([]);
  const cyberWeekToerFilter = useRef<string[]>([]);
  const ownerFilter = useRef<string[]>([]);
  const journeyFilter = useRef<string[]>([]);
  const stepFilter = useRef<string[]>([]);
  const toolingStatusFilter = useRef<string[]>([]);
  const scorecardsFilter = useRef<string[]>([]);
  const prrFilter = useRef<ICatalog.FilterYesOrNever>('');
  const securityTierFilter = useRef<number[]>([]);
  const techTeamsFilter = useRef<string>('');
  const scorecardStatusFilter = useRef<string[]>([]);
  const targetClassificationFilter = useRef<string[]>([]);

  return {
    tagsFilter,
    statusFilter,
    docsCategoryFilter,
    criticalityFilter,
    cyberweekRelevantFilter,
    support247Filter,
    teamsFilter,
    lifecycleFilter,
    typeFilter,
    costCenterFilter,
    idsAndAliasesFilter,
    reviewFilter,
    dataClassificationFilter,
    apecFilter,
    cyberWeekPhaseFilter,
    cyberWeekToerFilter,
    ownerFilter,
    journeyFilter,
    stepFilter,
    toolingStatusFilter,
    scorecardsFilter,
    prrFilter,
    securityTierFilter,
    techTeamsFilter,
    scorecardStatusFilter,
    targetClassificationFilter,
  };
}
