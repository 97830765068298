import React, { FormEvent, useMemo, useState } from 'react';
import { Table, type TableColumn, useIsAdmin } from 'plugin-ui-components';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/lab/Alert';
import { useApi } from '@backstage/core-plugin-api';
import { techInsightsApiRef } from '../../../api';
import CircularProgress from '@mui/material/CircularProgress';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InfoIcon from '@mui/icons-material/Info';

interface Props {
  scorecard?: TechInsights.Scorecard;
  retry: VoidFunction;
}

type IdentifiableCondition = TechInsights.TargetCondition & { id: Symbol };
const conditionForm: IdentifiableCondition = Object.freeze({
  id: Symbol(),
  value: '%%FIELD%%',
  property: '%%FIELD%%',
  operator: '%%FIELD%%',
});

export function ScorecardTargetsForm({ scorecard, retry }: Props) {
  const techInsightsApi = useApi(techInsightsApiRef);
  const [open, setOpen] = useState(false);
  const [entity, setEntity] = useState('');
  const [logicalOperator, setLogicalOperator] = useState<string>('');
  const [classification, setClassification] = useState<string>('');
  const [conditions, setConditions] = useState<IdentifiableCondition[]>([]);
  const [showConditionFields, setShowConditionFields] = useState(true);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState<string>('');
  const [operator, setOperator] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const isAdmin = useIsAdmin();

  const columns = useMemo(
    (): TableColumn<TechInsights.Target>[] => [
      {
        field: 'entity',
        children: 'Entity',
      },
      {
        field: 'logical_operator',
        children: 'Logical operator',
        render: target => {
          const text = target.logical_operator || 'N/A';
          if ((text as any) === 'N/A') {
            return (
              <Tooltip title="If the logical operator isn't defined, it will be considered as AND by default">
                <Box
                  component="span"
                  sx={{
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                    cursor: 'help',
                  }}
                >
                  {text}
                </Box>
              </Tooltip>
            );
          }
          return target.logical_operator;
        },
      },
      {
        field: 'classification',
        children: 'Classification',
        render: target => {
          const text = target.classification || 'N/A';
          if ((text as any) === 'N/A') {
            return (
              <Box
                component="span"
                sx={{
                  textDecoration: 'underline',
                  textDecorationStyle: 'dotted',
                  cursor: 'help',
                }}
              >
                {text}
              </Box>
            );
          }
          return target.classification;
        },
      },
      {
        field: 'conditions',
        children: 'Conditions',
        render: target => (
          <Box
            component="ul"
            paddingInlineStart="1rem"
            margin={0}
            display="flex"
            flexDirection="column"
            gap="0.25rem"
          >
            {target.conditions.map((condition, i) => {
              let text = condition.property;
              if (condition.operator === 'VALUE_EXISTS') {
                text += ' exists';
              } else {
                text += ` ${condition.operator} "${condition.value}"`;
              }
              return (
                <Box
                  component="li"
                  key={i}
                  display="flex"
                  gap="0.25rem"
                  alignItems="center"
                >
                  <span>{condition.property}</span>
                  <Chip
                    size="small"
                    color="info"
                    variant="outlined"
                    label={
                      condition.operator === 'VALUE_EXISTS'
                        ? 'exists'
                        : condition.operator
                    }
                  />
                  {condition.operator !== 'VALUE_EXISTS' && (
                    <span>{condition.value}</span>
                  )}
                </Box>
              );
            })}
          </Box>
        ),
      },
      {
        field: '',
        children: '',
        hidden: !isAdmin,
        render: target => {
          return (
            <>
              <IconButton
                type="button"
                size="small"
                onClick={() => removeTarget(target.id)}
              >
                <RemoveCircleIcon color="error" />
              </IconButton>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scorecard, isAdmin],
  );

  function createTarget(ev: FormEvent) {
    ev.preventDefault();
    if (!scorecard) return;

    if (!conditions.length) {
      setError('At least one condition must be defined');
      return;
    }

    const target: TechInsights.CreateTargetDto = {
      scorecard_id: scorecard.id,
      entity,
      logical_operator: logicalOperator as any,
      ...(classification
        ? {
            classification: classification as TechInsights.ClassificationValues,
          }
        : {}),
      conditions: conditions.map(({ id: _, ...c }) => c),
    };

    setLoading(true);
    techInsightsApi
      .createScorecardTarget(target)
      .then(() => {
        retry();
        setOpen(false);
        setEntity('');
        setLogicalOperator('');
        setConditions([]);
      })
      .catch(err => setError(err.message ?? 'Something went wrong'))
      .finally(() => setLoading(false));
  }

  function removeTarget(id: number) {
    setLoading(true);
    techInsightsApi
      .deleteScorecardTarget(id)
      .then(retry)
      .catch(err => setError(err.message ?? 'Something went wrong'))
      .finally(() => setLoading(false));
  }

  function addCondition() {
    const condition: IdentifiableCondition = {
      id: Symbol(),
      value,
      property,
      operator,
    };

    setConditions([...conditions, condition]);
    setShowConditionFields(false);
    // Reset form fields
    setProperty('');
    setOperator('');
    setValue('');
  }

  function removeCondition(id: Symbol) {
    setConditions(conditions.filter(i => i.id !== id));
  }

  if (!scorecard) return null;
  return (
    <Card>
      <CardHeader title="Scorecard targets" />
      <Table
        options={{
          emptyNode: 'No targets are defined for this scorecard',
          showSearch: false,
        }}
        size="small"
        header={
          isAdmin && (
            <Button
              variant="text"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => setOpen(true)}
              disabled={loading}
            >
              Add target
            </Button>
          )
        }
        data={scorecard.targets ?? []}
        columns={columns}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xl">
        <DialogTitle>Add a scorecard target</DialogTitle>
        <DialogContent style={{ width: '700px' }}>
          <form onSubmit={createTarget} style={{ paddingTop: '0.5rem' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="entity" sx={{ fontSize: '.9rem' }}>
                    Entity type
                  </InputLabel>
                  <Select
                    value={entity}
                    onChange={ev => setEntity(ev.target.value)}
                    fullWidth
                    required
                    name="entity"
                    label="Entity type"
                    labelId="entity"
                  >
                    <MenuItem value="component">Component</MenuItem>
                    <MenuItem value="documentation">Documentation</MenuItem>
                    <MenuItem value="group">Group</MenuItem>
                  </Select>
                  <FormHelperText>
                    The type of entity this target is for
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="logical_operator" sx={{ fontSize: '.9rem' }}>
                    Logical operator
                  </InputLabel>
                  <Select
                    value={logicalOperator}
                    onChange={ev => setLogicalOperator(ev.target.value)}
                    fullWidth
                    name="logical_operator"
                    label="Logical operator (Optional)"
                    labelId="logical_operator"
                  >
                    <MenuItem value="">
                      <i>None</i>
                    </MenuItem>
                    <MenuItem value="AND">AND</MenuItem>
                    <MenuItem value="OR">OR</MenuItem>
                  </Select>
                  <FormHelperText>
                    The logical operator to use when combining conditions
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="logical_operator" sx={{ fontSize: '.9rem' }}>
                    Classification
                  </InputLabel>
                  <Select
                    value={classification}
                    onChange={ev => setClassification(ev.target.value)}
                    fullWidth
                    name="logical_operator"
                    label="Logical operator (Optional)"
                    labelId="logical_operator"
                    defaultValue={classification}
                  >
                    <MenuItem value="">
                      <i>None</i>
                    </MenuItem>
                    <MenuItem value="MUST">MUST</MenuItem>
                    <MenuItem value="SHOULD">SHOULD</MenuItem>
                    <MenuItem value="COULD">COULD</MenuItem>
                  </Select>
                  <FormHelperText>
                    The classification of the applied targets
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Box>
              <Table<IdentifiableCondition>
                header={
                  <Box marginInlineStart="-1rem">
                    <Box display="flex" alignItems="center">
                      <Typography>Conditions</Typography>
                      <Tooltip title="Add a condition">
                        <IconButton
                          type="button"
                          size="small"
                          onClick={() => setShowConditionFields(true)}
                        >
                          <AddIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <FormHelperText>
                      The conditions used to match the target entities
                    </FormHelperText>
                  </Box>
                }
                options={{
                  showSearch: false,
                  emptyNode: 'Click the + button to add conditions',
                }}
                size="small"
                data={
                  showConditionFields
                    ? [...conditions, conditionForm]
                    : conditions
                }
                columns={[
                  {
                    field: 'property',
                    children: (
                      <Box display="flex" gap="0.25rem" alignItems="center">
                        Property
                        <Tooltip title="The property to match against">
                          <InfoIcon style={{ fontSize: '1.1em' }} />
                        </Tooltip>
                      </Box>
                    ),
                    render: row => {
                      if (row.value === '%%FIELD%%') {
                        return (
                          <TextField
                            fullWidth
                            name="property"
                            variant="outlined"
                            placeholder="Property"
                            size="small"
                            required
                            value={property}
                            onChange={ev => setProperty(ev.target.value)}
                          />
                        );
                      }
                      return row.property;
                    },
                  },
                  {
                    field: 'operator',
                    children: (
                      <Box display="flex" gap="0.25rem" alignItems="center">
                        Operator
                        <Tooltip title="The operator to use when comparing the property to the value">
                          <InfoIcon style={{ fontSize: '1.1em' }} />
                        </Tooltip>
                      </Box>
                    ),
                    render: row => {
                      if (row.value === '%%FIELD%%') {
                        return (
                          <Select
                            fullWidth
                            size="small"
                            name="operator"
                            placeholder="operator"
                            defaultValue=""
                            value={operator}
                            onChange={ev => {
                              setOperator(ev.target.value);
                              if (ev.target.value === 'VALUE_EXISTS') {
                                setValue('VALUE_EXISTS');
                              } else if (value === 'VALUE_EXISTS') {
                                setValue('');
                              }
                            }}
                            renderValue={v =>
                              v === 'VALUE_EXISTS' ? 'Exists' : v
                            }
                            required
                          >
                            <MenuItem value="=">Equals</MenuItem>
                            <MenuItem value=">">Greater than</MenuItem>
                            <MenuItem value="<">Less than</MenuItem>
                            <MenuItem value=">=">
                              Greater than or equal to
                            </MenuItem>
                            <MenuItem value="<=">
                              Less than or equal to
                            </MenuItem>
                            <MenuItem value="VALUE_EXISTS">
                              Value exists
                            </MenuItem>
                          </Select>
                        );
                      } else if (row.operator === 'VALUE_EXISTS') {
                        return 'Exists';
                      }
                      return row.operator;
                    },
                  },
                  {
                    field: 'value',
                    children: (
                      <Box display="flex" gap="0.25rem" alignItems="center">
                        Value
                        <Tooltip title="The value to compare the property against">
                          <InfoIcon style={{ fontSize: '1.1em' }} />
                        </Tooltip>
                      </Box>
                    ),
                    hidden: operator === 'VALUE_EXISTS',
                    render: row => {
                      if (row.value === '%%FIELD%%') {
                        return (
                          <TextField
                            fullWidth
                            name="value"
                            variant="outlined"
                            placeholder="Value"
                            size="small"
                            required
                            value={value}
                            onChange={ev => setValue(ev.target.value)}
                          />
                        );
                      } else if (row.value === 'VALUE_EXISTS') {
                        return '';
                      }
                      return row.value;
                    },
                  },
                  {
                    field: '',
                    children: '',
                    render: row => {
                      if (row.value === '%%FIELD%%') {
                        return (
                          <>
                            <Tooltip
                              title="Cancel"
                              onClick={() => {
                                setShowConditionFields(false);
                                setProperty('');
                                setOperator('');
                                setValue('');
                              }}
                            >
                              <IconButton type="button" size="small">
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save condition">
                              <IconButton
                                type="button"
                                size="small"
                                onClick={addCondition}
                              >
                                <SaveIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          </>
                        );
                      }
                      return (
                        <Tooltip
                          title="Remove condition"
                          onClick={() => removeCondition(row.id)}
                        >
                          <IconButton type="button" size="small">
                            <RemoveCircleIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      );
                    },
                  },
                ]}
              />
              {error && (
                <Alert severity="error" onClose={() => setError(undefined)}>
                  {error}
                </Alert>
              )}
            </Box>

            <Box display="flex" gap="1rem" marginBlock="2rem 1rem">
              <Button
                type="reset"
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                  setLogicalOperator('');
                  setClassification('');
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Box flex={1} />
              <Button type="submit" variant="contained" disabled={loading}>
                {loading ? <CircularProgress size="small" /> : 'Save'}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Card>
  );
}
