import { Link, TableColumn } from '@backstage/core-components';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { collectToer, entitySort, isCyberWeekRelatedTag } from 'plugin-core';
import { ScorecardChip } from 'plugin-scorecards';
import React from 'react';
import { ReviewChip } from '../../../ReviewChip';
import { HStack } from '../../../Stacks';
import { ToerChip } from '../../../ToerChip';
import { EventTracker } from '../../../Tracking';
import { CriticalityChip } from '../../CriticalityChip';
import {
  clickDataClassification,
  DataClassificationChip,
} from '../../DataClassificationChip';
import {
  createActionsColumn,
  createHiddenSearchableColumn,
  createMetadataDescriptionColumn,
  createNameDescriptionColumn,
  createOwnerColumn,
} from './shared';

type ApplicationColumnNames =
  | 'name'
  | 'owner'
  | 'criticality'
  | 'description'
  | 'tags'
  | 'review'
  | 'apec'
  | 'prr'
  | 'star'
  | 'scorecards';

interface ScorecardColumnConfig {
  scorecardsList: IEntityScorecard[];
}

export function createCriticalityColumn(): TableColumn<
  ICatalog.EntityRow<IEntityApp>
> {
  return {
    title: 'Criticality',
    field: 'entity.spec.lifecycle',
    render: ({ entity }) => {
      const classification = entity.metadata?.lastReview?.dataClassification;
      const {
        kind = 'Component',
        metadata: { namespace = 'default', name },
      } = entity;
      return (
        <div className="flex-wrapped">
          {entity.spec?.lifecycle && (
            <Chip
              className="info-chip"
              label={entity.spec.lifecycle}
              size="small"
              variant="outlined"
            />
          )}
          {entity.spec?.spec?.incident_contact && (
            <Tooltip title="Has 24/7 support">
              <Chip
                className="info-chip"
                label="24/7"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
          {classification && (
            <EventTracker {...clickDataClassification()}>
              <Link
                to={`/catalog/${namespace}/${kind}/${name}/review`}
                color="textPrimary"
              >
                <DataClassificationChip entity={entity} clickable />
              </Link>
            </EventTracker>
          )}
          {entity.spec?.spec?.security_tier && (
            <Chip
              className="info-chip"
              label={`Security Tier ${entity.spec.spec.security_tier}`}
              size="small"
              variant="outlined"
            />
          )}
        </div>
      );
    },
  };
}

export function createTagsColumn(): TableColumn<
  ICatalog.EntityRow<IEntityApp>
> {
  return {
    title: 'Cyber Week',
    field: 'entity.metadata.cyberweekInScope',
    render: ({ entity }) => {
      const toer = collectToer(entity);

      return (
        <div className="tag-wrapper">
          {entity.metadata.cyberweekInScope && <ToerChip toer={toer} />}
          {entity.metadata.cyberweekInScope && (
            <Tooltip title="Relevant for cyberweek">
              <Chip
                className="info-chip"
                label="relevant"
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
          {entity.metadata.tags &&
            entity.metadata.tags
              .filter(v => isCyberWeekRelatedTag(v))
              .map(t => (
                <Chip
                  className="info-chip"
                  key={t}
                  label={t}
                  size="small"
                  variant="outlined"
                />
              ))}
        </div>
      );
    },
  };
}

export function createReviewColumn(): TableColumn<
  ICatalog.EntityRow<IEntityApp>
> {
  return {
    title: 'Review status',
    field: 'resolved.reviewStatus',
    render: ({ entity }) => {
      if (entity.spec?.type && entity.spec?.type === 'service') {
        return <ReviewChip entity={entity} />;
      }
      return undefined;
    },
  };
}

export function createApecColumn(
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow<IEntityApp>> {
  return {
    title: (
      <Tooltip title="Application Production Engineering Checklist">
        <span>
          APEC <InfoOutlined className="header-info" />
        </span>
      </Tooltip>
    ),
    field: 'entity.metadata.apecStatus.status',
    customSort: (a, b) => {
      const metadata1 = a.entity.metadata;
      const metadata2 = b.entity.metadata;
      const s1 = metadata1.apecStatus?.status;
      const s2 = metadata2.apecStatus?.status;
      const [first, second] = order === 'asc' ? [s1, s2] : [s2, s1];
      switch (true) {
        case first === second:
          return 0;
        case first === '':
          return 1;
        case second === '':
          return -1;

        case first === 'red':
        case first === 'amber' && !['red'].includes(second):
        case first === 'green' && !['red', 'amber'].includes(second):
        case first === 'notrelevant' &&
          !['red', 'amber', 'green'].includes(second):
          return order === 'asc' ? -1 : 1;
        default:
          return order === 'asc' ? 1 : -1;
      }
    },
    render: ({ entity }) => {
      const metadata = entity.metadata;
      return (
        <div className="flex-wrapped">
          {metadata.apecStatus?.status && (
            <CriticalityChip
              size="small"
              variant="outlined"
              status={metadata.apecStatus.status}
              reasons={metadata.apecStatus.reasons.filter(
                value => !value.endsWith('marked as not applicable'),
              )}
            />
          )}
        </div>
      );
    },
  };
}

export function createPrrColumn(): TableColumn<ICatalog.EntityRow<IEntityApp>> {
  return {
    title: (
      <Tooltip title="Production Readiness Review Completion">
        <span>
          PRR <InfoOutlined className="header-info" />
        </span>
      </Tooltip>
    ),
    field: 'entity.metadata.productionReadinessReviewDate',
    customSort: (a, b) => {
      const metadata1 = a.entity.metadata;
      const metadata2 = b.entity.metadata;
      const prr1 = metadata1.productionReadinessReviewDate;
      const prr2 = metadata2.productionReadinessReviewDate;
      const d1 = new Date(metadata1.productionReadinessReviewDate);
      const d2 = new Date(metadata2.productionReadinessReviewDate);

      if (!isNaN(d2.getTime())) {
        if (isNaN(d1.getTime())) {
          return -1;
        }
        return Math.sign(d1.getTime() - d2.getTime());
      }

      if (prr2 && !prr1) {
        return -1;
      }

      return 0;
    },

    render: ({ entity }) => {
      return (
        <div className="flex-wrapped">
          {entity.metadata.productionReadinessReviewDate && (
            <Tooltip title="Production Readiness Review">
              <Chip
                className="info-chip"
                label={
                  entity.metadata.productionReadinessReviewDate === 'never'
                    ? entity.metadata.productionReadinessReviewDate
                    : new Date(
                        entity.metadata.productionReadinessReviewDate,
                      ).toISODateString('short')
                }
                size="small"
                variant="outlined"
              />
            </Tooltip>
          )}
        </div>
      );
    },
  };
}

export function createScorecardsColumn(
  scorecardColumn: ScorecardColumnConfig | undefined,
  order: 'asc' | 'desc' | '' = 'asc',
): TableColumn<ICatalog.EntityRow<IEntityApp>> {
  return {
    title: (
      <HStack alignItems="center" spacing=".2rem">
        <div>Scorecards</div>
      </HStack>
    ),
    render: ({ entity }) => {
      return (
        <ScorecardChip
          entity={entity}
          {...(scorecardColumn && {
            scorecardsToShow: scorecardColumn.scorecardsList,
          })}
        />
      );
    },
    sorting: true,
    customSort: (a, b) =>
      entitySort(a.entity, b.entity, 'spec.scorecardCompletion', order),
  };
}

export const applicationColumns = (
  order: 'asc' | 'desc' | '' = 'asc',
  skip: Array<ApplicationColumnNames> = [],
  scorecardsColumn?: ScorecardColumnConfig,
): TableColumn<ICatalog.EntityRow<IEntityApp>>[] => {
  const columnMap = {
    name: createNameDescriptionColumn,
    owner: createOwnerColumn,
    criticality: createCriticalityColumn,
    description: () => createMetadataDescriptionColumn(order),
    tags: createTagsColumn,
    scorecards: () => createScorecardsColumn(scorecardsColumn, order),
    review: createReviewColumn,
    apec: () => createApecColumn(order),
    prr: createPrrColumn,
    star: createActionsColumn,
  };

  // Skip columns that are not needed
  const columns = Object.entries(columnMap)
    .filter(([key]) => !skip.includes(key as ApplicationColumnNames))
    .map(([, createColumn]) => createColumn());

  const additionalColumns = [
    createHiddenSearchableColumn('entity.spec.spec.name'),
    createHiddenSearchableColumn('entity.spec.spec.subtitle'),
  ];

  return [...columns, ...additionalColumns];
};
