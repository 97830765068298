import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import styled from '@mui/styles/styled';
import Box from '@mui/material/Box';

export const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  marginBottom: theme.spacing(4),
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledCardHeader = styled(CardHeader)(props => ({
  padding: props.theme.spacing(2),
  '& > div': {
    marginRight: 8,
  },
  '& > *': {
    margin: 0,
  },
  '& h3': {
    marginBottom: 0,
  },
  '& svg': {
    verticalAlign: 'middle',
  },
}));

export const StyledCardContent = styled(Box)(
  (props: { theme: Theme; disabled?: boolean }) => ({
    opacity: props.disabled ? '0.6' : '',
    filter: props.disabled ? 'blur(2px)' : '',
  }),
);
