import { useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { cdpApiRef } from '../api';

type FileData = {
  requests: Promise<string>[];
  files: { url: string; name: string }[];
};

export function useDeploymentArtifacts(
  domain: string,
  org: string,
  repo: string,
  pipelineID: string,
  name: string,
) {
  const api = useApi(cdpApiRef);

  const {
    loading,
    error,
    value: files = [],
  } = useAsync(async () => {
    const data = await api.getDeploymentArtifacts(
      domain,
      org,
      repo,
      pipelineID,
      name,
    );

    const fileData = Object.entries(data?.files || {}).reduce(
      (acc, [key, value]) => {
        const file = {
          name: key.split('/').pop() ?? key,
          url: value,
        };

        const request = new Promise<string>(async (resolve, reject) => {
          try {
            const fileContent = await api.getDeploymentArtifact(value);
            return resolve(fileContent);
          } catch (e) {
            return reject(e);
          }
        });

        return {
          files: [...acc.files, file],
          requests: [...acc.requests, request],
        };
      },
      {
        files: [],
        requests: [],
      } as FileData,
    );

    const contents = await Promise.all(fileData.requests);

    if (contents.length !== fileData.requests.length) {
      throw new Error('failed to fetch content of all files');
    }

    return fileData.files.map((f, i) => ({ ...f, content: contents[i] }));
  }, []);

  return {
    loading,
    files,
    error,
  };
}
