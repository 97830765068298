import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SelectFilter } from './SelectFilter';
import type { Table } from '@tanstack/react-table';
import { DateTimeRangeFilter } from './DateTimeRangeFilter';
import 'rsuite/DateRangePicker/styles/index.css';
import { CustomProvider } from 'rsuite';
import { useTheme } from '@mui/material/styles';
import { DateFilter } from './DateFilter';
import type { TableColumn } from '../types';

interface Props<T> {
  table: Table<T>;
  loading?: boolean;
}

export function ColumnFilters<T>({ table, loading }: Props<T>) {
  const theme = useTheme();
  const headers = table.getHeaderGroups().flatMap(g => g.headers);

  if (headers.every(h => !h.column.getCanFilter())) return null;

  return (
    <CustomProvider theme={theme.palette.mode}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="button">Refine Results</Typography>
          <Button
            variant="text"
            size="small"
            disableRipple
            sx={{
              p: 0,
              ':hover': { background: 'transparent' },
              ':active': { color: 'primary.dark' },
            }}
            onClick={() =>
              table.setColumnFilters(prev => prev.filter(f => f.id === 'group'))
            }
            disabled={loading}
          >
            Clear
          </Button>
        </Box>
        {table.getHeaderGroups().map(headerGroup =>
          headerGroup.headers.map(header => {
            const { filtering } = header.column.columnDef as TableColumn<T>;

            if (
              !filtering ||
              !header.column.getCanFilter() ||
              (filtering.filterType === 'select' && !filtering.options)
            )
              return null;

            switch (filtering.filterType) {
              case 'date':
              case 'date-time':
              case 'month':
              case 'time':
                return (
                  <DateFilter
                    key={header.id}
                    type={filtering.filterType}
                    header={header}
                  />
                );
              case 'date-range':
              case 'date-time-range':
              case 'month-range':
              case 'time-range':
                return (
                  <DateTimeRangeFilter
                    key={header.id}
                    type={filtering.filterType}
                    header={header}
                    disabled={loading}
                  />
                );
              default:
                return (
                  <SelectFilter
                    key={header.id}
                    header={header}
                    disabled={loading}
                  />
                );
            }
          }),
        )}
      </Box>
    </CustomProvider>
  );
}
