import React from 'react';
import type { Table } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import type { TableProps } from './types';
import { Pagination } from './Pagination';

interface Props<T> {
  table: Table<T>;
  pagination?: TableProps<T>['pagination'];
  loading?: boolean;
}

export function TableFooter<T>({ table, pagination, loading }: Props<T>) {
  if (!pagination) return null;

  return (
    <Box sx={{ position: 'sticky', bottom: 0, zIndex: 1 }}>
      <Pagination table={table} pagination={pagination} loading={loading} />
    </Box>
  );
}
