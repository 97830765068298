import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';

interface DiffObject {
  key: string;
  oldValue: any;
  newValue: any;
}

export function getObjectDiff(a: any, b: any, skip?: string[]) {
  return reduce(
    a,
    function diff(result, value, key) {
      if (skip?.includes(key)) return result;
      if (isEqual(value, b[key])) return result;
      return result.concat({
        key,
        oldValue: value,
        newValue: b[key],
      });
    },
    [] as DiffObject[],
  );
}
