import React from 'react';
import { DateRangePicker } from 'rsuite';
import { flexRender, type Header } from '@tanstack/react-table';
import { useStyles } from '../styles';
import cn from 'classnames';
import type { DateRange } from 'rsuite/DateRangePicker';
import type {
  TableFilteringVariants,
  TableFilterOption,
} from '../types/filtering';
import type { TableColumn } from '../types';
import { getFormat } from '../utils/general.utils';
import FormHelperText from '@mui/material/FormHelperText';

type FilterType = TableFilteringVariants['filterType'];
interface Props<T> {
  type: FilterType;
  header: Header<T, unknown>;
  disabled?: boolean;
}

const defaultValue: TableFilterOption[] = [];

export function DateTimeRangeFilter<T>({ type, header, disabled }: Props<T>) {
  const styles = useStyles();
  const columnDef = header.column.columnDef as TableColumn<T>;
  const filtering = columnDef.filtering;

  if (
    !filtering ||
    !(
      (filtering && filtering.filterType === 'date-range') ||
      filtering.filterType === 'date-time-range' ||
      filtering.filterType === 'month-range' ||
      filtering.filterType === 'time-range'
    )
  ) {
    return null;
  }

  const renderedHeader =
    filtering.label ??
    flexRender(header.column.columnDef.header, header.getContext());

  const [filterValueOption] =
    (header.column.getFilterValue() as TableFilterOption<DateRange>[]) ??
    defaultValue;
  const filterValue = filterValueOption?.value;

  return (
    <fieldset
      className={cn(styles.rSuiteDatePicker, type, { active: !!filterValue })}
    >
      <legend>{renderedHeader}</legend>
      <DateRangePicker
        block
        format={getFormat(type)}
        placeholder=" "
        appearance="subtle"
        value={filterValue ?? []}
        menuClassName={styles.rSuiteDatePickerMenu}
        onChange={newValue => {
          if (!newValue) {
            header.column.setFilterValue(null);
          } else {
            header.column.setFilterValue([
              { value: newValue, label: type ?? 'select' },
            ] satisfies TableFilterOption<DateRange>[]);
          }
        }}
        shouldDisableDate={filtering.shouldDisableDate}
        showHeader={false}
        disabled={disabled}
        ranges={filtering.ranges ?? []}
      />
      {columnDef.filtering?.helperText && (
        <FormHelperText>{columnDef.filtering.helperText}</FormHelperText>
      )}
    </fieldset>
  );
}

DateTimeRangeFilter.getInitialSelection = (
  column: TableColumn<any>,
  enableSearchParamsSync?: boolean,
): TableFilterOption[] => {
  const columnId = column.id;
  const filtering = column.filtering;
  if (!filtering || !columnId) return [];
  const searchParams = new URLSearchParams(window.location.search);

  if (enableSearchParamsSync && searchParams.size) {
    switch (filtering.filterType) {
      case 'date-range':
      case 'date-time-range':
      case 'month-range':
      case 'time-range': {
        const value = searchParams.getAll(columnId);
        return value?.length === 2
          ? [{ value: [new Date(value[0]), new Date(value[1])], label: '' }]
          : [];
      }
      default:
        return [];
    }
  }

  const { initialSelection } = filtering;
  return initialSelection?.length ? initialSelection : [];
};
