export enum RunStatusEnum {
  Initialized = 'INITIALIZED',
  Running = 'RUNNING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}

export const RunStatus = [
  { value: RunStatusEnum.Initialized, label: 'Initialized' },
  { value: RunStatusEnum.Running, label: 'Running' },
  { value: RunStatusEnum.Failed, label: 'Failed' },
  { value: RunStatusEnum.Succeeded, label: 'Succeeded' },
] as const;

export enum NotebookTypeEnum {
  SharedNotebook = 'shared_notebook',
  ScheduledNotebook = 'scheduled_notebook',
}
