import type { ParentFinder, TreeNode } from '../types';
import type { ExpandedState, Updater } from '@tanstack/react-table';

export function buildTree<T>(
  items: T[],
  isParent: ParentFinder<T>,
): TreeNode<T>[] {
  const rootNodes: TreeNode<T>[] = [];
  const itemMap = new Map<T, TreeNode<T>>();

  // Convert items to TreeNode with subRows always as an empty array
  items.forEach(item =>
    itemMap.set(item, { ...item, __subRows: [], __depth: -1 }),
  );

  function assignDepth(node: TreeNode<T>, depth: number) {
    node.__depth = depth;
    node.__subRows.forEach(child => assignDepth(child, depth + 1));
  }

  // Iterate over each item and place it correctly in the tree
  items.forEach(item => {
    const node = itemMap.get(item)!;
    let isRoot = true;

    for (const potentialParent of items) {
      if (isParent(potentialParent, item)) {
        isRoot = false;
        const parentNode = itemMap.get(potentialParent)!;
        parentNode.__subRows.push(node);
        break;
      }
    }

    if (isRoot) {
      rootNodes.push(node);
    }
  });

  // Assign depth starting from root level (0)
  rootNodes.forEach(root => assignDepth(root, 0));

  return rootNodes;
}

export function handleNestedExpansion(
  newState: Updater<ExpandedState>,
  prevState: ExpandedState,
): ExpandedState {
  if (newState === true) return {};

  const newExpanded = (
    typeof newState === 'function' ? newState(prevState) : { ...newState }
  ) as Record<string, boolean>;

  // Remove expanded nested children if parent doesn't exist (e.g. 0.0 is removed if 0 doesn't exist)
  const newExpandedKeys = Object.keys(newExpanded);
  const existingKeys = new Set(newExpandedKeys);
  newExpandedKeys.forEach(key => {
    const keyParts = key.split('.');
    for (let i = 1; i < keyParts.length; i++) {
      const parentKey = keyParts.slice(0, i).join('.');
      if (!existingKeys.has(parentKey)) {
        delete newExpanded[key];
        break;
      }
    }
  });

  return newExpanded;
}
