import React from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { FeatureFlagRibbon, InfoCard } from 'plugin-ui-components';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { useAnalysisReport, useUserEntity } from '../../hooks';
import {
  AnalysisGrade,
  AnalysisItem,
  CardBottom,
  LastAnalysis,
  NoAnalysisCard,
} from './components';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';

export function CodacyAnalysisReport() {
  const catalogApi = useApi(catalogApiRef);
  const { entity } = useEntity<IEntityApp>();
  const { report, loading } = useAnalysisReport({ entity });
  const { userEntity } = useUserEntity({ catalogApi, report });

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CardContent>
          <CircularProgress />
        </CardContent>
      </Box>
    );
  }

  if (
    // temp fix: if any information is missing, don't render the widget
    !report ||
    !report?.grade ||
    !report?.issuesPercentage ||
    !report?.complexFilesPercentage ||
    !report?.duplicationPercentage ||
    !report?.coverage?.coveragePercentage
  ) {
    return (
      <FeatureFlagRibbon name="code-quality">
        <NoAnalysisCard />
      </FeatureFlagRibbon>
    );
  }

  return (
    <FeatureFlagRibbon name="code-quality">
      <InfoCard
        id="code-quality-widget"
        title={
          <Tooltip
            title="Code quality analysis from Codacy"
            content="Code Quality"
          >
            <span>
              Code Quality
              <InfoOutlined
                style={{ fontSize: 18, cursor: 'pointer', marginLeft: 2 }}
              />
            </span>
          </Tooltip>
        }
        style={{ marginTop: 32 }}
      >
        <CardContent>
          <Grid container spacing={2} xs={10}>
            <AnalysisGrade report={report} />

            <AnalysisItem
              owner={report?.repository.owner}
              name={report?.repository.name}
              description="Percentage of complex files in the repository."
              title="Complexity"
              report={report?.complexFilesPercentage}
            />
            <AnalysisItem
              owner={report?.repository.owner}
              name={report?.repository.name}
              description="Percentage of issues relative to the expected baseline."
              title="Issues"
              report={report?.issuesPercentage}
            />
            <AnalysisItem
              owner={report?.repository.owner}
              name={report?.repository.name}
              description="Percentage of duplicated files in the repository."
              title="Duplication"
              report={report?.duplicationPercentage}
            />
            <AnalysisItem
              owner={report?.repository.owner}
              name={report?.repository.name}
              description="Average percentage of lines covered by tests in each repository."
              title="Coverage"
              report={report?.coverage.coveragePercentage}
            />

            <LastAnalysis report={report} user={userEntity?.metadata?.name} />
          </Grid>

          <CardBottom report={report} />
        </CardContent>
      </InfoCard>
    </FeatureFlagRibbon>
  );
}
