import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import {
  ProviderSettingsItem,
  SettingsLayout,
  UserSettingsPage,
} from '@backstage/plugin-user-settings';
import { LoadTestingPage } from 'plugin-load-testing';
import { AnnouncementsPage } from 'plugin-announcements';
import { ApiCatalogPage } from 'plugin-api-catalog';
import { AppCatalogPage } from 'plugin-app-catalog';
import { CatalogEntityPage } from 'plugin-catalog';
import { CDPStandaloneRouter } from 'plugin-cdp';
import { CostInsightsPage } from 'plugin-cloud-cost-insights';
import { ComplianceAndSecurityPage } from 'plugin-compliance-and-security';
import { ContainerImagesRouter } from 'plugin-container-images';
import { oauth2ApiRef } from 'plugin-core';
import { CreateRepositoryPage } from 'plugin-create-repository';
import { CyberWeekPage } from 'plugin-cyberweek';
import { DependenciesStandAloneRouter } from 'plugin-dependencies';
import { DomainsPage } from 'plugin-domains';
import { InfrastructurePage } from 'plugin-infrastructure';
import { MLRouter } from 'plugin-ml';
import { MLPlatformRouter } from 'plugin-ml-platform';
import { NakadiRouter } from 'plugin-nakadi';
import { NLInterfacePage } from 'plugin-nl-interface';
import { OpsgeniePage } from 'plugin-opsgenie';
import { PersonalizationPage } from 'plugin-personalization';
import { ReportingLinePage } from 'plugin-reporting-line';
import { RkeepPage } from 'plugin-rkeep';
import { TechInsightsPage } from 'plugin-scorecards';
import { SearchPage } from 'plugin-search';
import { SettingsOnboardingPage } from 'plugin-settings-onboarding';
import { StartPage } from 'plugin-start-page';
import { SupportRouter } from 'plugin-support';
import { TeamsCatalogPage } from 'plugin-teams-catalog';
import { TechRadar } from 'plugin-tech-radar';
import { ToerOrgPage } from 'plugin-toer-org';
import { ToolingRouter } from 'plugin-tooling-catalog';
import { NotFoundPage } from 'plugin-ui-components';
import { XcmetricsPage } from 'plugin-xcmetrics';
import { ZalandoDocsPage } from 'plugin-zalando-docs';
import React from 'react';
import { Navigate, Route, RouteProps } from 'react-router';
import { NavigateWithParams } from './components/NavigateWithParams';
import { SettingsAboutPage } from './components/SettingsAboutPage';
import { TechDocsReaderPageCustom } from './components/TechDocs';
import { OktaIcon } from './OktaIcon';
import { WithAnalytics } from './tracking/WithAnalytics';

export type TrackedRouteType = RouteProps & {
  plugin: string;
  renderChildren?: React.ReactNode;
};

export const trackedRoutes: TrackedRouteType[] = [
  {
    path: '/catalog',
    plugin: 'catalog',
    element: <Navigate to="/applications" />,
  },
  {
    path: '/catalog/:namespace/:kind/:name/*',
    plugin: 'catalog',
    element: <CatalogEntityPage />,
  },
  {
    path: '/catalog-graph',
    plugin: 'catalog',
    element: <CatalogGraphPage initialState={{ showFilters: false }} />,
  },
  {
    path: '/applications/*',
    plugin: 'app-catalog',
    element: <AppCatalogPage />,
  },
  { path: '/domains', plugin: 'domains', element: <DomainsPage /> },
  {
    path: '/toer-orgs/*',
    plugin: 'toer-orgs',
    element: <ToerOrgPage />,
  },
  {
    path: '/reporting-line/*',
    plugin: 'reporting-line',
    element: <ReportingLinePage />,
  },
  { path: '/apis/*', plugin: 'api-catalog', element: <ApiCatalogPage /> },
  // '/api' needs to be removed after some grace period (suggestion 31.12.2022).
  {
    path: '/api/linter',
    plugin: 'catalog',
    element: <Navigate to="/apis/linter" />,
  },
  { path: '/api', plugin: 'api-catalog', element: <Navigate to="/apis" /> },
  { path: '/create/*', plugin: 'create', element: <CreateRepositoryPage /> },
  { path: '/cyberweek/*', plugin: 'cyberweek', element: <CyberWeekPage /> },
  { path: '/support/*', plugin: 'support', element: <SupportRouter /> },
  { path: '/home/*', plugin: 'start', element: <StartPage /> },
  { path: '/cdp/*', plugin: 'cdp', element: <CDPStandaloneRouter /> },
  {
    path: '/container-images/*',
    plugin: 'container-images',
    element: <ContainerImagesRouter />,
  },
  { path: '/search/*', plugin: 'search', element: <SearchPage /> },
  { path: '/ml/*', plugin: 'ml-platform', element: <MLPlatformRouter /> },
  /* Used to redirect old explore links to their corresponding plugins */
  {
    path: '/explore/teams',
    plugin: 'teams-catalog',
    element: <Navigate to="/teams" />,
  },
  {
    path: '/explore/tooling-catalog',
    plugin: 'tooling-catalog',
    element: <Navigate to="/tooling" />,
  },
  {
    path: '/explore/tech-radar',
    plugin: 'tech-radar',
    element: <Navigate to="/tech-radar" />,
  },
  /***/
  { path: '/teams', plugin: 'teams-catalog', element: <TeamsCatalogPage /> },
  {
    path: '/compliance-and-security/*',
    plugin: 'compliance-and-security',
    element: <ComplianceAndSecurityPage />,
  },
  {
    path: '/product-portfolio',
    plugin: 'tooling-catalog',
    element: <Navigate to="/tooling" />,
  },
  {
    path: '/tooling-catalog',
    plugin: 'tooling-catalog',
    element: <Navigate to="/tooling" />,
  },
  {
    path: '/tooling/*',
    plugin: 'tooling-catalog',
    element: <ToolingRouter />,
  },
  { path: '/tech-radar/*', plugin: 'tech-radar', element: <TechRadar /> },
  {
    path: '/settings/*',
    plugin: 'settings',
    element: (
      <UserSettingsPage
        providerSettings={
          <ProviderSettingsItem
            title="Zalando's Okta OAuth"
            description="Zalando's Okta OAuth"
            icon={() => (
              <OktaIcon
                width={46}
                height={46}
                style={{
                  marginRight: 10,
                  backgroundColor: '#dedede',
                  borderRadius: '50%',
                }}
              >
                <title>Okta OAuth</title>
              </OktaIcon>
            )}
            apiRef={oauth2ApiRef}
          />
        }
      />
    ),
    renderChildren: (
      <>
        <SettingsLayout.Route path="/personalization" title="Personalization">
          <PersonalizationPage />
        </SettingsLayout.Route>
        <SettingsLayout.Route path="/onboarding" title="Onboarding">
          <SettingsOnboardingPage />
        </SettingsLayout.Route>
        <SettingsLayout.Route path="/about" title="About">
          <SettingsAboutPage />
        </SettingsLayout.Route>
      </>
    ),
  },
  {
    path: '/docs/*',
    plugin: 'zalando-docs',
    element: <ZalandoDocsPage />,
  },
  // NOTE: This route is needed to render doc embedded page
  {
    path: '/backstage-docs/*',
    plugin: 'zalando-docs',
    element: <TechDocsIndexPage />,
    renderChildren: <DefaultTechDocsHome />,
  },
  {
    path: '/docs/:namespace/:kind/:name/*',
    plugin: 'zalando-docs',
    element: <TechDocsReaderPage children={TechDocsReaderPageCustom} />,
  },
  {
    path: '/infrastructure/*',
    plugin: 'infrastructure',
    element: <InfrastructurePage />,
  },
  {
    path: '/cloud-cost-insights/*',
    plugin: 'cloud-cost-insights',
    element: <CostInsightsPage />,
  },
  {
    path: '/libraries/*',
    plugin: 'plugin-dependencies',
    element: <DependenciesStandAloneRouter />,
  },
  {
    path: '/nakadi/*',
    plugin: 'plugin-nakadi',
    element: <NakadiRouter />,
  },
  {
    path: '/announcements/*',
    plugin: 'plugin-announcements',
    element: <AnnouncementsPage />,
  },
  {
    path: '/reliability-center/*',
    plugin: 'opsgenie',
    element: <OpsgeniePage />,
  },
  {
    path: '/nl-interface/*',
    plugin: 'plugin-nl-interface',
    element: <NavigateWithParams to="/ask" replace />,
  },
  {
    path: '/ask/*',
    plugin: 'plugin-nl-interface',
    element: <NLInterfacePage />,
  },
  {
    path: '/tech-insights/*',
    plugin: 'plugin-scorecards',
    element: <TechInsightsPage />,
  },
  {
    path: '/load-tests/*',
    plugin: 'plugin-load-testing',
    element: <LoadTestingPage />,
  },
  {
    path: '/mlp',
    plugin: 'ml',
    element: <MLRouter />,
  },
  {
    path: '/rkeep/*',
    plugin: 'plugin-rkeep',
    element: <RkeepPage />,
  },
  {
    path: '/mlp/*',
    plugin: 'ml',
    element: <MLRouter />,
  },
  // NOTE: This route must be the last one in the list
  { path: '/*', plugin: 'none', element: <NotFoundPage /> },
];

export const routes = (
  <WithAnalytics>
    <FlatRoutes>
      <Route path="/" element={<Navigate to="/home" />} />
      {trackedRoutes.map(({ path, element, renderChildren }) => (
        <Route
          key={path}
          path={path}
          element={element as React.ReactElement}
          children={renderChildren}
        />
      ))}
      <Route path="/xcmetrics/*" element={<XcmetricsPage />} />
    </FlatRoutes>
  </WithAnalytics>
);
