import { getTeamNameFromFullName } from 'plugin-core';

export type Format = 'nameOnly' | 'aliasOnly' | 'nameAndAlias';

/**
 *
 * @param team IEntityGroup
 * @param format Value can be one of the following: `"nameOnly"`, `"aliasOnly"` or `"nameAndAlias"`. Defaults to `"nameAndAlias"`.
 * @returns A string containing the formatted team name e.g. Builder Portal, pitchfork or Builder Portal (pitchfork)
 */
export function formatTeamName(
  team: IEntityGroup | undefined,
  format: Format = 'nameAndAlias',
): string {
  if (!team) return '';
  const teamAlias = team?.spec?.id || '';
  const teamFullName = getTeamNameFromFullName(team?.spec?.fullName || '');
  switch (format) {
    case 'nameOnly':
      return teamFullName;
    case 'aliasOnly':
      return teamAlias;
    default: {
      let output = teamFullName;
      if (teamAlias) output += ` (${teamAlias})`;
      return output;
    }
  }
}
