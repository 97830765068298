import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ColumnFilter, TableGroupFilter } from '../types/filtering';
import type { Table } from '@tanstack/react-table';
import { GroupFilterItem } from './GroupFilterItem';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';

interface Props<T> {
  table: Table<T>;
  items?: TableGroupFilter<T>[];
}

export function GroupFilters<T>({ items, table }: Props<T>) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';

  if (!items) return null;

  const selectedGroup = table
    .getState()
    .columnFilters.find(f => f.id === 'group') as ColumnFilter | undefined;

  const groupsMap = items.reduce((map, item) => {
    const group = item.group || '';
    if (!map.has(group)) {
      map.set(group, []);
    }
    map.get(group)?.push(item);
    return map;
  }, new Map<string, TableGroupFilter<T>[]>());
  const groups = Array.from(groupsMap).map(([title, groupItems]) => ({
    title,
    items: groupItems,
  }));

  return (
    <Box
      sx={{
        backgroundColor: isDark ? '#1b1b1b' : 'rgba(0, 0, 0, 0.11)',
        boxShadow: isDark
          ? 'inset 0 0 3px rgba(255, 255, 255, 0.2)'
          : undefined,
        gridArea: 'filters',
        height: 'fit-content',
        padding: 1,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        p: 1.25,
      }}
    >
      {groups.map(group => {
        return (
          <Box key={group.title}>
            {group.title && (
              <Typography variant="subtitle2">{group.title}</Typography>
            )}
            <List
              disablePadding
              sx={{
                borderRadius: 1.5,
                overflow: 'hidden',
                boxShadow: 1,
              }}
            >
              {group.items.map(item => {
                return (
                  <GroupFilterItem
                    key={item.id}
                    table={table}
                    item={item}
                    selectedGroup={selectedGroup}
                  />
                );
              })}
            </List>
          </Box>
        );
      })}
    </Box>
  );
}
