import React from 'react';
import type {
  CustomPaginationState,
  PaginationOptions,
} from './types/pagination';
import TablePagination, {
  TablePaginationProps,
} from '@mui/material/TablePagination';
import { Table } from '@tanstack/react-table';
import { DEFAULT_PAGE_SIZE_OPTIONS } from './constants';

interface Props<T> {
  table: Table<T>;
  pagination: true | PaginationOptions;
  loading?: boolean;
}

export function Pagination<T>({ table, pagination, loading }: Props<T>) {
  const { pageSize, pageIndex } = table.getState().pagination;

  const { rowsPerPageOptions, hideRowsPerPage, hideFirstAndLast } =
    pagination === true ? ({} as PaginationOptions) : pagination;

  const commonProps: Omit<
    TablePaginationProps,
    'count' | 'onPageChange' | 'page'
  > = {
    rowsPerPageOptions: rowsPerPageOptions ?? DEFAULT_PAGE_SIZE_OPTIONS,
    showFirstButton: !hideFirstAndLast,
    showLastButton: !hideFirstAndLast,
    disabled: loading,
    labelRowsPerPage: hideRowsPerPage ? '' : undefined,
    rowsPerPage: pageSize,
    component: 'div',
    sx: {
      bgcolor: 'background.paper',
      borderTop: '2px solid',
      borderColor: 'divider',

      '& > div ': hideRowsPerPage
        ? {
            '& [class*=MuiTablePagination-input]': {
              display: 'none',
            },
            '& [class*=MuiTablePagination-selectLabel]': {
              display: 'none',
            },
          }
        : undefined,
    },
    classes: {},
    slotProps: {
      select: {
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
      },
    },
  };

  // Server-side cursor pagination
  if (typeof pagination === 'object' && 'nextCursor' in pagination) {
    const { nextCursor } = pagination;

    return (
      <TablePagination
        {...commonProps}
        count={nextCursor ? pageSize * 3 : pageSize}
        page={(table.getState().pagination as any).cursor ? 1 : 0}
        showFirstButton
        showLastButton={false}
        onPageChange={(_, page) => {
          table.setPagination(prev => ({
            ...prev,
            cursor: page === 0 ? undefined : nextCursor,
          }));
        }}
        onRowsPerPageChange={ev => {
          const { value } = ev.target;
          table.setPagination({
            pageIndex: 0,
            pageSize: value ? Number(value) : 10,
            cursor: undefined,
          } satisfies CustomPaginationState as any);
        }}
        slotProps={{
          ...commonProps.slotProps,
          actions: {
            previousButton: {
              className: 'd-none',
            },
          },
        }}
        sx={{
          ...commonProps.sx,
          '[class*=MuiTablePagination-displayedRows]': {
            display: 'none',
          },
        }}
      />
    );
  }

  // Server-side offset and limit pagination
  if (typeof pagination === 'object' && 'offset' in pagination) {
    const { offset, total } = pagination;

    return (
      <TablePagination
        {...commonProps}
        count={total}
        onPageChange={(_, page) =>
          table.setPagination(prev => ({
            ...prev,
            offset: page * pageSize - pageSize,
          }))
        }
        onRowsPerPageChange={ev => {
          const { value } = ev.target;
          table.setPagination({
            offset: 0,
            pageIndex: 0,
            pageSize: value ? Number(value) : 10,
          } satisfies CustomPaginationState as any);
        }}
        page={offset === 0 ? 0 : offset / pageSize}
      />
    );
  }

  // Fully controlled pagination
  return (
    <TablePagination
      {...commonProps}
      count={table.getFilteredRowModel().rows.length}
      page={pageIndex}
      onPageChange={(_, page) => table.setPageIndex(page)}
      onRowsPerPageChange={ev => {
        const { value } = ev.target;
        table.setPagination({
          pageIndex: 0,
          pageSize: value ? Number(value) : 10,
        });
      }}
    />
  );
}
