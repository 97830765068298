import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { type AlertProps } from '@mui/lab/Alert';
import { useStyles } from '../../StylingComponent/styles';

const PopUpAlert = (props: AlertProps) => {
  const classes = useStyles();
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      className={
        props.severity === 'success'
          ? classes.orangeSnackbar
          : classes.darkSnackbar
      }
    />
  );
};

interface TestDetailsSnackbarProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error';
  onClose: () => void;
}

export const TestDetailsSnackbar = ({
  open,
  message,
  severity,
  onClose,
}: TestDetailsSnackbarProps) => (
  <Snackbar
    open={open}
    autoHideDuration={10000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <div>
      <PopUpAlert onClose={onClose} severity={severity}>
        {message}
      </PopUpAlert>
    </div>
  </Snackbar>
);
