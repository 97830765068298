import {
  InfoCard,
  StatusError,
  StatusOK,
  StatusWarning,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { eventTypesApiRef } from '../../api/EventTypesApi';
import { EventTypesManager } from '../../api/EventTypesApi/EventTypesApi';
import { EventType } from '../../domain/EventTypes';
import { useApiCall } from '../../hooks/useApiCall';
import { DLQList } from '../DLQList';
import { EventTypeList } from '../EventTypeList';

export function EventsTab() {
  const { entity } = useEntity();
  const application = entity.metadata.name;

  const {
    payLoad: eventTypes = [],
    isLoading: isLoadingEventTypes,
    status: loadingEventTypesStatus,
  } = useApiCall<EventTypesManager, EventType[]>(eventTypesApiRef, api =>
    api.getEventTypes(application),
  );

  const configApi = useApi(configApiRef);
  const nakadiUiURL = configApi.getOptionalString('nakadi.ui.url') ?? '';

  return (
    <Grid container>
      <Grid item sm={12}>
        <Grid item xs={12}>
          <EventTypeList
            status={loadingEventTypesStatus}
            application={application}
            eventTypes={eventTypes}
            isLoading={isLoadingEventTypes}
            nakadiUiURL={nakadiUiURL}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <DLQList application={application} nakadiUiURL={nakadiUiURL} />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <InfoCard title="Notes">
          <Typography variant="h4">Event Types data</Typography>
          <Typography paragraph>
            <IconButton aria-label="expand row" size="small" disableRipple>
              <KeyboardArrowDownIcon />
            </IconButton>{' '}
            Shows detailed publishing stats.
            <br />
            <Box
              component={StatusOK}
              sx={{ display: 'inline', marginLeft: '0.35rem !important' }}
            />
            in front of a traffic stat indicates that your publishing rate is
            OK. We recommend you to publish no more than 1 MB/s per partition to
            avoid publishing latencies.
            <br />
            <Box
              component={StatusWarning}
              sx={{ display: 'inline', marginLeft: '0.35rem !important' }}
            />
            indicates that you are publishing more than 1MB/s per partition.
            <br />
            <Box
              component={StatusError}
              sx={{ display: 'inline', marginLeft: '0.35rem !important' }}
            />
            indicates that you are publishing more than 2MB/s per partition.
          </Typography>
          <Typography variant="h4">Subscription data</Typography>
          <Typography paragraph>
            <strong>Unconsumed Events</strong> is the currently retained number
            of events unconsumed accross all partitions. If this number is
            higher than events available in Nakadi, it means that some events
            are deleted permanently and never consumed.
            <br />
            <strong>Consumer lag</strong> is the maximum lag accross all
            partitions.
          </Typography>
        </InfoCard>
      </Grid>
    </Grid>
  );
}
