import React from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import CloudIcon from '@mui/icons-material/Cloud';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import BuildIcon from '@mui/icons-material/Build';
import HelpIcon from '@mui/icons-material/Help';

import { PIPELINE_STATUS, STEP_STATUS, STEP_TYPE } from '../constants';
import { STATUS_COLORS } from './colors';

export function getColorByStatus(status: string) {
  switch (status) {
    case PIPELINE_STATUS.NOT_STARTED:
    case STEP_STATUS.VALIDATING:
      return STATUS_COLORS.default;
    case STEP_STATUS.SKIPPED:
      return STATUS_COLORS.skipped;
    case PIPELINE_STATUS.SUCCEEDED:
      return STATUS_COLORS.success;
    case STEP_STATUS.PENDING_APPROVAL:
      return STATUS_COLORS.warning;
    case PIPELINE_STATUS.FAILED:
    case PIPELINE_STATUS.ERROR:
      return STATUS_COLORS.failed;
    case STEP_STATUS.INIT:
      return STATUS_COLORS.info;
    case PIPELINE_STATUS.IN_PROGRESS:
      return STATUS_COLORS.progress;
    case PIPELINE_STATUS.PENDING:
      return STATUS_COLORS.info;
    case PIPELINE_STATUS.ABORTED:
    case STEP_STATUS.ABORTING:
    case STEP_STATUS.TIMEOUT:
      return STATUS_COLORS.aborted;
    case STEP_STATUS.PAUSED:
      return STATUS_COLORS.info;
    default:
      return STATUS_COLORS.default;
  }
}

export function getIconByStepType(type: string) {
  switch (type) {
    case STEP_TYPE.SCRIPT:
      return <SettingsIcon />;
    case STEP_TYPE.PROCESS:
    case STEP_TYPE.DEPLOY:
      return <CloudIcon />;
    case STEP_TYPE.TRAFFIC:
    case STEP_TYPE.OVERLAY:
      return <BuildIcon />;
    case STEP_TYPE.SLEEP:
      return <AirlineSeatIndividualSuiteIcon />;
    default:
      return <HelpIcon />;
  }
}

export function getStatusByStepType(
  type: string,
  status: string,
  medatada: { paused: boolean },
) {
  switch (type) {
    case STEP_TYPE.TRAFFIC:
      if (medatada.paused && status === STEP_STATUS.IN_PROGRESS) {
        return STEP_STATUS.PAUSED;
      }
      return status;
    default:
      return status;
  }
}
