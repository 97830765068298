import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

export const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'separate',

    '&.striped': {
      '& tbody tr:nth-of-type(odd)': {
        backgroundColor: `var(--tr-bg)`,
      },
    },

    '&.dense': {
      '& tbody tr td': {
        paddingBlock: theme.spacing(1),
      },
    },

    '&:not(.striped)': {
      '& tbody tr:not(:last-of-type) td': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },

    '& thead': {
      '& tr': {
        '&.header-group': {},

        '& th': {
          borderBlock: '1px solid #d5d5d5',
        },
      },

      '& th': {
        fontWeight: 700,
        color: 'inherit',
        paddingBlock: theme.spacing(1),
        wordBreak: 'keep-all',

        '& > button': {
          position: 'relative',
          backgroundColor: 'transparent',
          padding: 0,
          border: 0,
          fontWeight: 'bold',
          display: 'inline-flex',
          alignItems: 'center',
          color: theme.palette.text.primary,
          textAlign: 'start',
          textTransform: 'uppercase',
          '&:active': {
            color: theme.palette.grey.A700,
          },
          '&:hover .sort-icon': {
            opacity: 1,
            '&:not(.sorted)': {
              color: theme.palette.text.disabled,
              fontWeight: 'normal',
            },
          },
          '&:not(:disabled)': {
            cursor: 'pointer',
          },
        },
        '& .sort-icon': {
          position: 'absolute',
          insetInlineEnd: -15,
          fontSize: '1.1em',
          transition: 'all 200ms linear',
          transformOrigin: 'center',
          opacity: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          '&.sorted': {
            opacity: 1,
            animation: 'bounce 200ms ease-in-out',
            '&.desc': {
              transform: 'rotateX(180deg)',
            },
          },
          '& > svg': {
            fontSize: 'inherit',
          },
        },
      },
    },

    '& .tree-icon-btn': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: 20,
      height: 20,

      '& .icon-placeholder': {
        opacity: 0,
        visibility: 'hidden',
      },
      '& .icon-wrapper': {
        position: 'absolute',
        width: 20,
        height: 20,

        '& .icon': {
          position: 'absolute',
          top: 0,
          left: 0,
          transition: 'opacity 100ms linear, transform 100ms ease-in-out',
          opacity: 0,
          transform: 'rotate(90deg)',

          '&.show': {
            opacity: 1,
            transform: 'rotate(0deg)',
          },
        },
      },
    },

    '& tr': {
      '&:nth-of-type(2n+1)': {
        backgroundColor: 'transparent',
      },
    },
  },
  tableCell: {
    paddingBlock: '0.6rem',
  },
  tableCellExpand: {
    position: 'relative',
    paddingInlineEnd: '0.6rem',
    paddingInlineStart: 0,
    paddingBlock: '0.3rem 0.5rem',

    '&.show-guides > button': {
      '&:not([data-indent="1"])::before': {
        content: '""',
        position: 'absolute',
        height: '1000%',
        width: 1,
        bottom: '-200%',
        left: 'calc(-50% - 2px)',
        borderLeft: '1px solid var(--disabled-color)',
      },
      '&:not([data-has-children="false"])[data-expanded="true"]::after': {
        content: '""',
        position: 'absolute',
        height: '1000%',
        width: 1,
        top: '100%',
        left: '50%',
        borderLeft: '1px solid var(--disabled-color)',
      },
    },
  },
  rSuiteDatePicker: {
    padding: 0,
    margin: 0,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'var(--disabled-color)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],

    '&:focus-within, &.active': {
      '& legend': {
        translate: '0 -8px',
        fontSize: '0.8rem',
        width: 'auto',
        paddingInline: 3,
        color: theme.palette.textSubtle,
      },
    },
    '& legend': {
      padding: 0,
      transition: 'all 200ms ease-out',
      translate: '0 9px',
      height: 0,
      width: 0,
      whiteSpace: 'nowrap',
      marginInlineStart: theme.spacing(1.5),
    },
    '&.date-time-range input.rs-input': {
      fontSize: '0.85em',
    },
    '&.active': {
      '& input.rs-input:not(:disabled)': {
        color: theme.palette.primary.main,
      },
    },
    '& .rs-picker': {
      '& .rs-input-group': {
        outline: 0,
      },
      '& .rs-input-group-addon': {
        color: 'currentColor',
      },
      '& .rs-input-group.rs-input-group-inside, .rs-input': {
        backgroundColor: 'transparent',
      },
      '& .rs-picker-clean.rs-btn-close': {
        color: 'inherit',
      },
      '& .rs-date-range-input.rs-input': {
        '&::placeholder': {
          color: 'currentColor',
        },
      },
    },
  },
  rSuiteDatePickerMenu: {
    '--rs-btn-link-hover-text': theme.palette.primary.main,
    '--rs-btn-link-text': theme.palette.primary.main,
    '--rs-input-focus-border': theme.palette.primary.main,
    '--rs-bg-active': theme.palette.primary.main,
    '--rs-btn-primary-bg': theme.palette.primary.main,
    '--rs-calendar-cell-selected-hover-bg': theme.palette.primary.dark,
    '--rs-btn-primary-hover-bg': theme.palette.primary.dark,
    '--rs-calendar-range-bg': alpha(theme.palette.primary.main, 0.11),
    '--rs-listbox-option-hover-bg': alpha(theme.palette.primary.main, 0.15),
    '--rs-listbox-option-hover-text': 'currentColor',

    '& .rs-btn:not(.rs-btn-subtle):not(.rs-btn-link)': {
      boxShadow: theme.shadows[2],
    },
  },
}));
