import React, { type ReactNode } from 'react';
import type {
  TableToolbarItem,
  TableToolbarItemWithChildren,
  TableToolbarItemWithClick,
  TableToolbarItemWithHref,
} from '../types/toolbar';
import { IconLinkVertical, LinkButton } from '@backstage/core-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  item: TableToolbarItem;
  loading?: boolean;
}

export function ToolbarItem({ item, loading }: Props) {
  const itemWithChildren = item as TableToolbarItemWithChildren;
  const itemWithHref = item as TableToolbarItemWithHref;
  const itemWithClick = item as TableToolbarItemWithClick;

  let content: ReactNode = <></>;

  if (itemWithChildren.children) {
    content = itemWithChildren.children;
  } else if (itemWithHref.href) {
    const Icon = () =>
      itemWithHref.icon && (
        <itemWithHref.icon color="primary" fontSize="small" />
      );
    if (itemWithHref.label) {
      content = (
        <LinkButton
          size="small"
          startIcon={<Icon />}
          to={itemWithHref.href}
          children={itemWithHref.label}
          color="primary"
        />
      );
    } else {
      content = (
        <IconLinkVertical href={itemWithHref.href} icon={<Icon />} label="" />
      );
    }
  } else if (!!itemWithClick.onClick) {
    const Icon = () =>
      itemWithClick.icon && (
        <itemWithClick.icon fontSize="small" onClick={itemWithClick.onClick} />
      );
    if (itemWithClick.label) {
      content = (
        <Button
          startIcon={<Icon />}
          children={itemWithClick.label}
          onClick={itemWithClick.onClick}
          size="small"
          disabled={loading}
          sx={{ color: loading ? 'action.disabled' : 'primary.main' }}
        />
      );
    } else {
      content = (
        <IconButton
          children={<Icon />}
          size="small"
          disabled={loading}
          onClick={itemWithClick.onClick}
          sx={{ color: loading ? 'action.disabled' : 'primary.main' }}
        />
      );
    }
  }

  return itemWithHref.tooltip ? (
    <Tooltip title={itemWithHref.tooltip}>
      <div>{content}</div>
    </Tooltip>
  ) : (
    content
  );
}
