import React, { useMemo } from 'react';
import { AboutField, HStack, PieChart, VStack } from 'plugin-ui-components';
import { Link, MarkdownContent } from '@backstage/core-components';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import useTheme from '@mui/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Circle } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';

type ScorecardDetails = Pick<
  TechInsights.Scorecard,
  'name' | 'title' | 'description' | 'checks'
> & {
  completionPercentage?: number;
  categories: string[];
  stats?: Record<'passing' | 'failing' | 'skipped', number>;
};

interface Props {
  scorecard: IEntityScorecard | TechInsights.Scorecard;
}

export function ScorecardOverview({ scorecard }: Props) {
  const theme = useTheme();

  const details = useMemo((): ScorecardDetails => {
    if ((scorecard as IEntityScorecard).kind === 'Scorecard') {
      const obj = scorecard as IEntityScorecard;

      const appliesTo =
        obj.relations?.filter(r => r.type === 'scorecardConsumedBy').length ??
        0;
      const failing = obj.spec.failing;
      const skipped =
        // If non has failed nor they have passed, then we consider them as skipped
        failing === 0 && obj.spec.completionPercentage === 0
          ? appliesTo
          : obj.spec.exclusions.length;

      const passing = obj.spec.passing;

      return {
        name: obj.metadata.name,
        title: obj.metadata.title ?? '',
        description: obj.metadata.description ?? '',
        checks: obj.spec.checks,
        completionPercentage: obj.spec.completionPercentage,
        categories: obj.spec.categories,
        stats: { passing, failing, skipped },
      };
    }
    const obj = scorecard as TechInsights.Scorecard;
    return {
      name: obj.name,
      title: obj.title,
      description: obj.description,
      checks: obj.checks,
      categories: obj.categories,
    };
  }, [scorecard]);

  const getChartDisplayValue = () => {
    if (details.stats?.passing || details.stats?.failing) {
      return new Intl.NumberFormat('en', {
        style: 'percent',
        maximumFractionDigits: 0,
      }).format((details.completionPercentage ?? 0) / 100);
    }
    return 'N/A';
  };

  return (
    <Grid container gap={2}>
      <Grid item xs={12} lg={6}>
        <Card>
          <CardHeader title={details.title} />
          <CardContent>
            <Grid container columnGap={4} rowGap={1}>
              <AboutField
                xs={10}
                md={5}
                label="Name"
                value={details.name}
                canCopy
              />
              <AboutField
                xs={10}
                md={5}
                label="Checks"
                value={
                  <VStack spacing="0.2rem" m={0}>
                    {details.checks.map(check => (
                      <Link
                        key={check.id}
                        to={`/tech-insights/checks/${check.name}`}
                      >
                        {check.title}
                      </Link>
                    ))}
                  </VStack>
                }
              />
              <AboutField
                xs={12}
                label="Categories"
                value={
                  <HStack spacing=".5rem">
                    {!details.categories?.length ? (
                      <Typography>-</Typography>
                    ) : (
                      details.categories?.map(category => {
                        return (
                          <Chip
                            key={category}
                            className="info-chip"
                            label={category}
                            size="small"
                            variant="outlined"
                          />
                        );
                      })
                    )}
                  </HStack>
                }
              />
              <AboutField
                xs={12}
                md={12}
                label="Description"
                value={<MarkdownContent content={details.description} />}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {details.stats && (
        <Grid item xs={12} lg={5.8}>
          <Card>
            <CardHeader title="Statistics" />
            <CardContent
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: details.stats.passing,
                          label: 'Succeeded',
                          color: theme.palette.success.main,
                        },
                        {
                          id: 1,
                          value: details.stats.failing,
                          label: 'Failed',
                          color: theme.palette.error.main,
                        },
                        {
                          id: 2,
                          value: details.stats.skipped,
                          label: 'Skipped',
                          color: theme.palette.grey[500],
                        },
                      ],
                      innerRadius: 80,
                    },
                  ]}
                  height={200}
                  width={300}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                  displayValue={getChartDisplayValue()}
                />
              </Box>
              {/* Custom legend */}
              <Box display="inline-flex" flexDirection="column" gap={1}>
                <Box display="flex" gap={1} alignItems="center">
                  <Circle fontSize="small" color="success" />
                  <Typography variant="body2">
                    Succeeded ({details.stats.passing})
                  </Typography>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                  <Circle fontSize="small" color="error" />
                  <Typography variant="body2">
                    Failed ({details.stats.failing})
                  </Typography>
                </Box>
                <Box display="flex" gap={1} alignItems="center">
                  <Circle fontSize="small" color="disabled" />

                  <Typography variant="body2">
                    Skipped ({details.stats.skipped})
                    <Tooltip title="Entities that are marked as excluded by its owner(s)">
                      <InfoIcon
                        sx={{
                          fontSize: '0.9em',
                          ml: 0.5,
                          verticalAlign: 'middle',
                          cursor: 'help',
                        }}
                      />
                    </Tooltip>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
}
