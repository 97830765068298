import makeStyles from '@mui/styles/makeStyles';

type ClassKey = 'inputRoot';
export const useStyles = makeStyles<Theme, {}, ClassKey>(theme => ({
  inputRoot: {
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));
