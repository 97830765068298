import React from 'react';
import type { GroupedTab, NodeTab } from './types';
import { LinkButton } from '@backstage/core-components';
import { useStyles } from './styles';
import Box from '@mui/material/Box';
import { useParams } from 'react-router';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { FeatureFlagIcon } from '../FeatureFlagRibbon';
import Badge from '@mui/material/Badge';
import { Helmet } from 'react-helmet';

interface Props {
  tab: GroupedTab;
}

const trimTrailingSlash = (str: string) => str.replace(/\/$/, '');

export function TabButton({ tab }: Props) {
  const featureFlags = useApi(featureFlagsApiRef);
  const styles = useStyles();
  const params = useParams();
  const pathname = useLocation().pathname;
  const path = params['*'];

  function getPath(node: NodeTab) {
    return path
      ? pathname.replace(new RegExp(`/${path}$`), node.path)
      : `${trimTrailingSlash(pathname)}${node.path}`;
  }

  if ('element' in tab) {
    if (tab.featureFlag && !featureFlags.isActive(tab.featureFlag)) return null;
    if (tab.hidden) return null;
    const isActive = tab.path === `/${path}`;
    return (
      <LinkButton
        to={getPath(tab)}
        className={cn(styles.tab, { active: isActive })}
        color="inherit"
      >
        {isActive && <Helmet title={tab.label} />}
        {tab.badge ? (
          <Badge variant="dot" color="error">
            {tab.label}
          </Badge>
        ) : (
          tab.label
        )}
        <FeatureFlagIcon name={tab.featureFlag ?? ''} />
      </LinkButton>
    );
  }

  const isGroupActive = tab.children.some(child => child.path === `/${path}`);
  return (
    <Box
      sx={{
        '&:hover': {
          '& .menu': {
            display: 'flex',
            zIndex: 2,
          },
        },
      }}
    >
      <LinkButton
        to={getPath(tab.children[0])}
        color={isGroupActive ? 'primary' : 'inherit'}
        className={styles.tab}
        disableRipple
      >
        {tab.label}
        <ArrowDropDownIcon className="arrow" sx={{ height: 20 }} />
      </LinkButton>
      <Box
        className="menu"
        sx={{
          display: isGroupActive ? 'flex' : 'none',
          position: 'absolute',
          left: 0,
          width: '100%',
          backgroundColor: 'background.paper',
          pl: 3,
        }}
      >
        {tab.children.map((child, i) => {
          return <TabButton key={i} tab={child} />;
        })}
      </Box>
    </Box>
  );
}
