import { IRun, IProvidedParameter, IDeliveryConfigurationParameter, IDeliveryStepConfig } from '../../api/types/payload';
import {IWithRepoInfo, RepositoryModel} from "../repository";
import { RunsTagsModel } from '../runs';
import { RuntimeModel } from './runtime.model';
import { RunStepModel } from './step.model';

export class RunModel implements IWithRepoInfo {
  id: string;
  created_at: string;
  triggered_at: string;
  finished_at: string;
  build_version: string;
  commit_message: string;
  commit_id: string;
  status: string;
  pr_title: string;
  pr_number: number;
  target_branch: string;
  source_type: string;
  accountable_user: string;
  branch: string;
  target_repo: string;
  source_repo: string;
  delivery_configuration: any;
  parameters: Array<IDeliveryConfigurationParameter>;
  delivery_step_config: Array<IDeliveryStepConfig>;
  dependency_vars: { [key: string]: string };
  env_vars: { [key: string]: string };
  error: string;
  warnings: string[];
  steps: Array<RunStepModel>;
  provided_parameters: Array<IProvidedParameter>;
  runtime: RuntimeModel;
  retriggered_from_pipeline: string;
  retriggered_from_pipeline_build_version: string;
  triggered_by_dependency: string;
  deployment_unit: string;
  event: string;
  tags: RunsTagsModel;

  isPullRequest: boolean;
  hasParameters: boolean;
  hasDependencyVars: boolean;
  hasProvidedParams: boolean;
  isRetriggered: boolean;
  isTriggeredByDependency: boolean;
  isSchedule: boolean;
  areStepsConcurrent: boolean;
  repoInfo: RepositoryModel;

  constructor(json: IRun = {} as IRun) {
    this.id = json.id ?? '';
    this.created_at = json.created_at ?? '';
    this.triggered_at = json.triggered_at ?? '';
    this.finished_at = json.finished_at ?? '';
    this.build_version = json.build_version ?? '';
    this.commit_message = json.commit_message ?? '';
    this.commit_id = json.commit_id ?? '';
    this.status = json.status ?? '';
    this.source_type = json.source_type ?? '';
    this.pr_title = json.pr_title ?? '';
    this.pr_number = json.pr_number ?? 0;
    this.target_branch = json.target_branch ?? '';
    this.branch = json.tags?.branch ?? '';
    this.deployment_unit = json.tags?.deployment_unit ?? '';
    this.target_repo = json.target_repo ?? '';
    // in case of push pipeline source_repo is nil, in that case using target_repo
    this.source_repo = json.source_repo ?? this.target_repo;
    this.accountable_user = json.tags?.accountable_user ?? '';
    this.event = json.tags?.event ?? '';
    this.tags = new RunsTagsModel(json.tags);
    this.delivery_configuration = json.delivery_configuration ?? {};
    this.delivery_step_config = json.delivery_configuration?.pipeline ?? [];
    this.parameters = json.delivery_configuration?.parameters ?? [];
    this.dependency_vars = json.dependency_vars ?? {};
    this.env_vars = json.env_vars ?? {};
    this.error = json.error ?? '';
    this.warnings = json.warnings ?? [];
    this.provided_parameters = json.provided_parameters ?? [];
    this.runtime = new RuntimeModel(json.runtime ?? {});
    this.retriggered_from_pipeline = json.retriggered_from_pipeline ?? '';
    this.triggered_by_dependency = json.triggered_by_dependency ?? '';
    this.retriggered_from_pipeline_build_version = json.retriggered_from_pipeline_build_version ?? '';
    
    this.isPullRequest = Boolean(this.pr_number);
    this.hasParameters = Boolean(this.parameters.length);
    this.hasDependencyVars = Boolean(Object.keys(this.dependency_vars).length);
    this.hasProvidedParams = Boolean(this.provided_parameters.length);
    this.isRetriggered = Boolean(this.retriggered_from_pipeline);
    this.isTriggeredByDependency = Boolean(this.triggered_by_dependency);
    this.isSchedule = this.event === 'schedule';
    this.steps = [];
    if (json.steps) {
      json.steps.map((step, i) => {
        this.steps.push(new RunStepModel({ ...step, ordinal: i, config: this.delivery_step_config[i] }))
      });
    }
    this.areStepsConcurrent = this.steps.some(step => !!step.depends_on.length);

    this.repoInfo = RepositoryModel.fromFullPath(this.target_repo);
  }
}


