import {
  Entity,
  EntityRelation,
  parseEntityRef,
} from '@backstage/catalog-model';
import { sunriseAdminTeams } from '../constants';

/**
 * Check if a user is part of the Sunrise Admin Teams
 * @param userTeams The teams that the user belongs to
 * @returns {boolean} Whether or not the user is a Sunrise Admin
 */
export function isSunriseAdmin(userTeams: Array<IEntityGroup>): boolean {
  return userTeams.some(team => sunriseAdminTeams.includes(team.spec.team_id));
}

/**
 * Check if a user is the owner of an entity
 * @param entity The entity in question
 * @param userTeams The teams that the user belongs to
 * @returns {boolean} Whether or not the user is the owner
 */
export function isOwnerOf(entity: Entity, userTeams: IEntityGroup[]): boolean {
  const entityOwners =
    entity.relations?.filter((rel: EntityRelation) => rel.type === 'ownedBy') ||
    [];

  return entityOwners.some(({ targetRef }) =>
    userTeams.some(team =>
      [
        team.spec?.team_id?.toLowerCase(),
        team.spec?.id?.toLowerCase(),
      ].includes(parseEntityRef(targetRef)?.name.toLowerCase()),
    ),
  );
}
