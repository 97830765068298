import React, { memo, type ReactNode, useEffect, useRef } from 'react';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import MTable from '@mui/material/Table';
import {
  type ColumnDef,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { getExpandedState } from './utils/general.utils';
import { useStyles } from './styles';
import cn from 'classnames';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';
import Box from '@mui/material/Box';
import { FILTERS_WIDTH } from './constants';
import { filterFns } from './Filtering/filterFunctions';
import { useProcessedColumns } from './hooks/useProcessedColumns';
import { useProcessedData } from './hooks/useProcessedData';
import { GroupFilters } from './Filtering/GroupFilters';
import { ColumnFilters } from './Filtering/ColumnFilters';
import { useQueryParamsSync } from './hooks/useQueryParamsSync';
import { useTableState } from './hooks/useTableState';
import type { TableProps, TableState, TreeNode } from './types';
import cloneDeep from 'lodash/cloneDeep';

const defaultList: any[] = [];

function Table<T>({
  data = defaultList,
  columns,
  header,
  headerTooltip,
  enableGlobalSearch,
  pagination,
  treeMapper,
  emptyText,
  globalDepth,
  loading,
  groupFilters,
  maxHeight,
  toolbar,
  enableSearchParamsSync,
  onExport,
  striped = true,
  onStateChange,
  enableManualControl,
  dense,
  hideColumnHeader,
  tableElevation,
  ...props
}: TableProps<T>) {
  const stateRef = useRef<TableState>();
  const styles = useStyles();
  const processedData = useProcessedData(data, treeMapper);
  const processedColumns = useProcessedColumns(columns, groupFilters);
  const { state, setters } = useTableState({
    columns: processedColumns,
    groupFilters,
    pagination,
    enableSearchParamsSync,
  });

  useEffect(() => {
    if (
      !onStateChange ||
      loading ||
      !processedColumns.length ||
      /**
       * NOTE: Some state changes happen within TanStack table itself that doesn't cause any changes to
       *   the content of the state. Given that the state is a simple object, we can simply stringify it
       *   to detect changes. That would reduce the amount of times the onStateChange callback is invoked.
       */
      JSON.stringify(stateRef.current) === JSON.stringify(state)
    ) {
      return;
    }
    stateRef.current = cloneDeep(state);
    onStateChange(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const enableColumnFilters = columns.some(c => !!c.filtering);

  const table = useReactTable({
    data: processedData,
    columns: processedColumns as ColumnDef<T>[],
    state,
    ...setters,
    filterFns,
    getSubRows: treeMapper ? row => (row as TreeNode<T>).__subRows : undefined,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), // Client-side filtering
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getPaginationRowModel: !!pagination ? getPaginationRowModel() : undefined,
    getExpandedRowModel: getExpandedRowModel(),
    enableGlobalFilter: enableGlobalSearch,
    filterFromLeafRows: true,
    sortDescFirst: false,
    enableMultiSort: false,
    enableColumnFilters,
    manualFiltering: enableManualControl,
    manualPagination: enableManualControl,
    manualSorting: enableManualControl,
  });

  useQueryParamsSync(table, enableSearchParamsSync);
  useEffect(() => {
    if (globalDepth === undefined) return;
    const newState = getExpandedState(table, globalDepth);
    setters.onExpandedChange(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedData, globalDepth]);

  const showHeader = enableGlobalSearch || !!header;
  const tableHeader: ReactNode =
    typeof header === 'function'
      ? header({
          selectedGroup:
            groupFilters?.find(g =>
              state.columnFilters.some(
                f => f.id === 'group' && f.value[0].value === g.id,
              ),
            )?.label ?? '',
          total: table.getFilteredRowModel().rows.length,
        })
      : header;

  return (
    <Box
      sx={{
        display: maxHeight ? 'grid' : 'flex',
        gridTemplateColumns: enableColumnFilters
          ? `${FILTERS_WIDTH} 1fr`
          : '1fr',
        gridTemplateRows: '1fr',
        gap: 2,
        // NOTE: This small padding is added to show the shadow since the table has overflow hidden
        p: tableElevation === 0 ? 0 : 0.5,
        width: '100%',
        overflow: 'hidden',
        maxHeight,
      }}
    >
      {(enableColumnFilters || !!groupFilters) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: FILTERS_WIDTH,
            maxHeight: '100%',
            overflowY: 'auto',
            gap: 4,
          }}
        >
          <GroupFilters items={groupFilters} table={table} />
          <ColumnFilters table={table} loading={loading} />
        </Box>
      )}

      <Paper
        elevation={tableElevation}
        sx={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <TableHeader
          table={table}
          hidden={!showHeader}
          showSearch={enableGlobalSearch}
          toolbar={toolbar}
          header={tableHeader}
          headerTooltip={headerTooltip}
          loading={loading}
          onExport={onExport}
        />
        <TableContainer>
          <MTable
            {...props}
            className={cn(props.className, styles.table, { striped, dense })}
          >
            {!hideColumnHeader && <TableHead table={table} />}
            <TableBody
              table={table}
              isTree={!!treeMapper}
              emptyText={emptyText}
              loading={loading}
            />
          </MTable>
        </TableContainer>
        <TableFooter table={table} pagination={pagination} loading={loading} />
      </Paper>
    </Box>
  );
}

export const TableV2 = memo(Table) as typeof Table;
