import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  tab: {
    paddingTop: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.background.paper}`,
    borderRadius: 0,
    fontSize: '0.75rem',

    '&.active': {
      fontWeight: 700,
      borderColor: theme.palette.primary.main,
    },
    '&:not(.active)': {
      color: theme.palette.text.secondary,
    },

    '&:hover': {
      textDecoration: 'none',

      '& + .menu-options': {
        display: 'flex',
      },
    },

    '& + .menu-options': {
      display: 'none',
    },
  },
}));
