import React from 'react';
import { Table } from '@backstage/core-components';
import { IncidentWithTeams } from '../../types';
import { columns } from './columns';

interface Props {
  incidents: IncidentWithTeams[];
  loading: boolean;
}

export function IncidentsTable({ incidents, loading }: Props) {
  return (
    <Table
      options={{
        sorting: true,
        search: true,
        paging: true,
        actionsColumnIndex: -1,
        pageSize: 10,
        pageSizeOptions: [5, 10, 25, 50, 100, 150, 200],
        padding: 'dense',
      }}
      isLoading={loading}
      localization={{ header: { actions: undefined } }}
      columns={columns}
      data={incidents}
    />
  );
}
