import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  artifact: {
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  resources: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '5px',
    transition: 'all .3s ease-in-out',

    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  title: {
    display: 'inline-block',
    marginLeft: theme.spacing(2.5),
    fontWeight: 600,
  },
  summary: {
    position: 'relative',
    overflow: 'hidden',
    listStyle: 'none',
    cursor: 'pointer',

    '&::-webkit-details-marker': {
      display: 'none',
      color: 'white',
      transform: 'scale(0)',
    },

    '&:before': {
      width: '0px',
      height: '0px',
      content: `" "`,
      border: `solid ${theme.palette.border}`,
      borderWidth: '0 3px 3px 0',
      display: 'inline-block',
      padding: '0 5px 5px 0',
      transform: 'rotate(-45deg)',
      verticalAlign: 'middle',
      transition: 'all .1s ease-in-out',
    },
  },
  details: {
    '&[open] > summary:before': {
      transform: 'rotate(45deg) translate(2px, -4px)',
    },
    '& pre': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
    },
  },
}));
