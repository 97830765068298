import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

interface RouteGuardProps extends PropsWithChildren {
  canAccess: boolean | null | undefined; // Support undefined/null in case of asyn checks
  redirectUrl: string;
}

export function RouteGuard({
  canAccess,
  redirectUrl,
  children,
}: RouteGuardProps) {
  const navigate = useNavigate();
  const hasNavigated = useRef(false);

  useEffect(() => {
    if (canAccess === false && !hasNavigated.current) {
      hasNavigated.current = true;
      navigate(redirectUrl, { replace: true });
    }
  }, [canAccess, redirectUrl, navigate]);

  if (canAccess === undefined || canAccess === null) {
    return null;
  }

  return <>{canAccess && children}</>;
}
