import React from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';
import { FilterGroupsContext } from 'plugin-ui-components';
import { useStyles } from '../styles';

const id = 'classification-filter';
export const TargetClassificationFilter = React.memo(
  ({ label = 'Scorecard Classification' }: { label?: string }) => {
    const {
      availableTargetClassifications,
      targetClassificationFilter,
      setTargetClassificationFilter,
    } = React.useContext(FilterGroupsContext);
    const { inputRoot } = useStyles();

    return (
      <FormControl>
        <Autocomplete
          id={id}
          multiple
          options={availableTargetClassifications}
          value={targetClassificationFilter}
          classes={{ inputRoot }}
          onChange={(_: any, value) =>
            setTargetClassificationFilter(value as string[])
          }
          renderInput={params => (
            <TextField
              label={label}
              {...params}
              size="small"
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </FormControl>
    );
  },
);
