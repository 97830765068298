import {
  Table as MaterialTable,
  TableColumn,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import Alert from '@mui/lab/Alert';
import Button from '@mui/material/Button';
import { TrackedLink } from 'plugin-ui-components';
import React, { useEffect, useState } from 'react';
import { DLQApiRef } from '../../api/DLQApi/DLQApi';
import { subscriptionApiRef } from '../../api/SubscriptionsApi';
import { DLQStats } from '../../domain/DLQ';
import { onSubscriptionClick } from '../../utils/tracking';
import { EventView } from './EventView';

const subscriptionClickTracking = onSubscriptionClick();

const getTableColumns = (nakadiUiURL: string): TableColumn<DLQStats>[] => [
  {
    title: 'ID',
    field: 'subscription_id',
    type: 'string',
    render: row => (
      <TrackedLink
        to={`${nakadiUiURL}/#subscriptions/${row.subscription_id}`}
        {...subscriptionClickTracking}
      >
        {' '}
        {row.subscription_id}{' '}
      </TrackedLink>
    ),
    align: 'left',
  },
  {
    title: 'Event Type(s)',
    field: 'event_type',
    type: 'string',
    align: 'right',
  },
  {
    title: 'Unconsumed Events',
    field: 'unconsumed_events',
    type: 'numeric',
    align: 'right',
  },
  {
    title: 'Consumer Lag',
    field: 'consumer_lag_seconds',
    type: 'numeric',
    align: 'right',
  },
  {
    title: 'Owned',
    field: 'owned',
    type: 'boolean',
    align: 'right',
  },
  {
    title: 'Consuming',
    field: 'consuming',
    type: 'boolean',
    align: 'right',
  },
  {
    title: 'DLQ events count',
    field: 'events_count',
    type: 'numeric',
    align: 'right',
  },
];

export function DLQList({
  application,
  nakadiUiURL,
}: {
  application: string;
  nakadiUiURL: string;
}) {
  const dlqApi = useApi(DLQApiRef);
  const subscriptionsApi = useApi(subscriptionApiRef);
  const [dlqStats, setDlqStats] = useState<DLQStats[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [columns, setColumns] = useState<TableColumn<DLQStats>[]>([]);

  function loadDLQStats() {
    subscriptionsApi
      .getSubscriptions(application)
      .then(subscriptions => {
        setError('');
        return dlqApi.getDLQStats(subscriptions, application);
      })
      .then(setDlqStats)
      .catch(err => {
        setError(err.toString());
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    loadDLQStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setColumns(getTableColumns(nakadiUiURL));
  }, [nakadiUiURL]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <MaterialTable
      title="Subscriptions"
      columns={columns}
      data={dlqStats}
      detailPanel={row => (
        <EventView
          subscription_id={row.rowData.subscription_id}
          nakadiUiURL={nakadiUiURL}
        />
      )}
      isLoading={isLoading}
      options={{
        search: false,
        sorting: true,
        paging: dlqStats.length >= 5,
        grouping: false,
        draggable: false,
        actionsColumnIndex: -1,
      }}
      actions={[
        {
          icon: () => <Button variant="contained">Redrive</Button>,
          tooltip: 'Redrive all failed events',
          onClick: (_, stats) => {
            setIsLoading(true);
            setError('');
            const subscription_id = (stats as DLQStats).subscription_id;
            dlqApi
              .getDLQEvents(subscription_id)
              .then(events => {
                if (events.length === 0) {
                  return;
                }

                dlqApi
                  .redriveDLQEvents(subscription_id, events)
                  .then(() => {
                    loadDLQStats();
                  })
                  .catch(err => {
                    setError(err.toString());
                  });
              })
              .catch(err => {
                setError(err.toString());
              })
              .finally(() => {
                setIsLoading(false);
              });
          },
        },
      ]}
    />
  );
}
