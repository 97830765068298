import type { ComponentType, SVGProps } from 'react';
import type { CompoundEntityRef, Entity } from '@backstage/catalog-model';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import { isOwnerOf } from 'plugin-core';

interface Props {
  userTeams: IEntityGroup[];
  isStarredEntity: (
    entityOrRef: Entity | CompoundEntityRef | string,
  ) => boolean;
  /** The label to use in the personal "mine" group filter (e.g. "My Applications") */
  myLabel: string;
  /**
   * A custom filter function to use in the personal "mine" group filter.
   * The default uses the `isOwnerOf` function from `@backstage/core-plugin-api`
   * with the list of teams provided by `userTeams`.
   * @param entity
   */
  myFilterFn?: (entity: Entity) => boolean;
}

export function getCatalogFilterGroups({
  userTeams,
  isStarredEntity,
  myLabel,
  myFilterFn,
}: Props): ICatalog.ButtonGroup[] {
  function defaultFilterFn(entity: Entity) {
    return !!userTeams && isOwnerOf(entity, userTeams);
  }

  return [
    {
      name: 'Personal',
      items: [
        {
          id: 'mine',
          label: myLabel,
          icon: SettingsIcon as ComponentType<SVGProps<SVGSVGElement>>,
          filterFn: myFilterFn ?? defaultFilterFn,
        },
        {
          id: 'starred',
          label: 'Starred',
          icon: StarIcon as ComponentType<SVGProps<SVGSVGElement>>,
          filterFn: isStarredEntity,
        },
      ],
    },
    {
      name: 'Zalando',
      items: [
        {
          id: 'all',
          label: 'All',
          filterFn: () => true,
        },
      ],
    },
  ];
}
