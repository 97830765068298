import React, { type CSSProperties, type ReactNode } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import { flexRender, type Table } from '@tanstack/react-table';
import Typography from '@mui/material/Typography';
import MTableBody from '@mui/material/TableBody';
import { isPrimitive } from './utils/general.utils';
import { TreeExpandIcon } from './TreeExpandIcon';
import { DEFAULT_EMPTY_TEXT } from './constants';
import Skeleton from '@mui/lab/Skeleton';
import { TableColumn } from './types';

interface Props<T> {
  table: Table<T>;
  isTree?: boolean;
  loading?: boolean;
  emptyText?: ReactNode;
}

export function TableBody<T>({
  table,
  isTree,
  loading,
  emptyText = DEFAULT_EMPTY_TEXT,
}: Props<T>) {
  const isEmpty = table.getRowModel().rows.length === 0;

  return (
    <MTableBody>
      {!loading &&
        table.getRowModel().rows.map(row => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map(cell => {
                const columnDef = cell.column.columnDef as TableColumn<T>;
                if (columnDef.hidden) return null;
                const isDense = !!columnDef.dense;
                let placeSelf: CSSProperties['placeSelf'] | undefined;
                if (columnDef.align === 'center') placeSelf = 'center';
                if (columnDef.align === 'end') placeSelf = 'end';

                return (
                  <TableCell
                    key={cell.id}
                    sx={{
                      px: 2.5,
                      py: 2,
                      minWidth: isDense ? 0 : cell.column.getSize(),
                      whiteSpace: isDense ? 'nowrap' : 'normal',
                      textAlign:
                        columnDef.align === 'center' ? 'center' : undefined,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        placeSelf,
                      }}
                    >
                      <TreeExpandIcon
                        row={row}
                        hidden={
                          !isTree ||
                          cell.column.id !== table.getAllColumns()[0].id
                        }
                      />

                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}

      {loading &&
        Array(5)
          .fill(1)
          .map((_, i) => (
            <TableRow key={i}>
              {table
                .getAllColumns()
                .filter(c => !(c.columnDef as TableColumn<T>).hidden)
                .map(column => (
                  <TableCell key={column.id}>
                    <Skeleton variant="rectangular" height={20} />
                  </TableCell>
                ))}
            </TableRow>
          ))}

      {isEmpty && !loading && (
        <TableRow key="empty">
          <TableCell colSpan={table.getAllColumns().length}>
            {isPrimitive(emptyText) ? (
              <Typography sx={{ textAlign: 'center' }}>{emptyText}</Typography>
            ) : (
              emptyText
            )}
          </TableCell>
        </TableRow>
      )}
    </MTableBody>
  );
}
