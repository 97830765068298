import { useEffect, useMemo, useRef, useState } from 'react';
import { getDefaultTableState } from '../utils/state.utils';
import { handleNestedExpansion } from '../utils/tree.utils';
import type { TableOptions, Updater } from '@tanstack/react-table';
import type { TableColumn, TableState } from '../types';
import type { PaginationOptions } from '../types/pagination';
import type { TableGroupFilter } from '../types/filtering';

export interface UseTableStateProps<T> {
  columns: TableColumn<T>[];
  groupFilters?: TableGroupFilter<T>[];
  pagination?: boolean | PaginationOptions;
  enableSearchParamsSync?: boolean;
}
interface Output<T> {
  state: TableState;
  setters: Required<ISetter<T>>;
}
interface ISetter<T>
  extends Pick<
    TableOptions<T>,
    | 'onExpandedChange'
    | 'onColumnFiltersChange'
    | 'onGlobalFilterChange'
    | 'onSortingChange'
    | 'onPaginationChange'
  > {}

export function useTableState<T>(props: UseTableStateProps<T>): Output<T> {
  const [state, setState] = useState<TableState>(getDefaultTableState(props));
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.columns.length) {
      setState(getDefaultTableState(props));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns]);

  useEffect(() => {
    if (props.groupFilters) {
      setState(getDefaultTableState(props));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.groupFilters]);

  return useMemo((): Output<T> => {
    function stateUpdater<K>(key: keyof TableState) {
      return (updater: Updater<K>) => {
        if (!isMounted.current) return;
        setState(prev => {
          let newValue =
            updater instanceof Function ? updater(prev[key] as any) : updater;
          if (key === 'columnFilters' && Array.isArray(newValue)) {
            newValue = (newValue as TableState['columnFilters']).filter(
              f => f.value !== null,
            ) as K;
          }
          return { ...prev, [key]: newValue };
        });
      };
    }

    return {
      state,
      setters: {
        onGlobalFilterChange: n => {
          if (n instanceof Function) {
            const newState = n(state.globalFilter);
            setState(p => ({ ...p, globalFilter: newState }));
          } else {
            setState(p => ({ ...p, globalFilter: n }));
          }
        },
        onSortingChange: stateUpdater('sorting'),
        onPaginationChange: stateUpdater('pagination'),
        onColumnFiltersChange: stateUpdater('columnFilters'),
        onExpandedChange: newState =>
          setState(p => ({
            ...p,
            expanded: handleNestedExpansion(newState, p.expanded),
          })),
      },
    };
  }, [state]);
}
