import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { RunStatusEnum } from '../../api';

interface StatusFilterProps {
  value?: RunStatusEnum;
  onChange: (value: any) => void;
}

export const StatusFilter = (props: StatusFilterProps) => {
  const defaultValue = 'ALL';
  const { value, onChange } = props;

  return (
    <Select
      value={value || defaultValue}
      variant="outlined"
      margin="dense"
      fullWidth
      onChange={ev =>
        onChange(ev.target.value === defaultValue ? '' : ev.target.value)
      }
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      }}
    >
      <MenuItem value={defaultValue}>{defaultValue}</MenuItem>
      <MenuItem value={RunStatusEnum.Succeeded}>
        {RunStatusEnum.Succeeded}
      </MenuItem>
      <MenuItem value={RunStatusEnum.Running}>{RunStatusEnum.Running}</MenuItem>
      <MenuItem value={RunStatusEnum.Initialized}>
        {RunStatusEnum.Initialized}
      </MenuItem>
      <MenuItem value={RunStatusEnum.Failed}>{RunStatusEnum.Failed}</MenuItem>
    </Select>
  );
};
