import React from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import type { GroupedTab } from './types';
import { TabButton } from './TabButton';
import { useRoutes } from 'react-router-dom';
import Container from '@mui/material/Container';
import { useParams } from 'react-router';

interface Props extends BoxProps {
  tabs: GroupedTab[];
}

export function GroupedTabs({ tabs, ...props }: Props) {
  const element = useRoutes(tabs);
  const params = useParams();

  const path = params['*'];
  const isGroup = tabs.some(tab => {
    if ('children' in tab) {
      return tab.children.some(child => child.path === `/${path}`);
    }
    return false;
  });

  return (
    <Box {...props}>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-start',
          mb: 2,
          height: isGroup ? 90 : undefined,
          backgroundColor: 'background.paper',
          px: 3,
        }}
      >
        {tabs.map((tab, i) => {
          return <TabButton key={i} tab={tab} />;
        })}
      </Box>
      <Container maxWidth="xl" sx={{ mb: 4 }}>
        {element}
      </Container>
    </Box>
  );
}
