import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { getLinearCompletionStyles } from '../../styles';
import { HStack, PIE_CHART_SIZE_MAP, PieChart } from 'plugin-ui-components';
import { getColor } from '../../../utils';
import useTheme from '@mui/styles/useTheme';

interface CustomLinearProgressProps {
  color: 'success' | 'warning' | 'error';
  value: number;
}

function CustomLinearProgress({ color, value }: CustomLinearProgressProps) {
  const Progress = withStyles(theme => getLinearCompletionStyles(theme, color))(
    LinearProgress,
  );
  return (
    <Progress
      variant="determinate"
      value={value}
      style={{
        borderRadius: 5,
        flex: 1,
      }}
    />
  );
}

interface LinearScorecardCompletionProps {
  completion: number;
  type: 'linear';
  size?: 'small' | 'medium' | 'large';
}

interface CircularScorecardCompletionProps {
  completion: {
    passing: number;
    failing: number;
    skipped?: number;
    percentage: number;
  };
  type: 'circular';
  size?: 'small' | 'medium' | 'large';
}

type ScorecardCompletionProps =
  | LinearScorecardCompletionProps
  | CircularScorecardCompletionProps;

export function ScorecardCompletion({
  completion,
  type,
  size,
}: ScorecardCompletionProps) {
  const theme = useTheme();

  const getPieChartSize = () => {
    if (size) return PIE_CHART_SIZE_MAP[size];
    return PIE_CHART_SIZE_MAP.medium;
  };

  const pieChartSize = getPieChartSize();

  return (
    <>
      {type === 'circular' && (
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value: completion.passing,
                  label: 'Succeeded',
                  color: theme.palette.success.main,
                },
                {
                  id: 1,
                  value: completion.failing,
                  label: 'Failed',
                  color: theme.palette.error.main,
                },
                {
                  id: 2,
                  value: completion.skipped || 0,
                  label: 'Skipped',
                  color: theme.palette.grey[500],
                },
              ],
              innerRadius: pieChartSize.innerRadius,
              outerRadius: pieChartSize.outerRadius,
              cx: pieChartSize.width / 2 - 10,
            },
          ]}
          height={pieChartSize.height}
          width={pieChartSize.width}
          slotProps={{
            legend: { hidden: true },
          }}
          displayValue={`${completion.percentage}%`}
          labelProps={{
            fontSize: pieChartSize.fontSize,
            transform: `translate(${pieChartSize.labelX}, ${pieChartSize.labelY})`,
          }}
        />
      )}
      {type === 'linear' && (
        <HStack alignItems="center" flex={1} spacing=".5rem">
          <Box width="100%" mr={1}>
            <CustomLinearProgress
              color={getColor(completion)}
              value={completion}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${
              completion || 0
            }%`}</Typography>
          </Box>
        </HStack>
      )}
    </>
  );
}
