import React, { KeyboardEvent, MouseEvent, RefObject } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Text } from '../../../../../../common';
import { LogsFoldModel, LogsFoldStatusEnum } from '../../../../../../models';
import { Alert, Timer } from '../../../../../common';
import {
  durationHumanized,
  nanoTimestampToDateString,
} from '../../../../../../utils/time';
import { ErrorMessage } from '../Messages';
import { LogsLines } from '../Lines';
import 'style-loader!css-loader!sass-loader!./fold.scss';
import { useServices } from '../../../../../../services';

type Props = {
  index: number;
  fold: LogsFoldModel;
  onClick: (fold: LogsFoldModel) => void;
  scrollableRef: RefObject<HTMLElement>;
};

export const LogsFold = observer(
  ({ index, fold, onClick, scrollableRef }: Props) => {
    const { runService } = useServices();
    const summaryAction = (
      event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>,
    ) => {
      event.preventDefault();
      onClick(fold);
    };
    return (
      <details open={fold.isOpen} key={`fold-${index}`}>
        <summary
          role="button"
          tabIndex={0}
          onKeyDown={summaryAction}
          onClick={summaryAction}
        >
          <div>
            {fold.linesState.isLoading ? (
              <CircularProgress className="fold-loader" size={16} />
            ) : (
              <ArrowForwardIosIcon className="icon arrow" />
            )}
            <Text display="flex" pr={2} ellipsis fullWidth alignItems="center">
              {fold.status === LogsFoldStatusEnum.Finished && (
                <CheckCircleIcon className="icon" />
              )}
              {fold.status === LogsFoldStatusEnum.InProgress && (
                <CircularProgress className="fold-in-progress" size={16} />
              )}
              {fold.name}
            </Text>
            {fold.status === LogsFoldStatusEnum.Finished ? (
              <>
                Ran for:{' '}
                {durationHumanized(
                  nanoTimestampToDateString(fold.firstEventTimestamp),
                  nanoTimestampToDateString(fold.lastEventTimestamp),
                )}
              </>
            ) : (
              <Timer
                status={runService.step.status}
                startedAt={nanoTimestampToDateString(fold.firstEventTimestamp)}
              />
            )}
          </div>
        </summary>

        {fold.linesState.isUnrecoverable && (
          <ErrorMessage message={fold.linesState.message} />
        )}

        {fold.linesState.isRecoverable && (
          <ErrorMessage message="Unable to fetch log lines due to a client side connectivity issue." />
        )}

        {fold.isOpen && (
          <>
            <LogsLines lines={fold.lines} scrollableRef={scrollableRef} />

            {!!fold.warning && (
              <Alert severity="warning">
                Supported limit of 20k lines was reached, download the log file
                to view all lines.
              </Alert>
            )}
          </>
        )}
      </details>
    );
  },
);
