import React from 'react';
import styled from '@mui/styles/styled';

import { InfoCard, List, ListItem, Emoji, fontSize } from '../../../common';
import { DefaultTheme } from '@mui/system';

function colorBySeverity(severity: string, theme: DefaultTheme) {
  switch (severity) {
    case 'info':
      return theme.palette.common.black;
    case 'error':
      return theme.palette.common.black;
    case 'warning':
      return theme.palette.common.black;
    default:
      return theme.palette.text.primary;
  }
}

const Text = styled('pre')<Theme, { 'data-severity'?: string }>(props => ({
  fontSize: fontSize.default,
  fontFamily: 'inherit',
  margin: 'unset',
  background: 'unset',
  border: 'unset',
  borderRadius: 'unset',
  padding: 'unset',
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  color: colorBySeverity(props['data-severity'] || 'default', props.theme),
}));

const Title = styled('span')<Theme, { 'data-severity'?: string }>(props => ({
  color: colorBySeverity(props['data-severity'] || 'default', props.theme),
}));

export function Error({ error }: { error: string }) {
  return (
    <InfoCard
      severity="error"
      title={
        <Title data-severity="error">
          <Emoji name="error" emoji="❌" /> Error
        </Title>
      }
    >
      {error && <Text data-severity="error">{error}</Text>}
    </InfoCard>
  );
}

export function Warnings({ warnings }: { warnings: string[] }) {
  return (
    <InfoCard
      title={
        <span>
          <Emoji name="warning" emoji="⚠️" /> {`Warnings (${warnings.length})`}
        </span>
      }
    >
      <LinesList lines={warnings} />
    </InfoCard>
  );
}

export function StatusLog({ statusLog }: { statusLog: string[] }) {
  return (
    <InfoCard title={`📃 Status log (${statusLog.length})`}>
      <LinesList lines={statusLog} />
    </InfoCard>
  );
}

export function UserErrors({ userErrors }: { userErrors: string[] }) {
  return (
    <InfoCard
      title={
        <span>
          <Emoji name="error" emoji="❌" /> Error Events ({userErrors.length})
        </span>
      }
    >
      <LinesList lines={userErrors} />
    </InfoCard>
  );
}

export function InternalErrors({
  internalErrors,
}: {
  internalErrors: string[];
}) {
  return (
    <InfoCard
      title={
        <span>
          <Emoji name="error" emoji="❌" /> Internal Errors (
          {internalErrors.length})
        </span>
      }
    >
      <LinesList lines={internalErrors} />
    </InfoCard>
  );
}

function LinesList({ lines }: { lines: Array<string> }) {
  if (lines.length === 0) {
    return <></>;
  }

  return (
    <List>
      {lines.map((line, i) => (
        <ListItem key={i}>
          <Text>{line}</Text>
        </ListItem>
      ))}
    </List>
  );
}
