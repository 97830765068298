import type { FilterFn } from '@tanstack/react-table';
import type { DateRange } from 'rsuite/DateRangePicker';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import type { CustomFilterFns, TableFilterOption } from '../types/filtering';

dayjs.extend(isBetween);

function isFilterValueEmpty(value: any): boolean {
  return (
    [null, undefined, ''].includes(value) ||
    (Array.isArray(value) && value.length === 0)
  );
}

export const filterFns: Record<CustomFilterFns, FilterFn<any>> = {
  boolean: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = String(row.getValue(columnId));
    const filterValue = `${selection[0]?.value ?? ''}`;
    return columnValue === filterValue;
  },
  exists: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = row.getValue(columnId);
    const filterValue = selection[0]?.value;
    if (filterValue === true) return !!columnValue;
    if (filterValue === false) return !columnValue;
    return true;
  },
  arrEqualsSome: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = row.getValue(columnId);
    return selection.some(v => {
      if (Array.isArray(columnValue)) {
        return columnValue.includes(v.value);
      }
      return v.value === columnValue;
    });
  },
  arrEqualsAll: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = row.getValue(columnId);
    return selection.every(v => {
      if (Array.isArray(columnValue)) {
        return columnValue.includes(v.value);
      }
      return v.value === columnValue;
    });
  },
  equals: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = row.getValue(columnId);
    const filterValue = selection[0]?.value;
    return columnValue === filterValue;
  },
  includes: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = String(row.getValue(columnId) ?? '').toLowerCase();
    const filterValue = String(selection[0]?.value ?? '').toLowerCase();
    return columnValue.includes(filterValue);
  },
  includesSensitive: (row, columnId, selection: TableFilterOption[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = String(row.getValue(columnId) ?? '');
    const filterValue = String(selection[0]?.value ?? '');
    return columnValue.includes(filterValue);
  },
  dateEquals: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = dayjs(row.getValue(columnId)).startOf('day');
    const filterValue = selection[0]?.value?.[0];
    return columnValue.isSame(filterValue, 'day');
  },
  dateTimeEquals: (
    row,
    columnId,
    selection: TableFilterOption<DateRange>[],
  ) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = dayjs(row.getValue(columnId));
    const filterValue = selection[0]?.value?.[0];
    return columnValue.isSame(filterValue, 'minute');
  },
  monthEquals: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = dayjs(row.getValue(columnId)).startOf('month');
    const filterValue = selection[0]?.value?.[0];
    return columnValue.isSame(filterValue, 'month');
  },
  timeEquals: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const columnValue = dayjs(row.getValue(columnId));
    const filterValue = selection[0]?.value?.[0];
    return columnValue.isSame(filterValue, 'minute');
  },
  dateBetween: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const [from, to] = selection[0]?.value ?? [];
    return dayjs(row.getValue(columnId)).isBetween(from, to, 'day', '[]');
  },
  dateTimeBetween: (
    row,
    columnId,
    selection: TableFilterOption<DateRange>[],
  ) => {
    if (isFilterValueEmpty(selection)) return true;
    const [from, to] = selection[0]?.value ?? [];
    return dayjs(row.getValue(columnId)).isBetween(from, to, 'minute', '[]');
  },
  monthBetween: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const [from, to] = selection[0]?.value ?? [];
    return dayjs(row.getValue(columnId)).isBetween(from, to, 'month', '[]');
  },
  timeBetween: (row, columnId, selection: TableFilterOption<DateRange>[]) => {
    if (isFilterValueEmpty(selection)) return true;
    const [from, to] = selection[0]?.value ?? [];
    return dayjs(row.getValue(columnId)).isBetween(from, to, 'minute', '[]');
  },
};
