import React from 'react';

import AdjustIcon from '@mui/icons-material/Adjust';
import TodayIcon from '@mui/icons-material/Today';
import LaptopIcon from '@mui/icons-material/Laptop';
import TrafficIcon from '@mui/icons-material/Traffic';
import HotelIcon from '@mui/icons-material/Hotel';
import StorageIcon from '@mui/icons-material/Storage';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { UserReference } from 'plugin-ui-components';

import { UserIcon } from '../../../../common';
import { getFormattedDateTime } from '../../../../utils/time';
import { getIconByStepType } from '../../../../utils/pipeline';
import { STEP_STATUS, STEP_TYPE } from '../../../../constants';
import { RunStepModel } from '../../../../models';
import Link from '@mui/material/Link';

export function getStepConfig(step: RunStepModel) {
  const common = [
    {
      icon: getIconByStepType(step.type),
      value: step.type,
      text: `Type: ${step.type}`,
    },
    {
      icon: <CloudUploadIcon />,
      value: step.human_approval_timeout,
      text: `Approval timeout: ${step.human_approval_timeout}`,
    },
  ];

  const deployment = {
    icon: <CloudUploadIcon />,
    value: step.id,
    text: `Deployment: ${step.id}`,
  };

  const cluster = {
    icon: <StorageIcon />,
    value: step.target_cluster,
    text: `Target: ${step.target_cluster}`,
  };

  const associatedStepLink = {
    icon: <AdjustIcon />,
    value: step.associated_step.id,
    text: (
      <>
        Associated step:&nbsp;
        <Link href={`../steps/${step.associated_step.ordinal}`}>
          {step.associated_step.id}
        </Link>
      </>
    ),
  };

  const trafficData = [
    deployment,
    cluster,
    {
      icon: <TrafficIcon />,
      value: step.target_percentage,
      text: `Target traffic: ${step.target_percentage}%`,
    },
    {
      icon: <AdjustIcon />,
      value: step.stackset,
      text: `Stackset: ${step.stackset}`,
    },
    {
      icon: <AdjustIcon />,
      value: step.stack_version,
      text: `Stack version: ${step.stack_version}`,
    },
  ];

  switch (step.type) {
    case STEP_TYPE.SCRIPT:
      return [
        ...common,
        {
          icon: <LaptopIcon />,
          value: step.vm,
          text: `VM: ${step.vm}`,
        },
      ];
    case STEP_TYPE.PROCESS:
    case STEP_TYPE.DEPLOY:
      return [...common, deployment, cluster, associatedStepLink];
    case STEP_TYPE.TRAFFIC:
      return [...common, ...trafficData];
    case STEP_TYPE.SLEEP:
      return [
        ...common,
        {
          icon: <HotelIcon />,
          value: step.run.sleep_until,
          text: `Sleep until: ${getFormattedDateTime(step.run.sleep_until)}`,
        },
      ];
    default:
      return common;
  }
}

export function getStepRunDetails(step: RunStepModel) {
  return [
    {
      icon: <AdjustIcon />,
      value: step.run.id,
      text: `Execution ID: ${step.run.id}`,
    },
    {
      icon: <AdjustIcon />,
      value: step.run.status,
      text: `Status: ${step.run.status} ${
        step.traffic_paused ? '(paused)' : ''
      }`,
    },
    {
      icon: <UserIcon />,
      value: step.accountable_user,
      text: (
        <>
          Accountable user:&nbsp;
          <UserReference user={step.accountable_user} displayType="login" />
        </>
      ),
    },
    {
      icon: <TodayIcon />,
      value: step.run.created_at,
      text: `Started at: ${getFormattedDateTime(step.run.created_at)}`,
    },
    {
      icon: <TodayIcon />,
      value: step.run.finished_at,
      text: `Finished at: ${getFormattedDateTime(step.run.finished_at)}`,
    },
  ];
}

export function getStepExecutionReason(status: string): string {
  switch (status) {
    case STEP_STATUS.VALIDATING:
      return 'Step is being validated; only steps that pass validation can be executed..';
    case STEP_STATUS.PENDING_APPROVAL:
      return 'Step is awaiting human approval.';
    case STEP_STATUS.NOT_STARTED:
    case STEP_STATUS.INIT:
      return "Step didn't start yet.";
    case STEP_STATUS.FAILED:
    case STEP_STATUS.ABORTING:
    case STEP_STATUS.ABORTED:
    case STEP_STATUS.ERROR:
    case STEP_STATUS.TIMEOUT:
      return 'Step failed before execution started.';
    case STEP_STATUS.SKIPPED:
      return 'Step has been skipped.';
    default:
      return 'Unknown';
  }
}
