import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { FilterGroupsContext } from 'plugin-ui-components';
import React from 'react';
import { SECURITY_TIERS } from '../../constants';
import { useStyles } from '../styles';

const options = SECURITY_TIERS.map(tier => ({
  label: `Tier ${tier}`,
  value: tier,
}));
const label = 'Security Tier';
const id = 'security-tier-filter';

export const SecurityTierFilter = React.memo(() => {
  const { securityTierFilter, setSecurityTierFilter } =
    React.useContext(FilterGroupsContext);
  const { inputRoot } = useStyles();

  return (
    <FormControl size="small" margin="none">
      <Autocomplete
        id={id}
        multiple
        options={options}
        value={options.filter(opt =>
          securityTierFilter.some(f => Number(f) === opt.value),
        )}
        classes={{ inputRoot }}
        getOptionLabel={option => option?.label || ''}
        onChange={(_: any, opts) => {
          setSecurityTierFilter(opts.map(o => o.value));
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            label={label}
            {...params}
            variant="outlined"
            margin="dense"
            size="small"
          />
        )}
      />
    </FormControl>
  );
});
