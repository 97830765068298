export default `
query Models(
  $account_id: String, 
  $application_id: String, 
  $limit: Int, 
  $offset: Int, 
  $name: String, 
  $license_type: String,
  $tag_name: String, 
  $include: [String!],
  $origin: String,
  $vendor: String
  $team_id: String
  $compliant: Boolean
  $archived: Boolean
) {
  meta {
    application_team_map
    deployment_environments {
      deployment_environment_id
      account_id
      type
      production_status
      name
    }
    vendors
  }
  models(
    application_id: $application_id,
    account_id: $account_id,
    name: $name,
    tag_name: $tag_name,
    limit: $limit,
    offset: $offset,
    license_type: $license_type,
    origin: $origin,
    include: $include
    vendor: $vendor
    team_id: $team_id
    compliant: $compliant
    archived: $archived
  ) {
    items {
      ... on ModelSchema {
        name
        model_id
        description
        updated_at
        license_link
        slug
        license_type
        application_id
        archived
        deployment_environment {
          type
          account_id
          name
          production_status
        }
        model_card {
          model_card_id
          references
          citations
          model_architecture
          input_format
          output_format
          use_cases
          limitations
          links
          technical_specification
          risk_classification
          ethical_considerations
          tradeoffs
        }
        origin
        created_at
        vendor
        created_by {
          realm
          uid
        }
        tags {
          name
          value
        }
        updated_by {
          realm
          uid
        }
      }
    }
    total {
      all
      query
    }
  }
}
`;
