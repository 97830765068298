import React, { CSSProperties, PropsWithChildren } from 'react';
import cn from 'classnames';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import type { Column, Table } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import type { TableColumn } from './types';

interface Props<T> extends PropsWithChildren {
  table: Table<T>;
  column: Column<T, any>;
  textAlign?: CSSProperties['textAlign'];
}

export function SortableHead<T>({
  table,
  column,
  children,
  textAlign,
}: Props<T>) {
  const columnDef = column.columnDef as TableColumn<T>;
  const sorting = table.getState().sorting;
  const whiteSpace: CSSProperties['whiteSpace'] =
    columnDef.whiteSpace ?? 'nowrap';

  if (!column.getCanSort()) {
    return (
      <Box sx={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}>
        {children}
      </Box>
    );
  }

  return (
    <button onClick={column.getToggleSortingHandler()}>
      <Box sx={{ whiteSpace, textAlign }}>{children}</Box>
      <div
        className={cn('sort-icon', {
          sorted: !!column.getIsSorted(),
          desc: !!sorting.find(s => s.id === column.id && s.desc),
        })}
      >
        <ArrowDownwardIcon />
      </div>
    </button>
  );
}
