import React, { RefObject } from 'react';
import { Route, Routes } from 'react-router';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react-lite';

import { FLAT_LOGS, FOLDED_LOGS } from '../../constants';
import { useServices } from '../../services';
import { Details } from './components/Details';
import { StepOverview } from './components/StepOverview';
import { ScalyrLogs, ScalyrFlatLogs } from './components/ScalyrLogs';
import { Artifacts } from './components/Artifacts';
import { stepRoutes } from '../../router';
import { DeploymentStatus } from './components/DeploymentStatus';
import { TestResults } from './components/TestResults';
import { deploymentResourceCssVariables } from '../../styles/css-variables';
import { DeploymentResources } from './components/DeploymentResources';

interface Props {
  scrollableRef: RefObject<HTMLElement>;
}

export const StepDetailsPageComponent = observer(({ scrollableRef }: Props) => {
  const { runService } = useServices();

  return (
    <Routes>
      <Route path="/" element={<StepOverview />}>
        <Route path="/" element={<Details />} />
        <Route
          path={stepRoutes.logs}
          element={
            <>
              {/* eslint-disable-next-line no-nested-ternary */}
              {FOLDED_LOGS.includes(runService.step.type) ? (
                <ScalyrLogs scrollableRef={scrollableRef} />
              ) : // eslint-disable-next-line no-nested-ternary
              FLAT_LOGS.includes(runService.step.type) ? (
                // eslint-disable-next-line no-nested-ternary
                <ScalyrFlatLogs scrollableRef={scrollableRef} />
              ) : null}
            </>
          }
        />
        <Route path={stepRoutes.artifacts} element={<Artifacts />} />
        <Route path={stepRoutes.tests} element={<TestResults />} />
        <Route path={stepRoutes.deployment} element={<DeploymentStatus />} />
        <Route
          path={stepRoutes.deploymentResources}
          element={<DeploymentResources />}
        />
      </Route>
    </Routes>
  );
});

export const StepDetailsPage = withStyles(deploymentResourceCssVariables)(
  StepDetailsPageComponent,
);
