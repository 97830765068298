import React, { CSSProperties } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { flexRender, type Table } from '@tanstack/react-table';
import MTableHead from '@mui/material/TableHead';
import { SortableHead } from './SortableHead';
import type { TableColumn } from './types';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import cn from 'classnames';

interface Props<T> {
  table: Table<T>;
}

export function TableHead<T>({ table }: Props<T>) {
  const headerGroups = table.getHeaderGroups();

  return (
    <MTableHead
      sx={{
        position: 'sticky',
        top: 0,
        bgcolor: 'background.paper',
        zIndex: 1,
      }}
    >
      {headerGroups.map(headerGroup => (
        <TableRow
          key={headerGroup.id}
          className={cn({
            'header-group':
              headerGroups.length > 1 &&
              headerGroup.depth < headerGroups.length - 1,
          })}
        >
          {headerGroup.headers.map(header => {
            const columnDef = header.column.columnDef as TableColumn<T>;
            if (columnDef.hidden) return null;
            const content = flexRender(
              header.column.columnDef.header,
              header.getContext(),
            );
            let textAlign: CSSProperties['textAlign'] | undefined;
            if (columnDef.align === 'center') textAlign = 'center';
            if (columnDef.align === 'end') textAlign = 'end';

            return (
              <TableCell
                key={header.id}
                colSpan={header.colSpan}
                sx={{ width: columnDef.dense ? 0 : undefined, textAlign }}
              >
                {header.isPlaceholder ? null : (
                  <SortableHead
                    table={table}
                    column={header.column}
                    textAlign={textAlign}
                  >
                    {columnDef.tooltip ? (
                      <Tooltip title={columnDef.tooltip}>
                        <Box
                          component="span"
                          display="inline-flex"
                          gap={0.5}
                          alignItems="center"
                          whiteSpace="nowrap"
                        >
                          {content} <InfoOutlined sx={{ fontSize: '1.1em' }} />
                        </Box>
                      </Tooltip>
                    ) : (
                      content
                    )}
                  </SortableHead>
                )}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </MTableHead>
  );
}
