import type { CSSProperties } from 'react';

export const smallColumnStyle: CSSProperties = {
  width: '5%',
  maxWidth: '5%',
};
export const mediumColumnStyle: CSSProperties = {
  width: '10%',
  maxWidth: '10%',
  whiteSpace: 'nowrap',
};
