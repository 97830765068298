import React, { type ReactNode, type PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import styled from '@mui/styles/styled';
import { fontSize } from '../Typography';
import { DefaultTheme } from '@mui/system';

type Props = PropsWithChildren<{
  title: ReactNode;
  subtitle?: ReactNode;
  severity?: 'error' | 'info' | 'warning' | 'default';
}>;

function colorBySeverity(severity: string, theme: DefaultTheme) {
  switch (severity) {
    case 'info':
      return '#e5f6fd';
    case 'error':
      return '#ffe0dc';
    case 'warning':
      return '#ffeed8';
    default:
      return theme.palette.background.default;
  }
}

const Card = styled('article')<
  Theme,
  { 'data-active': boolean; 'data-severity': string }
>(props => ({
  padding: props.theme.spacing(2),
  borderRadius: '4px',
  backgroundColor: colorBySeverity(props['data-severity'], props.theme),
  opacity: props['data-active'] ? '1' : '0.5',

  ['&:not(:first-child)']: {
    marginTop: props.theme.spacing(2),
  },

  '& > main': {
    breakInside: 'avoid',
  },
}));

export function InfoCard({
  title,
  subtitle,
  children,
  severity = 'default',
}: Props) {
  return (
    <Card data-active={Boolean(children)} data-severity={severity}>
      <Box fontWeight={600} fontSize={fontSize.medium} component="header">
        {title}
      </Box>

      {subtitle && (
        <Box fontWeight={400} fontSize={fontSize.default} component="p">
          {subtitle}
        </Box>
      )}

      {children && (
        <Box paddingTop={2} fontSize={fontSize.default} component="main">
          {children}
        </Box>
      )}
    </Card>
  );
}
