import {
  ArrayParam,
  DateTimeParam,
  NumberParam,
  NumericArrayParam,
  type QueryParamConfigMap,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import type { Table } from '@tanstack/react-table';
import { useEffect } from 'react';
import type { TableColumn } from '../types';
import type { TableFilterOption } from '../types/filtering';
import { ArrayDateTimeParam, BooleanParam } from '../utils/query.utils';

function getQueryParamType<T>(table: Table<T>, enabled?: boolean) {
  // Skip if disabled
  const config: QueryParamConfigMap = {
    search: StringParam,
    group: StringParam,
  };
  if (!enabled) return config;
  table.getAllColumns().forEach(c => {
    const { id, filtering } = c.columnDef as TableColumn<T>;
    if (id && filtering) {
      switch (filtering.filterType) {
        case 'month':
        case 'time':
        case 'date':
        case 'date-time':
          config[id] = DateTimeParam;
          break;
        case 'date-range':
        case 'date-time-range':
        case 'month-range':
        case 'time-range':
          config[id] = ArrayDateTimeParam;
          break;
        default: {
          const filterOptions = filtering.options as TableFilterOption[];
          const firstOption = filterOptions?.[0];
          const valueType = typeof firstOption?.value;
          switch (valueType) {
            case 'number':
              config[id] = filtering.multiSelect
                ? NumericArrayParam
                : NumberParam;
              break;
            case 'boolean':
              config[id] = BooleanParam;
              break;
            default:
              // Default to string for unknown types
              config[id] = filtering.multiSelect ? ArrayParam : StringParam;
          }
        }
      }
    }
  });
  return config;
}

type QueryParamsState = Partial<{
  search: string;
  group: string;
  [key: string]: string | string[] | undefined;
}>;

/** Sync table state to query params */
export function useQueryParamsSync<T>(table: Table<T>, enabled?: boolean) {
  const [queryParams, setQueryParams] = useQueryParams(
    getQueryParamType(table, enabled),
    { updateType: 'replace' },
  );

  const columnFilters = table.getState().columnFilters;
  const globalFilters = table.getState().globalFilter as string | undefined;

  useEffect(() => {
    if (!enabled) return;
    const newState: QueryParamsState = {};

    // Sync column filters
    columnFilters.forEach(filter => {
      const filterValue = filter.value as TableFilterOption[];
      if (filterValue) {
        if (filterValue[0]?.value?.[0] instanceof Date) {
          newState[filter.id] = filterValue.flatMap(v =>
            v.value.map((d: Date) => d.toISOString()),
          );
        } else {
          newState[filter.id] = filterValue.map(v => v.value);
        }
      }
    });

    // Sync search keywords
    if (globalFilters) newState.search = globalFilters;

    setQueryParams(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, globalFilters, enabled]);

  return queryParams;
}
