import React from 'react';
import { DatePicker } from 'rsuite';
import { flexRender, type Header } from '@tanstack/react-table';
import { useStyles } from '../styles';
import cn from 'classnames';
import type { TableColumn } from '../types';
import type {
  TableFilteringVariants,
  TableFilterOption,
} from '../types/filtering';
import { getFormat } from '../utils/general.utils';
import FormHelperText from '@mui/material/FormHelperText';

type FilterType = TableFilteringVariants['filterType'];
interface Props<T> {
  type: FilterType;
  header: Header<T, unknown>;
  disabled?: boolean;
}

const defaultValue: TableFilterOption[] = [];

export function DateFilter<T>({ type, header, disabled }: Props<T>) {
  const styles = useStyles();
  const columnDef = header.column.columnDef as TableColumn<T>;
  const filtering = columnDef.filtering;

  if (
    !filtering ||
    !(
      (filtering && filtering.filterType === 'date') ||
      filtering.filterType === 'date-time' ||
      filtering.filterType === 'month' ||
      filtering.filterType === 'time'
    )
  ) {
    return null;
  }

  const renderedHeader =
    filtering.label ??
    flexRender(header.column.columnDef.header, header.getContext());

  const [filterValueOption] =
    (header.column.getFilterValue() as TableFilterOption<Date>[]) ??
    defaultValue;
  const filterValue = filterValueOption?.value ?? null;

  return (
    <fieldset
      className={cn(styles.rSuiteDatePicker, type, { active: !!filterValue })}
    >
      <legend>{renderedHeader}</legend>
      <DatePicker
        block
        format={getFormat(type)}
        placeholder=" "
        appearance="subtle"
        value={filterValue}
        onChange={newValue => {
          if (!newValue) {
            header.column.setFilterValue(null);
          } else {
            header.column.setFilterValue([
              { value: newValue, label: type ?? 'select' },
            ] satisfies TableFilterOption<Date>[]);
          }
        }}
        shouldDisableDate={filtering.shouldDisableDate}
        disabled={disabled}
      />
      {columnDef.filtering?.helperText && (
        <FormHelperText>{columnDef.filtering.helperText}</FormHelperText>
      )}
    </fieldset>
  );
}

DateFilter.getInitialSelection = (
  column: TableColumn<any>,
  enableSearchParamsSync?: boolean,
): TableFilterOption[] => {
  const columnId = column.id;
  const filtering = column.filtering;
  if (!filtering || !columnId) return [];
  const searchParams = new URLSearchParams(window.location.search);

  if (enableSearchParamsSync && searchParams.size) {
    switch (filtering.filterType) {
      case 'date':
      case 'date-time':
      case 'month':
      case 'time': {
        const value = searchParams.get(columnId);
        return value ? [{ value: new Date(value), label: value }] : [];
      }
      default:
        return [];
    }
  }

  const { initialSelection } = filtering;
  return initialSelection?.length ? initialSelection : [];
};
