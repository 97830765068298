import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';

/**
 * Set `undefined` or `null` values to empty string recursively
 * @param {object} entity - Object you want to set `undefined` or `null` values to empty string.
 * @returns {object} Passed object with `undefined` or `null` values set to empty string.
 */
const setDefault = (entity: any): any => {
  return mapValues(entity, val => {
    if (isObject(val) && !isArray(val)) {
      return {
        ...val,
        ...setDefault(val),
      };
    }
    return isNil(val) ? '' : val;
  });
};

/**
 * Replace `undefined` or `null` values with empty string.
 * @param {object} value - Object you want to sanitize (set default)
 * @returns {object} Sanitized object
 */
export function replaceNilWithEmptyString<T = any>(value: T): T {
  return setDefault(value);
}
