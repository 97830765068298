import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  BaseLayout,
  ListEntities,
  StatusTableCell,
  ActionsTableCell,
  CopyActionsBar,
  StatusFilter,
} from '../../components';
import { useApi } from '@backstage/core-plugin-api';
import { RunStatusEnum, notebooksApiRef } from '../../api';
import { Content, SubvalueCell } from '@backstage/core-components';
import { constructLinkToDataLabNotebook } from '../../utils';
import {
  SourceNotebookLinkTag,
  TagGroup,
  UserTag,
} from '../../components/Tags';

export const Versions = () => {
  const notebookClient = useApi(notebooksApiRef);
  const { sharedNotebookId = '' } = useParams();
  const [refreshList, setRefreshList] = useState(false);
  const [page, setPage] = useState(0);
  const [statusFilter, setStatusFilter] = useState<RunStatusEnum>();
  const [archiveExecutionError, setArchiveExecutionError] = useState<Error>();
  const [notebookName, setNotebookName] = useState('');

  useEffect(() => {
    const getSharedNotebook = async () => {
      const res = await notebookClient.getSharedNotebookDetails(
        sharedNotebookId,
      );
      setNotebookName(res.name);
    };
    getSharedNotebook();
  }, [sharedNotebookId, notebookClient]);

  const onDelete = async (event: any, id: string) => {
    event?.stopPropagation();
    try {
      await notebookClient.deleteVersion(id, sharedNotebookId);
    } catch (err) {
      setArchiveExecutionError(err as Error);
    }
    setRefreshList(!refreshList);
  };

  const COLUMNS = [
    {
      title: 'Name',
      field: 'name',
      render: (row: any): React.ReactNode => (
        <SubvalueCell
          value={<b>{row.name}</b>}
          subvalue={
            <TagGroup>
              <UserTag user={row.created_by} />
              <SourceNotebookLinkTag
                link={constructLinkToDataLabNotebook(row.notebook_path)}
              />
            </TagGroup>
          }
        />
      ),
    },
    {
      title: 'Commit Message',
      field: 'version_message',
    },
    {
      title: 'Status',
      field: 'row.status',
      filtering: true,
      filterComponent: () => (
        <StatusFilter value={statusFilter} onChange={setStatusFilter} />
      ),
      render: (row: any) => <StatusTableCell status={row?.status} />,
    },
    {
      title: 'Created At',
      field: 'created_at',
    },
    {
      title: 'Actions',
      render: (row: any) => (
        <ActionsTableCell
          parentEntityType="shared_notebooks"
          id={row.shared_notebook_id}
          name={row.name}
          is_interactive={row.is_interactive}
          archived={row.archived}
          childEntityType="versions"
          childId={row.id}
          onDelete={onDelete}
        />
      ),
    },
  ];

  return (
    <BaseLayout notebookName={notebookName}>
      <Content>
        <CopyActionsBar type="versions" id={sharedNotebookId} />
        <ListEntities
          getEntitiesPromise={notebookClient.getVersions(
            sharedNotebookId,
            page,
            statusFilter,
          )}
          entityKey="versions"
          columns={COLUMNS}
          childEntityKey=""
          title="Versions"
          refreshList={refreshList}
          page={page}
          onPageChange={setPage}
          error={archiveExecutionError}
          setError={setArchiveExecutionError}
        />
      </Content>
    </BaseLayout>
  );
};
