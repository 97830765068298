import React, {
  createContext,
  type PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import type { GetEntitiesResponse } from '@backstage/catalog-client';
import type { Entity } from '@backstage/catalog-model';

interface Queue {
  state: 'idle' | 'loading';
  items: Set<string>;
}

interface ContextQueues {
  users: Queue;
  teams: Queue;
}

const EMPTY_QUEUE: Queue = {
  state: 'idle',
  items: new Set(),
};

interface IReferenceContext {
  entities: {
    users: Map<string, Entity>;
    teams: Map<string, Entity>;
  };
  queues: ContextQueues;
  updateQueues: React.Dispatch<React.SetStateAction<ContextQueues>>;
}

export const ReferenceContext = createContext<IReferenceContext>({
  entities: { users: new Map(), teams: new Map() },
  queues: {
    users: { ...EMPTY_QUEUE, items: new Set() },
    teams: { ...EMPTY_QUEUE, items: new Set() },
  },
  updateQueues: () => {},
});

export function useReferenceContext() {
  const batchCalls = React.useRef({ users: 0, teams: 0 });
  const { entities, queues, updateQueues } = useContext(ReferenceContext);

  const getUser = useCallback(
    async (userId: string): Promise<IEntityUser | string | undefined> => {
      const users = entities.users;
      // If user is already fetched, return it
      const userEntity = Array.from(users.values()).find(user => {
        return [
          user.metadata.name,
          (user as IEntityUser).spec?.profile?.email,
        ].includes(userId);
      }) as IEntityUser;

      if (!userEntity) {
        // Make sure users queue is only updated once to avoid multiple requests
        if (batchCalls.current.users === 0) {
          // Add the user to the queue and update the state with a loading state
          queues.users.items.add(userId);
          updateQueues(prev => ({
            ...prev,
            users: {
              ...prev.users,
              state: 'loading',
            },
          }));
          batchCalls.current.users = 1;
        }
        // If we are in idle state, and the user is not found, return undefined
        return queues.users.state === 'idle' ? undefined : userId;
      }
      return userEntity;
    },
    [entities, queues, updateQueues],
  );

  const getTeam = useCallback(
    async (teamId: string): Promise<IEntityGroup | string | undefined> => {
      const teams = entities.teams;
      // If team is already fetched, return it
      const teamEntity = Array.from(teams.values()).find(team => {
        return [
          ...((team.spec?.alias as string[]) || []),
          team.metadata.name,
          team.spec?.fullName,
          team.spec?.id,
        ].includes(teamId);
      }) as IEntityGroup;

      if (!teamEntity) {
        // Make sure teams queue is only updated once to avoid multiple requests
        if (batchCalls.current.teams === 0) {
          queues.teams.items.add(teamId);
          // Add the team to the queue and update the state with a loading state
          updateQueues(prev => ({
            ...prev,
            teams: {
              ...prev.teams,
              state: 'loading',
            },
          }));
          batchCalls.current.teams = 1;
          return teamId;
        }
        // If we are in idle state, and the team is not found, return undefined
        return queues.teams.state === 'idle' ? undefined : teamId;
      }
      return teamEntity;
    },
    [entities, queues, updateQueues],
  );

  return {
    getUser,
    getTeam,
    teamsLoading: queues.teams.state === 'loading',
    usersLoading: queues.users.state === 'loading',
  };
}

/* Wraps the provider with custom logic (handling batches and entities request...) */
export function ReferenceContextProvider(props: PropsWithChildren<{}>) {
  // Holds the entities fetched in bulk from the catalog
  const [entities, setEntities] = React.useState<IReferenceContext['entities']>(
    {
      users: new Map(),
      teams: new Map(),
    },
  );
  const catalogApi = useApi(catalogApiRef);

  /* Queues used to hold users and teams to be fetched */
  const [queues, setQueues] = React.useState<IReferenceContext['queues']>({
    users: { ...EMPTY_QUEUE, items: new Set() },
    teams: { ...EMPTY_QUEUE, items: new Set() },
  });

  /* Entities fetching is moved to provider level so we can get them all in one request */
  const getEntities = useCallback(
    async (type: 'teams' | 'users') => {
      if (type === 'teams') {
        const requests: Array<Promise<GetEntitiesResponse>> = [];
        const { withAliases, withoutAliases } = Array.from(
          queues.teams.items,
        ).reduce(
          (acc, team) => {
            if (team)
              if (isNaN(Number(team))) {
                acc.withAliases.push(team);
              } else {
                acc.withoutAliases.push(team);
              }
            return acc;
          },
          { withAliases: [] as string[], withoutAliases: [] as string[] },
        );

        // Construct request for cases where an alias is passed
        if (withAliases.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'Group',
                  'spec.id': withAliases,
                },
                {
                  kind: 'Group',
                  'spec.fullname': withAliases,
                },
                {
                  kind: 'Group',
                  'spec.alias': withAliases,
                },
              ],
            }),
          );
        }
        // Construct request for cases where SAP ID is passed
        if (withoutAliases.length) {
          requests.push(
            catalogApi.getEntities({
              filter: [
                {
                  kind: 'Group',
                  'metadata.name': withoutAliases,
                },
              ],
            }),
          );
        }
        return await Promise.all(requests);
      }
      const requests: Array<Promise<GetEntitiesResponse>> = [];
      const { byUserId, byEmail } = Array.from(queues.users.items).reduce(
        (acc, user) => {
          if (user)
            if (user.includes('@')) {
              acc.byEmail.push(user);
            } else {
              acc.byUserId.push(user);
            }
          return acc;
        },
        { byUserId: [] as string[], byEmail: [] as string[] },
      );
      // Construct request for cases where a user id is passed
      if (byUserId.length) {
        requests.push(
          catalogApi.getEntities({
            filter: [
              {
                kind: 'user',
                'metadata.name': byUserId,
              },
            ],
          }),
        );
      }
      // Construct request for cases where a user email is passed
      if (byEmail.length) {
        requests.push(
          catalogApi.getEntities({
            filter: [
              {
                kind: 'user',
                'spec.profile.email': byEmail,
              },
            ],
          }),
        );
      }

      return await Promise.all(requests);
    },
    [queues, catalogApi],
  );

  React.useEffect(() => {
    const { users, teams } = queues;

    if (teams.items.size) {
      getEntities('teams').then(data => {
        const items = data.flatMap(item => item.items);
        const arrayMap: [string, Entity][] = items.map(item => [
          item.metadata.name,
          item,
        ]);

        // Update the entities with the newly fetched teams
        setEntities(prev => ({
          ...prev,
          teams: new Map([...prev.teams, ...arrayMap]),
        }));

        // Reset the queue
        setQueues(prev => ({
          ...prev,
          teams: {
            state: 'idle',
            items: new Set(),
          },
        }));
      });
    }
    if (users.items.size) {
      getEntities('users').then(data => {
        const items = data.flatMap(item => item.items);
        const arrayMap: [string, Entity][] = items.map(item => [
          item.metadata.name,
          item,
        ]);
        // Update the entities with the newly fetched users
        setEntities(prev => ({
          ...prev,
          users: new Map([...prev.users, ...arrayMap]),
        }));

        // Reset the queue
        setQueues(prev => ({
          ...prev,
          users: {
            state: 'idle',
            items: new Set(),
          },
        }));
      });
    }
  }, [queues, getEntities]);

  return (
    <ReferenceContext.Provider
      value={{
        entities,
        queues,
        updateQueues: setQueues,
      }}
    >
      {props.children}
    </ReferenceContext.Provider>
  );
}
