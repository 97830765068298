import React from 'react';
import FormControl from '@mui/material/FormControl';
import { flexRender, Header } from '@tanstack/react-table';
import { SxProps } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/lab/Autocomplete';
import type { TableColumn } from '../types';
import type { TableFilterOption } from '../types/filtering';

interface Props<T> {
  header: Header<T, unknown>;
  disabled?: boolean;
}

const defaultValue: TableFilterOption[] = [];
export function SelectFilter<T>({ header, disabled }: Props<T>) {
  const id = `${header.column.id}-filter`;
  const columnDef = header.column.columnDef as TableColumn<T>;
  const filtering = columnDef.filtering;

  if (filtering?.filterType !== 'select') {
    return null;
  }

  const renderedHeader =
    filtering.label ??
    flexRender(header.column.columnDef.header, header.getContext());

  const value =
    (header.column.getFilterValue() as TableFilterOption[]) ?? defaultValue;

  const hasValue = !!value.length;
  const filteringOptions =
    typeof filtering.options === 'function' ? [] : filtering.options;

  return (
    <FormControl>
      <Autocomplete
        id={id}
        multiple={filtering.multiSelect}
        options={filteringOptions ?? []}
        value={(filtering.multiSelect ? value : value[0]) ?? []}
        onChange={(_: any, newValue) => {
          if ((Array.isArray(newValue) && newValue.length === 0) || !newValue) {
            header.column.setFilterValue(null);
          } else {
            header.column.setFilterValue(
              Array.isArray(newValue) ? newValue : [newValue],
            );
          }
        }}
        disabled={disabled}
        renderInput={props => (
          <TextField
            {...props}
            label={renderedHeader}
            helperText={columnDef.filtering?.helperText}
            size="small"
            variant="outlined"
            margin="dense"
            disabled={disabled}
            InputProps={{
              ...props.InputProps,
              inputProps: {
                ...props.inputProps,
                readOnly: !filtering.searchable,
                style: {
                  color: hasValue ? 'var(--primary-color)' : undefined,
                },
              },
            }}
          />
        )}
        filterOptions={(options, state) => {
          return options.filter(option => {
            return [option.label, option.helperText]
              .filter(Boolean)
              .some(text =>
                text?.toLowerCase().includes(state.inputValue.toLowerCase()),
              );
          });
        }}
        ChipProps={{
          variant: 'outlined',
          color: 'primary',
          size: 'small',
        }}
        getOptionLabel={option => option.label ?? ''}
        renderOption={(props, option) => {
          return (
            <Box
              {...props}
              component="li"
              children={option.label}
              data-helper-text={option.helperText}
              key={option.value}
              sx={theme => {
                const helperTextProps: SxProps = !!option.helperText
                  ? {
                      '::after': {
                        ...theme.typography.caption,
                        content: 'attr(data-helper-text)',
                        height: 0,
                        overflow: 'hidden',
                        flexBasis: '100%',
                      },
                      ':hover::after': {
                        height: 'auto',
                      },
                    }
                  : {};

                return {
                  whiteSpace: 'normal',
                  flexWrap: 'wrap',
                  ...helperTextProps,
                };
              }}
            />
          );
        }}
        sx={{
          '& [class*=MuiInputBase-root]': {
            backgroundColor: 'background.paper',
            boxShadow: 2,
          },
        }}
      />
    </FormControl>
  );
}

SelectFilter.getInitialSelection = <T,>(
  column: TableColumn<T>,
  enableSearchParamsSync?: boolean,
): TableFilterOption[] => {
  const columnId = column.id;
  const filtering = column.filtering;
  if (
    !filtering ||
    !columnId ||
    !(filtering.filterType === undefined || filtering.filterType === 'select')
  ) {
    return [];
  }

  const options = filtering.options as TableFilterOption[];
  const multiSelect = filtering.multiSelect;
  const searchParams = new URLSearchParams(window.location.search);

  const getInitialSelection = () => {
    const { initialSelection } = filtering;
    if (!initialSelection?.length) return [];
    if (multiSelect) {
      return options.filter(o =>
        initialSelection.some(s => s.value.toString() === o.value.toString()),
      );
    }
    const selectedOption = options.find(
      o => o.value.toString() === initialSelection[0].value.toString(),
    );

    return selectedOption ? [selectedOption] : [];
  };

  if (enableSearchParamsSync && searchParams.size) {
    if (multiSelect) {
      const paramOptions = searchParams.getAll(columnId);
      if (options?.length) {
        return options.filter(o => paramOptions.includes(o.value.toString()));
      }
      return paramOptions.map(v => ({ value: v, label: v }));
    }
    const paramOption = searchParams.get(columnId)!;
    if (options.length) {
      const selectedOption = options.find(
        o => o.value.toString() === paramOption,
      );
      if (selectedOption) return [selectedOption];
      /** NOTE: Group filter must always have a value selected. */
      return columnId === 'group' ? getInitialSelection() : [];
    }
    if (paramOption) {
      return [{ value: paramOption, label: paramOption }];
    }
    return [];
  }

  return getInitialSelection();
};
