import { STEP_STATUS } from '../../../../constants';

export function getMessageByStatus(scriptStepStatus: string): string {
  switch (scriptStepStatus) {
    case STEP_STATUS.ERROR:
    case STEP_STATUS.FAILED:
      return "Deployment resources weren't generated because the script step failed.";
    case STEP_STATUS.SKIPPED:
    case STEP_STATUS.ABORTED:
      return "Deployment resources weren't generated because the script step was skipped or aborted.";
    case STEP_STATUS.IN_PROGRESS:
      return 'Please wait, script step that creates artifacts is running…';
    default:
      return 'Please wait, script step that creates artifacts is starting…';
  }
}
