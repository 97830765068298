import React, { memo, useState } from 'react';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControl from '@mui/material/FormControl';
import { useDebounce } from 'react-use';
import { SEARCH_DEBOUNCE_TIME } from 'plugin-core';

interface Props {
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
}

export const DebouncedSearch = memo(function Comp({
  value,
  onChange,
  loading,
}: Props) {
  const [search, setSearch] = useState(value);

  useDebounce(
    () => {
      onChange(search);
    },
    SEARCH_DEBOUNCE_TIME,
    [search],
  );

  return (
    <FormControl sx={{ width: { xs: '100%', sm: 'auto' } }}>
      <Input
        type="search"
        id="input-with-icon-adornment"
        placeholder="Filter"
        startAdornment={
          <InputAdornment position="start">
            <FilterListIcon />
          </InputAdornment>
        }
        value={search}
        disabled={loading}
        onChange={e => setSearch(e.currentTarget.value)}
      />
    </FormControl>
  );
});
