import {
  createApiRef,
  DiscoveryApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/catalog-client';
import { ProductivityPerTeam } from '../types';
import {
  getTeamNameFromFullName,
  replaceNilWithEmptyString,
  throwReadableError,
} from 'plugin-core';

const DEPLOYMENT_FREQUENCY = '/deployment-frequencies/v1/latest';

export type ProductivityApiType = {
  getProductivityRows(teams: IEntityGroup[]): Promise<ProductivityPerTeam>;
};

type TeamStatMap = Map<string, number>;

export const productivityApiRef = createApiRef<ProductivityApiType>({
  id: 'plugin.start-page.productivity',
});

export class ProductivityApi implements ProductivityApiType {
  private readonly catalogApi: CatalogApi;
  private readonly userApi: Sunrise.ZalandoIdentityApi;
  private readonly oauth2Api: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: {
    catalogApi: CatalogApi;
    userApi: Sunrise.ZalandoIdentityApi;
    oauth2Api: OAuthApi;
    discoveryApi: DiscoveryApi;
  }) {
    this.catalogApi = options.catalogApi;
    this.userApi = options.userApi;
    this.oauth2Api = options.oauth2Api;
    this.discoveryApi = options.discoveryApi;
  }

  async getAllTeams(): Promise<Array<string>> {
    const teams = await this.userApi.getTeams();
    if (teams.length === 0) {
      return [];
    }

    let allTeams: Array<string> = [];

    for (const team of teams) {
      const teamData = await this.catalogApi.getEntityByRef({
        kind: 'Group',
        namespace: 'default',
        name: team,
      });

      if (teamData?.spec?.alias) {
        allTeams = [...allTeams, ...(teamData?.spec?.alias as Array<string>)];
      }
    }

    // @TODO remove once we can garantee that all teams have alias
    if (allTeams.length === 0) {
      return teams;
    }

    return allTeams;
  }

  async getDeploymentStats(teams: IEntityGroup[]): Promise<TeamStatMap> {
    const token = await this.oauth2Api.getAccessToken();
    const teamIds = teams.map(t => t.spec.id_name?.toLocaleLowerCase());
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    const url: string = `${proxyUrl}/deployment-stats${DEPLOYMENT_FREQUENCY}?teams=${teamIds.join(
      ',',
    )}`;

    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      credentials: 'include',
    });

    if (!response.ok) throwReadableError(response);

    const deploymentMap: TeamStatMap = new Map();

    const json = await response.json();

    teams.forEach(team => {
      let frequency = 0;

      if (json.items) {
        json.items.forEach((item: { team: string; frequency: number }) => {
          if (item.team === team.spec.id_name?.toLocaleLowerCase()) {
            frequency = this.prettifyNumber(item.frequency) ?? 0;
          }
        });
      }

      const name =
        getTeamNameFromFullName(team.spec.fullName) ||
        team.spec.id ||
        team.spec.team_id;

      deploymentMap.set(name, frequency);
    });

    return deploymentMap;
  }

  getDeploymentFrequencyState(
    frequency: number,
  ): 'green' | 'yellow' | 'orange' | 'red' {
    switch (true) {
      case frequency <= 1:
        return 'red';
      case frequency <= 2:
        return 'orange';
      case frequency <= 4:
        return 'yellow';
      default:
        return 'green';
    }
  }

  async getProductivityRows(
    teams: IEntityGroup[],
  ): Promise<ProductivityPerTeam> {
    const deploymentMap = await this.getDeploymentStats(
      teams.map(replaceNilWithEmptyString),
    );

    const productivityPerTeam: ProductivityPerTeam = new Map([]);

    if (teams.length > 1) {
      const totalDeployments = Array.from(deploymentMap.values()).reduce(
        (acc, v) => acc + v,
        0,
      );

      productivityPerTeam.set('average', [
        {
          dimension: 'Deployment Frequency',
          yourTeam: this.prettifyNumber(totalDeployments / teams.length),
          target: 5,
          unit: 'deploy days',
          state: this.getDeploymentFrequencyState(
            this.prettifyNumber(totalDeployments / teams.length),
          ),
        },
        {
          dimension: 'Lead Time',
          yourTeam: -1,
          target: -1,
          unit: 'days',
        },
      ]);
    }

    teams.forEach(team => {
      const name =
        getTeamNameFromFullName(team.spec.fullName) ||
        team.spec.id ||
        team.spec.team_id;
      productivityPerTeam.set(name, [
        {
          dimension: 'Deployment Frequency',
          yourTeam: deploymentMap.get(name) ?? 0,
          target: 5,
          unit: 'deploy days',
          state: this.getDeploymentFrequencyState(deploymentMap.get(name) ?? 0),
        },
        {
          dimension: 'Lead Time',
          yourTeam: -1,
          target: -1,
          unit: 'days',
        },
      ]);
    });

    return productivityPerTeam;
  }

  private prettifyNumber(value: number): number {
    return +new Intl.NumberFormat('en', { maximumFractionDigits: 2 }).format(
      value,
    );
  }
}
