import React from 'react';
import Box from '@mui/material/Box';
import { DebouncedSearch } from '../Filtering/DebouncedSearch';
import { ToolbarItem } from './ToolbarItem';
import { ExportButton } from './ExportButton';
import type { Table } from '@tanstack/react-table';
import type { TableProps } from '../types';
import type { TableToolbarItem } from '../types/toolbar';

interface Props<T> {
  showSearch?: boolean;
  table: Table<T>;
  items: TableToolbarItem[];
  loading?: boolean;
  onExport?: TableProps<T>['onExport'];
}

export function TableToolbar<T>({
  table,
  showSearch,
  items,
  loading,
  onExport,
}: Props<T>) {
  const globalFilter = table.getState().globalFilter as string;

  return (
    <Box display="flex" alignItems="flex-end" gap={1.5}>
      {items
        .filter(i => i.placement !== 'end')
        .map((item, i) => (
          <ToolbarItem key={i} item={item} loading={loading} />
        ))}
      {showSearch && (
        <DebouncedSearch
          loading={loading}
          value={globalFilter ?? ''}
          onChange={search => table.setGlobalFilter(search)}
        />
      )}
      {items
        .filter(i => i.placement === 'end')
        .map((item, i) => (
          <ToolbarItem key={i} item={item} loading={loading} />
        ))}
      {!!onExport && <ExportButton onExport={onExport} table={table} />}
    </Box>
  );
}
