import React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import GroupIcon from '@mui/icons-material/Group';
import BusinessIcon from '@mui/icons-material/Business';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Link } from '@backstage/core-components';
import { getTeamNameFromFullName } from 'plugin-core';
import { useStyles } from './style';
import { SocialButton } from './SocialButton';
import { TrackedLink } from '../Tracking';
import cn from 'classnames';

const userPlaceHolder: IEntityUser = {
  metadata: {
    zalandoMetadata: {
      phone: {},
    },
  },
  spec: { profile: {} },
} as IEntityUser;

type DivProps = JSX.IntrinsicElements['div'];

interface Props extends DivProps {
  user: IEntityUser;
  header?: React.ReactNode;
  tracking?: IEventTracker;
}

export const UserReferenceCard = React.forwardRef(
  (
    { user, tracking, header, ...props }: Props,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const styles = useStyles();
    const {
      metadata: {
        zalandoMetadata: {
          name,
          full_name,
          job_title,
          department,
          email,
          phone,
          team,
          login,
          city,
          inactive,
          deleted,
        },
      },
      spec: {
        profile: { picture },
      },
    } = user ?? userPlaceHolder;

    const phoneNumber = phone?.phone ?? phone?.mobile;
    const teamName = getTeamNameFromFullName(team);
    const link = `/catalog/default/user/${
      typeof user === 'string' ? user : login
    }`;
    const LinkComponent = tracking ? TrackedLink : Link;
    const trackingProps = { to: link };

    return (
      <div {...props} className={cn(styles.root, props.className)} ref={ref}>
        {header && (
          <>
            <div className={styles.cardHeader}>{header}</div>
            <hr className="thin-hr" />
          </>
        )}
        <div className="content">
          <div className="highlight">
            <Avatar
              src={deleted ? '/deleted-user.png' : picture}
              alt="Profile"
              className={styles.largePicture}
            />
            <div>
              <Typography>
                <LinkComponent
                  {...(trackingProps as any)}
                  to={link}
                  style={{ color: (inactive || deleted) && 'gray' }}
                >
                  {name || full_name}
                </LinkComponent>
              </Typography>
              <Typography>{login}</Typography>
              <Typography>{job_title}</Typography>
            </div>
          </div>

          <Typography
            variant="h6"
            style={{
              marginTop: '15px',
              display: deleted || inactive ? 'block' : 'none',
            }}
          >
            {deleted ? 'This user has been deleted' : 'This user is inactive'}
          </Typography>
          {!(deleted || inactive) && (
            <div className="details">
              <dl>
                {teamName && (
                  <>
                    <dt>
                      <Tooltip title="Team" placement="top">
                        <GroupIcon fontSize="small" />
                      </Tooltip>
                    </dt>
                    <dd>{teamName}</dd>
                  </>
                )}
                {department && (
                  <>
                    <dt>
                      <Tooltip title="Department" placement="top">
                        <BusinessIcon fontSize="small" />
                      </Tooltip>
                    </dt>
                    <dd>{department}</dd>
                  </>
                )}
                {city && (
                  <>
                    <dt>
                      <Tooltip title="City" placement="top">
                        <RoomIcon fontSize="small" />
                      </Tooltip>
                    </dt>
                    <dd>{city}</dd>
                  </>
                )}
              </dl>
            </div>
          )}
        </div>
        <hr className="thin-hr" />
        <div className="social">
          <SocialButton
            tooltip="Email"
            to={`mailto:${email}`}
            disabled={!email || inactive || deleted}
            Icon={EmailIcon as any}
          />
          <SocialButton
            tooltip="Call"
            to={`tel:${phoneNumber}`}
            disabled={!phoneNumber || inactive || deleted}
            Icon={CallIcon as any}
          />
          <SocialButton
            tooltip="Github"
            to={`https://github.bus.zalan.do/${login}`}
            disabled={!login || inactive || deleted}
            Icon={GitHubIcon as any}
          />
        </div>
      </div>
    );
  },
);
