import React from 'react';
import cn from 'classnames';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import type { Row } from '@tanstack/react-table';
import Box from '@mui/material/Box';
import { useStyles } from './styles';
import { DEFAULT_TREE_SPACING } from './constants';

interface Props<T> {
  row: Row<T>;
  hidden?: boolean;
}

export function TreeExpandIcon<T>({ row, hidden }: Props<T>) {
  const styles = useStyles();

  if (hidden) return null;

  const depth = (row.original as any).__depth;
  const open = row.getIsExpanded();

  return (
    <Box
      className={cn(styles.tableCell, styles.tableCellExpand)}
      sx={{
        ml: depth * DEFAULT_TREE_SPACING + depth,
        placeItems: 'center',
      }}
    >
      {row.getCanExpand() ? (
        <IconButton
          aria-label="expand row"
          size="small"
          className="tree-icon-btn"
          onClick={row.getToggleExpandedHandler()}
          sx={{ height: 'fit-content', mr: 1 }}
          data-expanded={open}
          disableRipple
        >
          <div className="icon-wrapper">
            <AddIcon fontSize="small" className={cn('icon', { show: !open })} />
            <RemoveIcon
              fontSize="small"
              className={cn('icon', { show: open })}
            />
          </div>
        </IconButton>
      ) : (
        <Box
          sx={theme => {
            const size = theme.spacing(DEFAULT_TREE_SPACING);

            return {
              width: size,
              height: size,
            };
          }}
        />
      )}
    </Box>
  );
}
