import {
  Alert,
  Incident,
  IOpsGenieAPI as API,
  OnCallParticipantRef,
  Schedule,
  Team,
} from './types';
import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';

export const opsgenieApiRef = createApiRef<OpsgenieApi>({
  id: 'plugin.opsgenie.service',
});

/** API to talk to Opsgenie. */
export class OpsgenieApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly proxyPath: string;
  private readonly domain: string;
  private readonly readOnly: boolean;

  constructor(opts: API.Options) {
    this.discoveryApi = opts.discoveryApi;
    this.domain = opts.domain;
    this.proxyPath = opts.proxyPath ?? '/opsgenie/api';
    this.readOnly = opts.readOnly;
  }

  async getAlerts(opts?: API.AlertsFetchOpts): Promise<Alert[]> {
    const limit = opts?.limit || 50;
    const query = opts?.query ? `&query=${opts?.query}` : '';
    const response = await this.fetch<API.AlertsResponse>(
      `/v2/alerts?limit=${limit}${query}`,
    );

    return response.data;
  }

  async getIncidents(teams?: string[]): Promise<Incident[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('opsgenie');
    const url = teams
      ? `${baseUrl}/incidents?teams=${teams.join(',')}`
      : `${baseUrl}/incidents`;
    const response = await fetch(url, {
      credentials: 'include',
    });
    if (response.ok) return (await response.json()) as Incident[];
    return [];
  }

  getIncidentsStream(
    opts?: API.IncidentsFetchOpts,
  ): ReadableStream<Incident[]> {
    const limit = 100;
    const sort = opts?.sort || 'createdAt';
    const order = opts?.order || 'desc';
    let query = opts?.query ? `&query=${encodeURIComponent(opts?.query)}` : '';

    // eslint-disable-next-line consistent-this
    const apiThis = this;

    return new ReadableStream({
      async start(controller) {
        // IF supplied add the list of teams to query on
        if (opts?.teams && opts?.teams.length > 0) {
          const teamIds = opts.teams.filter(team => !!team);
          let teamQuery = teamIds.join(' OR ');

          // If not team with radical agility Id available, return an empty list of incidents
          if (!teamQuery) {
            controller.enqueue([]);
            controller.close();
          }

          teamQuery = `details.key = "Owning Team radical agility id" AND details.value = (${teamQuery})`;
          if (query === '') query = `&query=${teamQuery}`;
          else query = `${query} AND ${teamQuery}`;
        }

        const url = `/v1/incidents?limit=${encodeURIComponent(
          limit,
        )}&sort=${encodeURIComponent(sort)}&order=${encodeURIComponent(
          order,
        )}&${query}`;

        let response = await apiThis.fetch<API.IncidentsResponse>(url);
        controller.enqueue(response.data);

        while (response.paging.next) {
          const parsedUrl = new URL(response.paging.next);
          response = await apiThis.fetch(parsedUrl.pathname + parsedUrl.search);
          controller.enqueue(response.data);
        }
        controller.close();
      },
    });
  }

  async acknowledgeAlert(alert: Alert): Promise<void> {
    if (this.isReadOnly()) {
      throw new Error("You can't acknowledge an alert in read-only mode.");
    }
    const init = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ source: 'Backstage — Opsgenie plugin' }),
    };

    await this.call(`/v2/alerts/${alert.id}/acknowledge`, init);
  }

  async closeAlert(alert: Alert): Promise<void> {
    if (this.isReadOnly()) {
      throw new Error("You can't close an alert in read-only mode.");
    }
    const init = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ source: 'Backstage — Opsgenie plugin' }),
    };

    await this.call(`/v2/alerts/${alert.id}/close`, init);
  }

  async getSchedules(): Promise<Schedule[]> {
    const response = await this.fetch<API.SchedulesResponse>('/v2/schedules');

    return response.data;
  }

  async getSchedulesForTeam(name: string): Promise<Schedule[]> {
    const response = await this.fetch<API.SchedulesResponse>('/v2/schedules');

    return response.data.filter(
      schedule => schedule.ownerTeam && schedule.ownerTeam.name === name,
    );
  }

  async getTeams(): Promise<Team[]> {
    const response = await this.fetch<API.TeamsResponse>('/v2/teams');

    return response.data;
  }

  async getOnCall(
    scheduleId: string,
    date?: string,
  ): Promise<OnCallParticipantRef[]> {
    const query = date ? `?date=${date}` : '';
    const response = await this.fetch<API.ScheduleOnCallResponse>(
      `/v2/schedules/${scheduleId}/on-calls${query}`,
    );

    return response.data.onCallParticipants;
  }

  getAlertDetailsURL(alert: Alert): string {
    return `${this.domain}/alert/detail/${alert.id}/details`;
  }

  getIncidentDetailsURL(incident: Incident): string {
    return `${this.domain}/incident/detail/${incident.id}`;
  }

  getUserDetailsURL(userId: string): string {
    return `${this.domain}/settings/users/${userId}/detail`;
  }

  isReadOnly(): boolean {
    return this.readOnly;
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const apiUrl = await this.apiUrl();

    const resp = await fetch(`${apiUrl}${input}`, {
      ...init,
      credentials: 'include',
    });
    if (!resp.ok) {
      throw new Error(`Request failed with ${resp.status} ${resp.statusText}`);
    }

    return await resp.json();
  }

  private async call(input: string, init?: RequestInit): Promise<void> {
    const apiUrl = await this.apiUrl();

    const resp = await fetch(`${apiUrl}${input}`, init);
    if (!resp.ok)
      throw new Error(`Request failed with ${resp.status}: ${resp.statusText}`);
  }

  private async apiUrl() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return proxyUrl + this.proxyPath;
  }
}
